import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';

import SecondaryHeader from '../customComponents/SecondaryHeader'

import ApiUtils from '../api/ApiUtils'

class MyTeams extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teams: "", 
      loadingScreen: <CircularProgress/>,
      teamDisplay: null
    }
  }

  async componentDidMount() {
    ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      this.setState({ user: user }, () => {
        if (user.isLeader || user.isAdmin || user.isCoach) {
          this.getTeams()
        } else {
          this.setState({ 
            createTeam: 
              <Typography style={{padding: "20px"}} variant="h6">
                {this.props.language === 'IT' ? "Devi essere leader per avere accesso a questa feature" : "You need to be a leader to access this feature." }
              </Typography>, 
            loadingScreen: null })
        }
      })
    })
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.language !== this.props.language) {
      if (this.state.user.isLeader || this.state.user.isAdmin || this.state.user.isCoach) {
        this.getTeams()
      } else {
        this.setState({ 
          createTeam: 
            <Typography style={{padding: "20px"}} variant="h6">
              {this.props.language === 'IT' ? "Devi essere leader per avere accesso a questa feature" : "You need to be a leader to access this feature." }
            </Typography>, 
          loadingScreen: null })
      }
    }
  }
  async getTeams() {
    let teams = await ApiUtils.getTeamsForLeader(this.props.user.attributes.email.toLowerCase())
    teams.sort(function(a, b) {
      return new Date(a.updatedAt) - new Date(b.updatedAt);}).reverse()
    let teamDisplay = []
    for (const team of teams) {
      teamDisplay.push(
        <Grid item key={"gridid" + team.teamName} style={{height: "40px", minWidth: "50%", textAlign: "left"}}>
          <Link href={"/team/" + team.id}>
            {team.teamName}
          </Link>
        </Grid>
      )
    }
    this.setState({ 
      teams: teams, 
      newTeamName: "", 
      loadingScreen: null, 
      teamDisplay: <div>
        <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
          <SecondaryHeader text={this.props.language === 'IT' ? "I tuoi team:" : "Your teams:"}/>
          <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2} style={{padding: "20px"}}>
            {teamDisplay}
          </Grid>
        </Paper>
        </div>,
    })
  }

  render() {
    return (
      <div>
          {this.state.loadingScreen}
          {this.state.teamDisplay}
      </div>
    );
  }
}
export default MyTeams;
