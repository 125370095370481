import React from 'react';
import { withRouter } from "react-router";

import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import SecondaryHeader from '../../customComponents/SecondaryHeader'
import Article from './Article'

import ApiUtils from '../../api/ApiUtils';

class Articles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      articlesDisplay: <CircularProgress/>,
      modalOpen: false,
      articleSelected: "",
      teams: [],
      teamSelected: ""
    }
  }

  shareArticle() {
    if (this.state.teamSelected === "" || this.state.articleSelected === "") {
      alert("Devi selezionare un team con cui condividere l'articolo!")
    } else {
      let team = this.state.teamSelected
      if (!team.articlesShared.includes(this.state.articleSelected)) {
        team.articlesShared.push(this.state.articleSelected)
        delete team.createdAt
        delete team.updatedAt
        ApiUtils.updateTeam(team).then(() => {
          alert("Articolo condiviso con il team " + team.teamName)
          window.location.reload()
        })
      }
    }
  }

  removeArticleFromTeam(team, articleId) {
    const index = team.articlesShared.indexOf(articleId);
    if (index > -1) {
      team.articlesShared.splice(index, 1);
    }
    delete team.createdAt
    delete team.updatedAt
    ApiUtils.updateTeam(team).then(() => {
      alert("Articolo rimosso dal team " + team.teamName)
      window.location.reload()
    })
  }

  handleTeamChange(event) {
    this.setState({ teamSelected: event.target.value})
  }

  toggleModal(state, articleId) {
    this.setState({modalOpen: state, articleSelected: articleId})
  }

  deleteArticle(articleId) {
    if (window.confirm("Sei sicuro di voler cancellare quest'articolo?")) {
      ApiUtils.deleteArticle(articleId).then(
        window.location.reload()
      )
    }
  }

  componentDidMount() {
    ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      if (user.isLeader || user.isAdmin) {
        ApiUtils.getTeamsForLeader(this.props.user.attributes.email.toLowerCase()).then((teams)  => {
          let articlesDisplay = []
          ApiUtils.getAllArticles().then((articles) => {
            for (const article of articles) {
              if (user.isAdmin) {
                articlesDisplay.push(<div style={{marginTop: "20px"}} key={article.id}>
                  <Button variant="outlined" color="secondary" 
                    onClick={() => this.props.history.push('/editArticle/' + article.id)}>
                    <EditIcon/>
                  </Button>
                  &nbsp;
                  <Button variant="outlined" color="secondary" 
                    onClick={() => this.deleteArticle(article.id)}>
                    <DeleteIcon/>
                  </Button>
                  <br/>
                  <Article id={article.id} title={article.title} content={article.content} 
                    timestamp={article.updatedAt.substring(0, 10)}/>
                  <hr/>
                </div>)
              } else {
                let teamsShared = []
                for (const team of teams) {
                  if (team.articlesShared.includes(article.id)) {
                    teamsShared.push(<Chip key={"chipKey" + team.id}
                    variant="outlined"
                    label={team.teamName}
                    onDelete={() => this.removeArticleFromTeam(team, article.id)}
                  />)
                  }
                }
                if (teamsShared.length <= 0) {
                  teamsShared = "Non condiviso."
                }
                articlesDisplay.push(<div style={{marginTop: "20px"}} key={article.id}>
                  {teamsShared}
                  <Button variant="contained" color="primary" style={{margin: "20px"}}
                    onClick={() => this.toggleModal(true, article.id)}>
                    <ShareIcon/>
                  </Button>
                  <br/>
                  <Article id={article.id} title={article.title} content={article.content} timestamp={article.updatedAt.substring(0, 10)}/>
                  <hr/>
                </div>)
              }
          }
          this.setState({ articlesDisplay: articlesDisplay, teams: teams })
        })
        })
        
      } else {
        this.setState({ articlesDisplay: <SecondaryHeader text="Non hai accesso a questa pagina." /> })
      }
    })
  }


  render() {
    let teamOptions = []
    for (const team of this.state.teams) {
      teamOptions.push(<MenuItem key={team.id} value={team}>{team.teamName}</MenuItem> )
    }
    return (
      <div>
      <Dialog key="modalShare"
        open={this.state.modalOpen}
        onClose={() => this.toggleModal(false, "")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Condividi l'articolo</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Con quale team vuoi condividere quest'articolo?
            <br/>
            <Select
              value={this.state.teamSelected}
              onChange={(event) => this.handleTeamChange(event)}
            >
              {teamOptions}
            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => this.toggleModal(false, "")} color="secondary">
            Indietro
          </Button>
          <Button variant="contained" onClick={() => this.shareArticle()} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
        {this.state.articlesDisplay}
      </div>
    );
  }
}
export default withRouter(Articles)