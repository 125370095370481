import React from 'react';
import { withRouter } from "react-router";

import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';

import ApiUtils from '../api/ApiUtils';

class UserFeedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feedback: "",
      loadingScreen: <CircularProgress/>,
    }
  }

  componentDidMount() {
    ApiUtils.getUserFeedbackForUserForTeamForReportForProcess(
      this.props.user.attributes.email.toLowerCase(),
      this.props.match.params.teamId,
      this.props.match.params.surveyType,
      this.props.match.params.processNumber
    ).then((existingFeedback) => {
      if (existingFeedback === undefined) {
        ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
          ApiUtils.getTeamById(this.props.match.params.teamId).then((team) => {
            if (this.props.match.params.surveyType === 'STORM') {
              if (this.props.match.params.processNumber === '2') {
                 this.setState({
                   feedback: <div>
                   <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
                     <Typography variant="h6" style={{padding: "10px", textAlign: "left"}}>
                       {this.props.language === 'IT' ? 
                       'Secondo te, il team sta lavorando sul profilo di team e sui suoi punti di forza?' : 
                       'According to you, the team has been working on the team profile and its strenghts?'}
                     </Typography>
                     <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
                       <RadioGroup style={{paddingLeft: "20px"}} aria-label="storm2" name="storm2" value={this.state.answer} 
                           onChange={event => {
                             const { value } = event.target;
                             console.log(value)
                             this.setState({ answer: value });
                       }}>
                         <FormControlLabel value="1" control={<Radio color="primary" value="1"/>} label={this.props.language === 'IT' ? "Sì" : 'Yes'} />
                         <FormControlLabel value="0" control={<Radio color="primary" value="0"/>} label="No" />
                       </RadioGroup>
                       <Button style={{marginTop: "20px"}} variant="contained" color="primary" 
                         onClick={() => this.submitAnswer()}>
                         {this.props.language === 'IT' ? "Conferma" : 'Confirm'}
                       </Button>
                     </Grid>
                   </Paper>
                   </div>,
                   loadingScreen: null
                 }) 
              } else if (this.props.match.params.processNumber === '3') {
                this.setState({
                  feedback: <div>
                  <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
                    <Typography variant="h6" style={{padding: "10px", textAlign: "left"}}>
                    {this.props.language === 'IT' ? 
                    'Il team ha provato ad applicare il suggerimento ricevuto? Se si, che benefici ha portato? Secondo te, cos’altro dovrebbe fare il team concretamente? Cosa suggeriresti di fare per valorizzare ancora di più il talento del team?' :
                    'Has the team tried to apply the suggestion? If yes, what benefits did it bring? According to you, what else should the team do? What do you suggest to do in order to value even more the talent of the team?'}
                    </Typography>
                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
                      <TextField
                        label="Risposta"
                        defaultValue={this.state.answer}
                        onChange={event => {
                          const { value } = event.target;
                          this.setState({ answer: value });
                        }}
                        fullWidth
                        variant="outlined"
                      />
                      <Button style={{marginTop: "20px"}} variant="contained" color="primary" 
                        onClick={() => this.submitAnswer()}>
                        {this.props.language === 'IT' ? "Conferma" : 'Confirm'}
                      </Button>
                    </Grid>
                  </Paper>
                  </div>,
                   loadingScreen: null
                })
              } else {
                this.setState({
                  feedback: this.props.language === 'IT' ? 'Nessun feedback trovato.' : 'No feedback found.',
                  loadingScreen: null
                })
              }
            } else if (this.props.match.params.surveyType === 'NORM') {
              if (this.props.match.params.processNumber === '2') {
                 this.setState({
                   feedback: <div>
                   <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
                     <Typography variant="h6" style={{padding: "10px", textAlign: "left"}}>
                     {this.props.language === 'IT' ? 'Secondo te, il team sta lavorando sul suggerimento ricevuto?' :
                     'According to you, has the team been working on the suggestion received?'}
                     </Typography>
                     <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
                       <RadioGroup style={{paddingLeft: "20px"}} aria-label="norm2" name="norm2" value={this.state.answer} 
                           onChange={event => {
                             const { value } = event.target;
                             console.log(value)
                             this.setState({ answer: value });
                       }}>
                         <FormControlLabel value="1" control={<Radio color="primary" value="1"/>} label={this.props.language === 'IT' ? "Sì" : 'Yes'} />
                         <FormControlLabel value="0" control={<Radio color="primary" value="0"/>} label="No" />
                       </RadioGroup>
                       <Button style={{marginTop: "20px"}} variant="contained" color="primary" 
                         onClick={() => this.submitAnswer()}>
                         {this.props.language === 'IT' ? "Conferma" : 'Confirm'}
                       </Button>
                     </Grid>
                   </Paper>
                   </div>,
                   loadingScreen: null
                 }) 
              } else if (this.props.match.params.processNumber === '3') {
                this.setState({
                  feedback: <div>
                  <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
                    <Typography variant="h6" style={{padding: "10px", textAlign: "left"}}>
                    {this.props.language === 'IT' ? 
                      'Il team ha provato ad applicare il suggerimento ricevuto? Se si, che benefici ha portato? Secondo te, cos’altro dovrebbe fare il team concretamente? Cosa suggeriresti di fare per valorizzare ancora di più le dinamiche del team?' :
                      'Has the team tried to apply the suggestion received? If yes, what benefits did it bring? According to you, what else should the team do? What do you recommend to do in order to value even more the dynamics of the team?'}
                    </Typography>
                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
                      <TextField
                        label="Risposta"
                        defaultValue={this.state.answer}
                        onChange={event => {
                          const { value } = event.target;
                          this.setState({ answer: value });
                        }}
                        fullWidth
                        variant="outlined"
                      />
                      <Button style={{marginTop: "20px"}} variant="contained" color="primary" 
                        onClick={() => this.submitAnswer()}>
                        {this.props.language === 'IT' ? "Conferma" : 'Confirm'}
                      </Button>
                    </Grid>
                  </Paper>
                  </div>,
                   loadingScreen: null
                })
              } else {
                this.setState({
                  feedback: this.props.language === 'IT' ? 'Nessun feedback trovato.' : 'No feedback found.',
                  loadingScreen: null
                })
              }
            } else if (this.props.match.params.surveyType === 'PERFORM') {
              if (this.props.match.params.processNumber === '2') {
                 this.setState({
                   feedback: <div>
                   <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
                     <Typography variant="h6" style={{padding: "10px", textAlign: "left"}}>
                     {this.props.language === 'IT' ? 'Secondo te, il team sta lavorando sul suggerimento ricevuto?' :
                     'According to you, has the team been working on the suggestion received?'}
                     </Typography>
                     <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
                       <RadioGroup style={{paddingLeft: "20px"}} aria-label="perform2" name="perform2" value={this.state.answer} 
                           onChange={event => {
                             const { value } = event.target;
                             console.log(value)
                             this.setState({ answer: value });
                       }}>
                         <FormControlLabel value="1" control={<Radio color="primary" value="1"/>} label={this.props.language === 'IT' ? "Sì" : 'Yes'} />
                         <FormControlLabel value="0" control={<Radio color="primary" value="0"/>} label="No" />
                       </RadioGroup>
                       <Button style={{marginTop: "20px"}} variant="contained" color="primary" 
                         onClick={() => this.submitAnswer()}>
                         {this.props.language === 'IT' ? "Conferma" : 'Confirm'}
                       </Button>
                     </Grid>
                   </Paper>
                   </div>,
                   loadingScreen: null
                 }) 
              } else if (this.props.match.params.processNumber === '3') {
                this.setState({
                  feedback: <div>
                  <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
                    <Typography variant="h6" style={{padding: "10px", textAlign: "left"}}>
                      {this.props.language === 'IT' ? 
                      'Stai vedendo dei miglioramenti nella performance, rispetto all’outcome su cui avete scelto di concentrarvi?' : 
                      'Have you seen any improvement in the performance, according to the oucome that you chose to focus on?'}
                    </Typography>
                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
                      <TextField
                        label="Risposta"
                        defaultValue={this.state.answer}
                        onChange={event => {
                          const { value } = event.target;
                          this.setState({ answer: value });
                        }}
                        fullWidth
                        variant="outlined"
                      />
                      <Button style={{marginTop: "20px"}} variant="contained" color="primary" 
                        onClick={() => this.submitAnswer()}>
                        {this.props.language === 'IT' ? "Conferma" : 'Confirm'}
                      </Button>
                    </Grid>
                  </Paper>
                  </div>,
                   loadingScreen: null
                })
              } else {
                this.setState({
                  feedback: this.props.language === 'IT' ? 'Nessun feedback trovato.' : 'No feedback found.',
                  loadingScreen: null
                })
              }
            } else {
              this.setState({
                feedback: this.props.language === 'IT' ? 'Nessun feedback trovato.' : 'No feedback found.',
                loadingScreen: null
              })
            }
          })
        })
      } else {
        console.log(existingFeedback)
        this.setState({
          feedback: this.props.language === 'IT' ? 'Hai già dato questo feedback.' : 'You have already provided this feedback.',
          loadingScreen: null
        })
      }
    })
  }

  
  async submitAnswer() {
    if (this.state.answer === "") {
      alert(this.props.language === 'IT' ? "Per favore rispondi alla domanda." : 'Please answer the question.')
    } else {
      await ApiUtils.createUserFeedback({
        "surveyType": this.props.match.params.surveyType,
        "processNumber": this.props.match.params.processNumber,
        "user": this.props.user.attributes.email.toLowerCase(),
        "answer": this.state.answer,
        "teamId": this.props.match.params.teamId,
        "isArchived": false
      })
      this.props.history.push('/')
    }
  }


  render() {
    return (
      <div>
        {this.state.loadingScreen}
        {this.state.feedback}
      </div>
    );
  }
}
export default withRouter(UserFeedback)