import React from 'react';
import { withRouter } from "react-router";

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

import FormReport from './FormReport';
import StormReport from './StormReport';
import NormReport from './NormReport';
import PerformReport from './PerformReport';
import WayReport from './WayReport';

import ApiUtils from '../../api/ApiUtils';
import MailHelper from '../../api/MailHelper';
import FormHelper from '../../api/FormHelper';

class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reportDetails: "",
      loadingScreen: <CircularProgress/>,
      reportShared: null
    }
  }

  componentDidMount() {
    ApiUtils.getTeamById(this.props.match.params.teamId).then((team) => {
      ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
        if (team.reportsGenerated.includes(this.props.match.params.reportId)) {
          if (team.leader === this.props.user.attributes.email.toLowerCase() || user.isAdmin) {
            this.populateView(team, true) 
          } else if (team.users.includes(this.props.user.attributes.email.toLowerCase())) {
            this.populateView(team, false) 
          } else {
            this.setState({ reportDetails: 
                <Typography style={{padding: "20px"}} variant="h6">
                  Non hai accesso a questo report.
                </Typography>,
              loadingScreen: null
            })
          }
        } else {
          this.setState({ reportDetails: 
              <Typography style={{padding: "20px"}} variant="h6">
                Non hai accesso a questo report.
              </Typography>,
            loadingScreen: null
          })
        }
      })
    })
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.language !== nextProps.language && this.state.report !== undefined) {
      let reportDetails = null
      if (this.state.report.type === "FORM") {
        reportDetails = <FormReport data={this.state.report} language={this.props.language}/>
      } else if (this.state.report.type === "STORM") {
        reportDetails = <StormReport data={this.state.report}  language={this.props.language} isLeader={false}/>
      } else if (this.state.report.type === "NORM") {
        reportDetails = <NormReport  language={this.props.language} data={this.state.report}/>
      } else if (this.state.report.type === "PERFORM") {
        reportDetails = <PerformReport  language={this.props.language} data={this.state.report}/>
      } else if (this.state.report.type === "WAY") {
        reportDetails = <WayReport  language={this.props.language} data={this.state.report}/>
      }
      this.setState({
        reportDetails: reportDetails
      })
    }
  }
  
  shareReport(team, report) {
    report.isShared = true
    delete report.createdAt;
    delete report.updatedAt;
    const reportData = JSON.parse(report.data)
    ApiUtils.updateReport(report).then(() => {
      delete team.createdAt
      delete team.updatedAt 
      if (report.type === "FORM") {
        if (Math.max(...reportData.team_dist) >= 50) {
          let index = reportData.team_dist.indexOf(Math.max(...reportData.team_dist))
          if (index === 0) {
            team.talent = "Razionale"
          } else if (index === 1) {
            team.talent = "Riflessivo"
          } else {
            team.talent = "Pratico"
          }
        } else {
          let index = reportData.team_dist.indexOf(Math.min(...reportData.team_dist))
          if (index === 0) {
            team.talent = "Emotivo"
          } else if (index === 1) {
            team.talent = "Creativo"
          } else {
            team.talent = "Idealista"
          }
        }
        team.profile = reportData.team_profile
        team.suggestions = FormHelper.getSuggestionsForTeamType(reportData.team_profile)
      } else if (report.type === "STORM") {
        let rage = 0
        let discontempt = 0
        let euphoria = 0
        let contempt = 0
        for (const answer of Object.keys(reportData)) {
          if (answer !== "participants") {
            const delta = reportData[answer]["positive"] - reportData[answer]["negative"]
            if (delta >= 0.5) {
              euphoria += 1
            } else if (delta >= 0) {
              contempt += 1
            } else if (delta >= -0.3) {
              discontempt += 1
            } else {
              rage += 1
            }
          }
        }
        const total = rage + discontempt + euphoria + contempt
        const total_score = Math.round(euphoria / total * 100) - Math.round(rage / total * 100) + (Math.round(contempt / total * 100) - Math.round(discontempt / total * 100))/2
        team.sentimentScore = total_score
        const maxSentiment = [rage, euphoria, discontempt, contempt].indexOf(Math.max(...[rage, euphoria, discontempt, contempt]));
        if (maxSentiment === 0) {
          team.emotion = "Rabbia"
        } else if (maxSentiment === 1) {
          team.emotion = "Euforia"
        } else if (maxSentiment === 2) {
          team.emotion = "Frustrazione"
        } else if (maxSentiment === 3) {
          team.emotion = "Soddisfazione"
        }
      } else if (report.type === "NORM") {
        if (reportData.avg > 4.9) {
          team.normAverage = "Nettamente sopra la media"
        } else if (reportData.avg > 3.75) {
          team.normAverage = "Sopra la media"
        } else if (reportData.avg > 2.5) {
          team.normAverage = "Nella media"
        } else if (reportData.avg > 1.25) {
          team.normAverage = "Sotto la media"
        } else {
          team.normAverage = "Nettamente sotto la media"
        }
        const dynamicsArray = [reportData.avg_trust, reportData.avg_synergy, reportData.avg_energy]
        const minDynamic = dynamicsArray.indexOf(Math.min(...dynamicsArray));
        const maxDynamic = dynamicsArray.indexOf(Math.max(...dynamicsArray));
        if (minDynamic === 0 && reportData.avg_trust <= 2.5) {
          team.lowDynamic = "Trust"
        } else if (minDynamic === 1 && reportData.avg_synergy <= 2.5) {
          team.lowDynamic = "Synergy"
        } else if (minDynamic === 2 && reportData.avg_energy <= 2.5) {
          team.lowDynamic = "Energy"
        } else {
          team.lowDynamic = "Nessuna"
        }
        if (maxDynamic === 0) {
          team.targetDynamic = "Trust"
        } else if (maxDynamic === 1) {
          team.targetDynamic = "Synergy"
        } else if (maxDynamic === 2) {
          team.targetDynamic = "Energy"
        }
      } else if (report.type === "PERFORM") {
        const teamPerformanceAvg = (Math.round(reportData.avg_speed/5*100) + Math.round(reportData.avg_growth/5*100) + Math.round(reportData.avg_results/5*100) + Math.round(reportData.avg_confidence/5*100)) / 4
        if (teamPerformanceAvg > 90) {
          team.performance = "Nettamente sopra la media"
        } else if (teamPerformanceAvg > 60) {
          team.performance = "Sopra la media"
        } else if (teamPerformanceAvg > 40) {
          team.performance = "Nella media"
        } else if (teamPerformanceAvg > 10) {
          team.performance = "Sotto la media"
        } else {
          team.performance = "Nettamente sotto la media"
        }
        if (reportData.net_development_score < 0) {
          team.mood = "Diminuita"
        } else if (reportData.net_development_score > 0) {
          team.mood = "Aumentata"
        } else {
          team.mood = "Uguale"
        }
        team.targetOutcome = "Performance"
        const bestActions = {
          "Speed": reportData.speed_action,
          "Results": reportData.results_action,
          "Growth": reportData.growth_action,
          "Sustainability": reportData.confidence_action,
          "Performance": reportData.total_best_action,
        }
        team.bestActions = JSON.stringify(bestActions)
      }
      ApiUtils.updateTeam(team).then(() => {
        MailHelper.sendMailReportShared(team, '/report/' + report.type + '/' + team.id + '/' + report.id)
        this.setState({ 
          reportShared: 
            <Box m={1} style={{padding: "20px"}}>
              <Chip
                label={this.props.language === 'IT' ? "Il report è condiviso con il team" : "The report is shared with the team"}
                color="primary"
              />
            </Box>
        })
      })
    })
  }

  populateView(team, isLeader) {
    ApiUtils.getReportById(this.props.match.params.reportId).then((report) => {
      let reportShared = null
      if (!isLeader && !report.isShared) {
        this.setState({ reportDetails: 
            <Typography style={{padding: "20px"}} variant="h6">
              Non hai accesso a questo report.
            </Typography>,
          loadingScreen: null
        })
        return
      }
      if (isLeader) {
        if (report.isShared) {
          reportShared = 
            <Box m={1} style={{padding: "20px"}}>
              <Chip
                label={this.props.language === 'IT' ? "Il report è condiviso con il team" : "The report is shared with the team"}
                color="primary"
              />
            </Box>
        } else {
          reportShared = 
            <Box m={1} style={{padding: "20px"}}>
              <Chip
                variant="outlined"
                label={this.props.language === 'IT' ? "Condividi con il team" : "Share with the team"}
                color="secondary"
                onClick={() => this.shareReport(team, report)}
              />
            </Box>
        }
      } 
      let reportDetails = null
      if (report.type === "FORM") {
        reportDetails = <FormReport data={report} language={this.props.language}/>
      } else if (report.type === "STORM") {
        reportDetails = <StormReport data={report}  language={this.props.language} isLeader={isLeader} stormQuestion={team.stormQuestion}/>
      } else if (report.type === "NORM") {
        reportDetails = <NormReport  language={this.props.language} data={report}/>
      } else if (report.type === "PERFORM") {
        reportDetails = <PerformReport  language={this.props.language} data={report}/>
      } else if (report.type === "WAY") {
        reportDetails = <WayReport  language={this.props.language} data={report}/>
      }
      this.setState({
        team: team,
        reportDetails: reportDetails,
        loadingScreen: null,
        reportShared: reportShared,
        report: report
      })
    })
  }

  render() {
    return (
      <div>
        {this.state.loadingScreen}
        {this.state.reportShared}
        {this.state.reportDetails}
      </div>
    );
  }
}
export default withRouter(Report)