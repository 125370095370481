import React from 'react';
import { withRouter } from "react-router";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import SurveyQuestion from '../../customComponents/SurveyQuestion'
import NoAccess from '../../customComponents/NoAccess'

import ApiUtils from '../../api/ApiUtils'
import ReportsHelper from '../../api/ReportsHelper';

import { Auth } from 'aws-amplify'
import Lambda from 'aws-sdk/clients/lambda';

class FormSurvey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      surveyAnswers: Array(52),
      modalOpen: false,
      loadingScreen: <CircularProgress/>,
      noAccess: null,
      submitDisabled: false
    }
  }
  
  async componentDidMount() {
    this.setState({ 
      shouldPopulateSurvey: true, 
      surveyPart: 0, 
      loadingScreen: null 
    })
    await ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      if (user !== null && user !== undefined 
        && (user.myProfile === null || user.myProfile === undefined || user.myProfile === "")
        ) {
          this.setState({ 
            shouldPopulateSurvey: true, 
            surveyPart: 0, 
            loadingScreen: null 
          })
      } else {
        this.setState({ 
          shouldPopulateSurvey: false,
          loadingScreen: null, 
          noAccess: <NoAccess text="Hai già risposto a questa survey."/> 
        })
      }
    })
  }


  toggleModal(state) {
    this.setState({modalOpen: state})
    
  }

  handleChange(value, radio) {
    let surveyAnswers = this.state.surveyAnswers
    surveyAnswers[radio] = value
    this.setState({ surveyAnswers: surveyAnswers })
  }

  async submitSurvey() {
    for (const answer of this.state.surveyAnswers) {
      if (answer === null || answer === undefined) {
        if (this.props.language === 'IT') {
          alert("Per favore rispondi a tutte le domande")
        } else {
          alert("Please answer all the questions.")
        }
        return
      }
    }
    await ApiUtils.createSurvey({
      "teamId": "_FORM",
      "user": this.props.user.attributes.email.toLowerCase(),
      "answers": this.state.surveyAnswers,
      "type": "FORM",
      "isArchived": false,
    })
    Auth.currentCredentials()
    .then(credentials => {
      const lambda = new Lambda({
        region: "eu-west-1",
        credentials: Auth.essentialCredentials(credentials)
      });
      lambda.invoke({
        FunctionName: 'teamXApiApp',
        Payload: JSON.stringify(
          ReportsHelper.getFormInputFormatted([this.state.surveyAnswers])
        ),
      }, (err, data) => {
        if (err) {
          console.log(err, err.stack);
        } else {
          ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
            user.myProfile = JSON.stringify(JSON.parse(data.Payload).team_dist)
            delete user.createdAt;
            delete user.updatedAt;
            ApiUtils.updateUser(user).then(() => {
              alert(this.props.language === 'IT' ? "Le tue risposte sono state registrate." : "Your answers have been recorded.")
              this.props.history.push('/')
            })
          })
        }
      });
    })
  }

  getIntroBlock() {
    return <div>
    <LinearProgress variant="determinate" value={0} />
    {this.props.language === 'IT' ? 
      <Typography variant="h6" style={{padding: "10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
        Di seguito troverete affermazioni che descrivono stati d'animo, azioni e reazioni. Ci sono 5 possibilità di risposta (da sinistra a destra):<br/>
        1. In disaccordo<br/>
        2. Parzialmente in disaccordo<br/>
        3. Né in disaccordo, né in accordo<br/>
        4. Parzialmente in accordo<br/>
        5. In accordo<br/>
        Leggete ciascuna affermazione e scegliete l'alternativa di risposta che meglio vi descrive.<br/>
        Non ci sono risposte giuste o sbagliate, rispondete semplicemente cio' che pensate.<br/>
      </Typography>
      : 
      <Typography variant="h6" style={{padding: "10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
        In the next pages you will find statements about mood, actions and reactions. There are 5 potential answers (left to right):<br/>
        1. I disagree<br/>
        2. Partially disagree<br/>
        3. Neither disagree, nor agree<br/>
        4. Partially agree<br/>
        5. I agree<br/>
        Read each statement and choose the best answer for you.<br/>
        There are no right or wrong answers, simply answer what you think.<br/>
      </Typography>
    }
    
    <Button variant="contained" onClick={() => {this.displaySurveyBlock(1)}} color="primary">
      <ArrowForwardIosIcon/>
    </Button>
  </div>
  }

  getFirstBlock() {
    return <div>
    <LinearProgress variant="determinate" value={0} />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[0]} 
      onChange={(v) => this.handleChange(v, 0)}
      questionText={this.props.language === 'IT' ? "Sono in grado di spiegare come solitamente reagisco allo stress" : "If I wanted to, I could tell a friend how I usually react to stress" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[1]} 
      onChange={(v) => this.handleChange(v, 1)}
      questionText={this.props.language === 'IT' ? "Tengo fede agli impegni presi" : "I will succeed in my commitments" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[2]} 
      onChange={(v) => this.handleChange(v, 2)}
      questionText={this.props.language === 'IT' ? "Con certe persone non riesco a dire no" : 'I can\'t say "no" to certain people' }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[3]} 
      onChange={(v) => this.handleChange(v, 3)}
      questionText={this.props.language === 'IT' ? "Cerco di non mettere in imbarazzo le persone di fronte ad altri" : "I try not to embarrass people in front of others" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[4]} 
      onChange={(v) => this.handleChange(v, 4)}
      questionText={this.props.language === 'IT' ? "Prima di decidere cerco di comprendere le mie emozioni" : "Before I make a decision, I try to understand my emotions" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[5]} 
      onChange={(v) => this.handleChange(v, 5)}
      questionText={this.props.language === 'IT' ? "Non mi pongo il problema di capire se le mie azioni hanno effetti negativi su chi mi circonda" : "I'm not concerned even when my actions have negative effects on others" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[6]} 
      onChange={(v) => this.handleChange(v, 6)}
      questionText={this.props.language === 'IT' ? "Le mie emozioni mi aiutano a capire cosa è importante" : "My feelings help me know what's important" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[7]} 
      onChange={(v) => this.handleChange(v, 7)}
      questionText={this.props.language === 'IT' ? "Sono consapevole delle mie emozioni anche quando non si manifestano in modo evidente" : "I am conscious of my emotions even when they are not clear" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[8]} 
      onChange={(v) => this.handleChange(v, 8)}
      questionText={this.props.language === 'IT' ? "Nella vita mi pongo traguardi stimolanti" : "I set exciting goals for myself" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[9]} 
      onChange={(v) => this.handleChange(v, 9)}
      questionText={this.props.language === 'IT' ? "Di fronte ad un ostacolo più grande di me, trovo il modo di aggirarlo" : "If I hit a major obstacle, I find a way around it" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[10]} 
      onChange={(v) => this.handleChange(v, 10)}
      questionText={this.props.language === 'IT' ? "Sono impaziente" : "I am impatient" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[11]} 
      onChange={(v) => this.handleChange(v, 11)}
      questionText={this.props.language === 'IT' ? "La mia forza di volontà mi permette di superare qualsiasi ostacolo" : "My will is strong enough to overcome any obstacle" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[12]} 
      onChange={(v) => this.handleChange(v, 12)}
      questionText={this.props.language === 'IT' ? "Ho chiaro quale sia il mio progetto di vita" : "I'm clear about my life's purpose." }
    />
    <Box component="span" m={1}>
      <Button variant="contained" onClick={() => {this.displaySurveyBlock(0)}} color="primary">
        <ArrowBackIosIcon/>
      </Button>
    </Box>
    <Box component="span" m={1}>
      <Button variant="contained" onClick={() => {this.displaySurveyBlock(2)}} color="primary">
        <ArrowForwardIosIcon/>
      </Button>
    </Box>
  </div>
  }

  getSecondBlock() {
    return <div>
    <LinearProgress variant="determinate" value={25} />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[13]} 
      onChange={(v) => this.handleChange(v, 13)}
      questionText={this.props.language === 'IT' ? "Ritengo di avere una visione a lungo termine della mia vita	" : "I have a long-term vision for my life" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[14]} 
      onChange={(v) => this.handleChange(v, 14)}
      questionText={this.props.language === 'IT' ? "Riesco a capire quando sto per arrabbiarmi" : "I am able to notice when I am just starting to get irritated" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[15]} 
      onChange={(v) => this.handleChange(v, 15)}
      questionText={this.props.language === 'IT' ? "Sono in grado di prevedere le mie reazioni" : "I am able to predict my reactions" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[16]} 
      onChange={(v) => this.handleChange(v, 16)}
      questionText={this.props.language === 'IT' ? "Trovo difficile parlare con persone che non hanno il mio stesso punto di vista" : "It's hard for me to talk to people whose point of view is different from mine" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[17]} 
      onChange={(v) => this.handleChange(v, 17)}
      questionText={this.props.language === 'IT' ? "Mi capita di non esprimere un'opinione che mi sembra interessante" : "Sometimes I don't express an opinion even though I have a good idea" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[18]} 
      onChange={(v) => this.handleChange(v, 18)}
      questionText={this.props.language === 'IT' ? "Solitamente, nonostante il mio impegno, accade sempre qualcosa che mi impedisce di raggiungere il mio scopo" : "No matter how strong my will, usually something stops me from reaching my goal" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[19]} 
      onChange={(v) => this.handleChange(v, 19)}
      questionText={this.props.language === 'IT' ? "Quando nella mia mente stabilisco una meta, so che la raggiungerò" : "When I commit to a goal, I know I will be successful" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[20]} 
      onChange={(v) => this.handleChange(v, 20)}
      questionText={this.props.language === 'IT' ? "Quando voglio ottenere qualcosa, riesco a concentrarmi efficacemente" : "When I want to achieve something, I can focus effectively" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[21]} 
      onChange={(v) => this.handleChange(v, 21)}
      questionText={this.props.language === 'IT' ? "Faccio fatica a combattere per ottenere ciò che voglio" : "I find it hard to pursue what I want" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[22]} 
      onChange={(v) => this.handleChange(v, 22)}
      questionText={this.props.language === 'IT' ? "Ritengo che le emozioni mi illuminino nella soluzione dei problemi" : "Emotions give me insight to solve problems" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[23]} 
      onChange={(v) => this.handleChange(v, 23)}
      questionText={this.props.language === 'IT' ? "Se decido di controllare le mie reazioni, riesco a farlo" : "I can control my reactions when appropriate" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[24]} 
      onChange={(v) => this.handleChange(v, 24)}
      questionText={this.props.language === 'IT' ? "Posso descrivere il mio comportamento in modo preciso" : "I can describe my own behavior accurately" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[25]} 
      onChange={(v) => this.handleChange(v, 25)}
      questionText={this.props.language === 'IT' ? "Sono pienamente consapevole delle mie reazioni" : "I am highly aware of my reactions" }
    />

    <Box component="span" m={1}>
      <Button variant="contained" onClick={() => {this.displaySurveyBlock(1)}} color="primary">
        <ArrowBackIosIcon/>
      </Button>
    </Box>
    <Box component="span" m={1}>
      <Button variant="contained" onClick={() => {this.displaySurveyBlock(3)}} color="primary">
        <ArrowForwardIosIcon/>
      </Button>
    </Box>
  </div>
  }

  getThirdBlock() {
    return <div>
    <LinearProgress variant="determinate" value={50} />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[26]} 
      onChange={(v) => this.handleChange(v, 26)}
      questionText={this.props.language === 'IT' ? "So leggere le emozioni sul viso degli altri" : "I can read the feelings on someone's face" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[27]} 
      onChange={(v) => this.handleChange(v, 27)}
      questionText={this.props.language === 'IT' ? "Sono spesso incerto nel definire i miei obiettivi" : "I feel uncertain when I set my goals" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[28]} 
      onChange={(v) => this.handleChange(v, 28)}
      questionText={this.props.language === 'IT' ? "Le emozioni seguono una logica" : "There is a logic to feelings" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[29]} 
      onChange={(v) => this.handleChange(v, 29)}
      questionText={this.props.language === 'IT' ? "Anche se vorrei, non riesco sempre ad esprimere il mio disaccordo" : "Even if I would like to, I cannot fully express my disagreement" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[30]} 
      onChange={(v) => this.handleChange(v, 30)}
      questionText={this.props.language === 'IT' ? "Sono le mie abilità e qualità personali che mi portano a raggiungere la meta prefissata" : "My personal qualities help me reach my goals" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[31]} 
      onChange={(v) => this.handleChange(v, 31)}
      questionText={this.props.language === 'IT' ? "So trasformare gli eventuali ostacoli in vantaggi" : "I turn obstacles to my advantage" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[32]} 
      onChange={(v) => this.handleChange(v, 32)}
      questionText={this.props.language === 'IT' ? "Faccio fatica ad ascoltare" : "I find it difficult to listen" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[33]} 
      onChange={(v) => this.handleChange(v, 33)}
      questionText={this.props.language === 'IT' ? "Riconoscere le emozioni delle persone mi aiuta a prendere buone decisioni" : "Recognizing people's feelings helps me make good decisions" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[34]} 
      onChange={(v) => this.handleChange(v, 34)}
      questionText={this.props.language === 'IT' ? "Riesco a individuare le ragioni alla base delle mie paure" : "I can identify the basic reasons of my fears" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[35]} 
      onChange={(v) => this.handleChange(v, 35)}
      questionText={this.props.language === 'IT' ? "Conosco le cose che mi infastidiscono" : "I know what bothers me" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[36]} 
      onChange={(v) => this.handleChange(v, 36)}
      questionText={this.props.language === 'IT' ? "Poiché conosco gli obiettivi della mia vita, riesco a prendere le migliori decisioni possibili" : "My sense of purpose helps me make the best decisions" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[37]} 
      onChange={(v) => this.handleChange(v, 37)}
      questionText={this.props.language === 'IT' ? "Spesso non oso parlare anche se ne ho voglia" : "Often I don't speak up even if I feel I should" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[38]} 
      onChange={(v) => this.handleChange(v, 38)}
      questionText={this.props.language === 'IT' ? "Sono consapevole di cosa mi rende euforico" : "I know what makes me joyful" }
    />
    <Box component="span" m={1}>
      <Button variant="contained" onClick={() => {this.displaySurveyBlock(2)}} color="primary">
        <ArrowBackIosIcon/>
      </Button>
    </Box>
    <Box component="span" m={1}>
      <Button variant="contained" onClick={() => {this.displaySurveyBlock(4)}} color="primary">
        <ArrowForwardIosIcon/>
      </Button>
    </Box>
  </div>
  }

  getFourthBlock() {
    return <div>
    <LinearProgress variant="determinate" value={75} />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[39]} 
      onChange={(v) => this.handleChange(v, 39)}
      questionText={this.props.language === 'IT' ? "Sono consapevole di cosa fa arrabbiare gli altri" : "I am aware of what makes other people angry" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[40]} 
      onChange={(v) => this.handleChange(v, 40)}
      questionText={this.props.language === 'IT' ? "Spesso faccio fatica a riconoscere le mie responsabilità" : "Often I find it difficult to accept my responsibilities" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[41]} 
      onChange={(v) => this.handleChange(v, 41)}
      questionText={this.props.language === 'IT' ? "Sono consapevole delle mie reazioni quando qualcosa mi innervosisce" : "After something happens to upset me, I know what I usually think and do" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[42]} 
      onChange={(v) => this.handleChange(v, 42)}
      questionText={this.props.language === 'IT' ? "Faccio fatica ad affrontare con serenità nuovi contesti" : "It's hard for me to easily face new situations" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[43]} 
      onChange={(v) => this.handleChange(v, 43)}
      questionText={this.props.language === 'IT' ? "È un problema per me controllare la mia aggressività" : "I find it hard to control my aggressiveness" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[44]} 
      onChange={(v) => this.handleChange(v, 44)}
      questionText={this.props.language === 'IT' ? "Perseguo i miei obiettivi nonostante le difficoltà" : "Even when life is complex, my sense of purpose helps me know what's most important" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[45]} 
      onChange={(v) => this.handleChange(v, 45)}
      questionText={this.props.language === 'IT' ? "Il cambiamento mi mette ansia" : "Changes make me anxious" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[46]} 
      onChange={(v) => this.handleChange(v, 46)}
      questionText={this.props.language === 'IT' ? "Sono in grado di descrivere come solitamente reagisco alla rabbia" : "I can explain the way I usually react to anger" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[47]} 
      onChange={(v) => this.handleChange(v, 47)}
      questionText={this.props.language === 'IT' ? "Capisco il motivo per il quale agisco in un determinato modo" : "I know why I act the way I do" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[48]} 
      onChange={(v) => this.handleChange(v, 48)}
      questionText={this.props.language === 'IT' ? "Ho chiare le ragioni delle mie decisioni" : "I have powerful reasons that guide my decisions" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[49]} 
      onChange={(v) => this.handleChange(v, 49)}
      questionText={this.props.language === 'IT' ? "Sono in grado di spiegare lo scopo di differenti emozioni" : "I can explain the purpose of different feelings" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[50]} 
      onChange={(v) => this.handleChange(v, 50)}
      questionText={this.props.language === 'IT' ? "Sono in grado di raggiungere i miei obiettivi" : "I have what it takes to reach my goals" }
    />
    <SurveyQuestion 
      questionValue={this.state.surveyAnswers[51]} 
      onChange={(v) => this.handleChange(v, 51)}
      questionText={this.props.language === 'IT' ? "Comincio molte cose senza riuscire a portarle a termine	" : "I start doing many things but I don't complete them" }
    />

    <Button variant="contained" onClick={() => {this.displaySurveyBlock(3)}} color="primary">
      <ArrowBackIosIcon/>
    </Button>
    <hr/>
    <Button variant="contained" color="primary" style={{margin: "20px"}}
      onClick={() => this.toggleModal(true)}>
      {this.props.language === 'IT' ? "Concludi il questionario" : "Finish the survey"}
    </Button>
    <Dialog
      open={this.state.modalOpen}
      onClose={() => this.toggleModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{this.props.language === 'IT' ? "Concludi il questionario" : "Finish the survey"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {this.props.language === 'IT' ? 
          "Una volta concluso il questionario, non potrai più cambiare le tue risposte." : 
          "Once you have submitted the survey, you won't be able to change your answers."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => this.toggleModal(false)} color="secondary">
          {this.props.language === 'IT' ? "Indietro" : "Back"}
        </Button>
        <Button variant="contained" color="primary" autoFocus
          disabled={this.state.submitDisabled} 
          onClick={() => {this.setState({submitDisabled:true}); this.submitSurvey() }} >
          {this.props.language === 'IT' ? "Conferma" : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
  }

  displaySurveyBlock(blockNumber) {
    if (blockNumber > 1) {
      for (const answer of this.state.surveyAnswers.slice(
            Math.max(0, ((blockNumber - 2) * 13) - 1), 
            (blockNumber - 1) * 13 )) {
        if (answer === null || answer === undefined) {
          if (this.props.language === 'IT') {
            alert("Rispondi alle domande prima di passare alla prossima parte")
          } else {
            alert("Answer all the questions before going to the next part")
          }
          return
        }
      }
    }
    this.setState({ surveyPart: blockNumber }, () => {window.scrollTo(0, 0)})
  }

  render() {
    let survey
    if (this.state.shouldPopulateSurvey) {
      if (this.state.surveyPart === 0) {
        survey = this.getIntroBlock()
      } else if (this.state.surveyPart === 1) {
        survey = this.getFirstBlock()
      } else if (this.state.surveyPart === 2) {
        survey = this.getSecondBlock()
      } else if (this.state.surveyPart === 3) {
        survey = this.getThirdBlock()
      } else if (this.state.surveyPart === 4) {
        survey = this.getFourthBlock()
      }
    }
    return (
      <div style={{marginBottom: "20px"}}>
        {this.state.loadingScreen}
        {survey}
        {this.state.noAccess}
      </div>
    );
  }
}
export default withRouter(FormSurvey)