var ReportsHelper = {

    getFormInputFormatted(surveyAnswers) {
        let formattedAnswers = []
        for (const answer of surveyAnswers) {
            let formattedAnswer = {
                "item[011]": answer[0],
                "item[012]": answer[1],
                "item[013]": answer[2],
                "item[014]": answer[3],
                "item[015]": answer[4],
                "item[016]": answer[5],
                "item[017]": answer[6],
                "item[018]": answer[7],
                "item[019]": answer[8],
                "item[020]": answer[9],
                "item[021]": answer[10],
                "item[022]": answer[11],
                "item[023]": answer[12],
                "item[024]": answer[13],
                "item[025]": answer[14],
                "item[026]": answer[15],
                "item[027]": answer[16],
                "item[028]": answer[17],
                "item[029]": answer[18],
                "item[030]": answer[19],
                "item[031]": answer[20],
                "item[032]": answer[21],
                "item[033]": answer[22],
                "item[034]": answer[23],
                "item[035]": answer[24],
                "item[036]": answer[25],
                "item[037]": answer[26],
                "item[038]": answer[27],
                "item[039]": answer[28],
                "item[040]": answer[29],
                "item[041]": answer[30],
                "item[042]": answer[31],
                "item[043]": answer[32],
                "item[044]": answer[33],
                "item[045]": answer[34],
                "item[046]": answer[35],
                "item[047]": answer[36],
                "item[048]": answer[37],
                "item[049]": answer[38],
                "item[050]": answer[39],
                "item[051]": answer[40],
                "item[052]": answer[41],
                "item[053]": answer[42],
                "item[054]": answer[43],
                "item[055]": answer[44],
                "item[056]": answer[45],
                "item[057]": answer[46],
                "item[058]": answer[47],
                "item[059]": answer[48],
                "item[060]": answer[49],
                "item[061]": answer[50],
                "item[062]": answer[51]
            }
            formattedAnswers.push(formattedAnswer)
        }
        return {
            "reportType": "form",
            "data": formattedAnswers
        }
    },


    getSinglePerformInputFormatted(answer) {
        return {
            "item[011]": answer[0],
            "item[012]": answer[1],
            "item[013]": answer[2],
            "item[014]": answer[3],
            "item[015]": answer[4],
            "item[016]": answer[5],
            "item[017]": answer[6],
            "item[018]": answer[7],
            "item[019]": answer[8],
            "item[020]": answer[9],
            "item[021]": answer[10],
            "item[022]": answer[11],
            "item[023]": answer[12],
            "item[024]": answer[13],
            "item[025]": answer[14],
            "item[026]": answer[15],
            "item[027]": answer[16],
            "item[028]": answer[17],
            "item[029]": answer[18],
            "item[030]": answer[19],
            "item[031]": answer[20],
            "item[032]": answer[21],
            "item[033]": answer[22],
            "item[034]": answer[23],
            "item[035]": answer[24],
            "item[036]": answer[25],
            "item[037]": answer[26],
            "item[038]": answer[27],
            "item[039]": answer[28],
            "item[040]": answer[29],
            "item[041]": answer[30],
            "item[042]": answer[31],
            "item[043]": answer[32],
            "item[044]": answer[33],
            "item[045]": answer[34],
            "item[046]": answer[35],
            "item[047]": answer[36],
            "item[048]": answer[37],
            "item[049]": answer[38],
            "item[050]": answer[39],
            "item[051]": answer[40],
            "item[052]": answer[41],
            "item[053]": answer[42],
            "item[054]": answer[43],
            "item[055]": answer[44],
            "item[056]": answer[45],
            "item[057]": answer[46],
            "item[058]": answer[47],
            "item[059]": answer[48],
            "item[060]": answer[49],
            "item[061]": answer[50],
            "item[062]": answer[51],
            "q755[001]": answer[54],
            "q755[002]": answer[53],
            "q755[003]": answer[55],
            "q755[004]": answer[52],
            "performance_question": answer[56],
            "q755[007]": answer[59],
            "q755[009]": answer[58],
            "q755[010]": answer[57],
        }
    },
}
export default ReportsHelper;