/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:bf66de93-7c00-445f-a027-9c4062b195e5",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_RbL4cSFph",
    "aws_user_pools_web_client_id": "7j6vfgb7thark9clglsbqcbei8",
    "oauth": {},
    "aws_content_delivery_bucket": "teamxreact-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1pw56qmvbnlw6.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://jrxillr45je3ngvtwxj5pjvy7q.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
