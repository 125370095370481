import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';

import SecondaryHeader from '../customComponents/SecondaryHeader'

import { Auth } from 'aws-amplify'

import ApiUtils from '../api/ApiUtils'
import MailHelper from '../api/MailHelper'
import Paragraph from '../customComponents/Paragraph';

const AWS = require("aws-sdk")
AWS.config.update({region: 'eu-west-1'});

class Account extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newUserMail: "", 
      isLeader: false,
      isAdmin: false,
      isCoach: false,
      display: null,
      loadingScreen: <CircularProgress/>
    }
    this.inviteUser = this.inviteUser.bind(this)
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.language !== nextProps.language) {
      this.displayStuff()
    }
  }

  componentDidMount() {
    ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      this.setState({ user: user }, () => this.displayStuff())
    })
  }

  displayStuff() {
    let trialPeriodEndDate
    if (this.state.user.isAdmin 
      || this.state.user.isCoach 
      || (this.state.user.isLeader 
        && (this.state.user.dateTrialPeriodEnd === null 
          || this.state.user.dateTrialPeriodEnd === undefined))) {
      trialPeriodEndDate = <Paragraph text={this.props.language === 'IT' ? 
        "Periodo di prova non applicabile in quanto già leader o coach." : 
        "Trial period not applicable since already leader or coach."}/>
    } else {
      if (this.state.user.dateTrialPeriodEnd === null || this.state.user.dateTrialPeriodEnd === undefined) {
      trialPeriodEndDate = <Paragraph text={this.props.language === 'IT' ? 
      "Contatta info@teamx.tech per iniziare il periodo di prova." : 
      "Reach out to info@teamx.tech to start the trial period."}/>
    } else {
      trialPeriodEndDate = <Paragraph text={new Date(this.state.user.dateTrialPeriodEnd).toLocaleString()}/>
    }
    }
    if (this.state.user.isAdmin) {
      this.setState({ display: 
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{paddingLeft: "20px"}}>
          <Paper elevation={3} style={{width: "90%", marginTop: "20px", paddingBottom: "10px"}}>
            <SecondaryHeader text="Email:"/>
            <Paragraph text={this.state.user.username}/>
            <SecondaryHeader text={this.props.language === 'IT' ? "Registrato da:" : "Registered since:"}/>
            <Paragraph text={this.state.user.createdAt}/>
            <SecondaryHeader text={this.props.language === 'IT' ? "Periodo di prova:" : "Trial period:"}/>
            {trialPeriodEndDate}
            <SecondaryHeader text={this.props.language === 'IT' ? "Notifiche: " : "Notifications:"}/>
            <Paragraph text={this.state.user.notificationsActive ? "On" : "Off"}/>
            <Button variant="contained" color="primary"
              onClick={() => this.toggleNotifications(this.state.user, this.state.user.notificationsActive)}>
              {this.state.user.notificationsActive ? 
                this.props.language === 'IT' ? "Disattiva" : "Deactivate" 
                : 
                this.props.language === 'IT' ? "Attiva" : "Activate"}
            </Button>
            <SecondaryHeader text={this.props.language === 'IT' ? "Permessi speciali:" : "Special permissions:"}/>
            <Paragraph text={this.state.user.isAdmin ? "Admin: Yes" : "Admin: No"}/>
            <Paragraph text={this.state.user.isCoach ? "Coach: Yes" : "Coach: No"}/>
            <Paragraph text={this.state.user.isLeader ? "Leader: Yes" : "Leader: No"}/>
          </Paper>
          <Paper elevation={3} style={{width: "90%", marginTop: "20px", paddingBottom: "10px"}}>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} 
        style={{paddingLeft: "10px", paddingRight: "10px"}}>
            <SecondaryHeader text={this.props.language === 'IT' ? "Invita:" : "Invite:"}/>
            <TextField
              label="Email"
              defaultValue={this.state.newUserMail}
              onChange={event => {
                const { value } = event.target;
                this.setState({ newUserMail: value });
              }}
              fullWidth
              variant="outlined"
            />
            <Paragraph text={this.props.language === 'IT' ? "Permessi speciali:" : "Special permissions:"}/>
            <FormControlLabel
              value="top"
              control={<Checkbox
                checked={this.state.isAdminChecked}
                onChange={(e) => this.handleCheck(e, "isAdmin")}
              />}
              label="Admin?"
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              control={<Checkbox
                checked={this.state.isCoachChecked}
                onChange={(e) => this.handleCheck(e, "isCoach")}
              />}
              label="Coach?"
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              control={<Checkbox
                checked={this.state.isLeaderChecked}
                onChange={(e) => this.handleCheck(e, "isLeader")}
              />}
              label="Leader?"
              labelPlacement="end"
            />
            <Button variant="contained" color="primary" 
              onClick={this.inviteUser}>
              {this.props.language === 'IT' ? "Invita" : "Invite"}
            </Button>
        </Grid>
          </Paper>
        </Grid>,
        loadingScreen: null
      })
    } else {
      this.setState({ display: 
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
          <Paper elevation={3} style={{width: "90%", marginTop: "20px", paddingBottom: "10px"}}>
            <SecondaryHeader text="Email:"/>
            <Paragraph text={this.state.user.username}/>
            <SecondaryHeader text={this.props.language === 'IT' ? "Registrato da:" : "Registered since:"}/>
            <Paragraph text={this.state.user.createdAt}/>
            <SecondaryHeader text={this.props.language === 'IT' ? "Periodo di prova:" : "Trial period:"}/>
            {trialPeriodEndDate}
            <SecondaryHeader text={this.props.language === 'IT' ? "Notifiche: " : "Notifications:"}/>
            <Paragraph text={this.state.user.notificationsActive ? "On" : "Off"}/>
            <Button variant="contained" color="primary"
              onClick={() => this.toggleNotifications(this.state.user, this.state.user.notificationsActive)}>
              {this.state.user.notificationsActive ? 
                this.props.language === 'IT' ? "Disattiva" : "Deactivate" 
                : 
                this.props.language === 'IT' ? "Attiva" : "Activate"}
            </Button>
            <SecondaryHeader text={this.props.language === 'IT' ? "Permessi speciali:" : "Special permissions:"}/>
            <Paragraph text={this.state.user.isAdmin ? "Admin: Yes" : "Admin: No"}/>
            <Paragraph text={this.state.user.isCoach ? "Coach: Yes" : "Coach: No"}/>
            <Paragraph text={this.state.user.isLeader ? "Leader: Yes" : "Leader: No"}/>
          </Paper>
        </Grid>,
        loadingScreen: null
      })
    }
  }

  handleCheck(event, checkbox) {
    if (checkbox === "isAdmin"){
      this.setState({ isAdmin: event.target.checked })
    } else if (checkbox === 'isCoach' ) {
      this.setState({ isCoach: event.target.checked })
    } else {
      this.setState({ isLeader: event.target.checked })
    }
  }

  startTrial(user) {
    user.dateTrialPeriodEnd = new Date().valueOf() + 30*24*60*60*1000
    user.isLeader = true
    delete user.updatedAt
    delete user.createdAt
    ApiUtils.updateUser(user)
    MailHelper.sendTrialStartMail(this.props.user.attributes.email.toLowerCase())
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  toggleNotifications(user, currentState) {
    user.notificationsActive = !currentState
    delete user.updatedAt
    delete user.createdAt
    ApiUtils.updateUser(user).then(
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    )
  }

  async inviteUser() {
    const userMail = this.state.newUserMail.toLowerCase().trim()
    const isLeader = this.state.isLeader
    const isAdmin = this.state.isAdmin
    const isCoach = this.state.isCoach
    var poolData = {
      UserPoolId: "eu-west-1_RbL4cSFph",
      Username: userMail,
      DesiredDeliveryMediums: ["EMAIL"],
      TemporaryPassword: "password",
      UserAttributes: [
        {
          Name: "email",
          Value: userMail
        },
        {
          Name: "email_verified",
          Value: "true"
        }
      ]
    };
    Auth.currentCredentials()
      .then(credentials => {
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        COGNITO_CLIENT.adminCreateUser(poolData, (error, data) => {
          if (error) {
            if (error.message === "An account with the given email already exists.") {
              ApiUtils.getUser(userMail).then((user) => {
                user.isLeader = isLeader
                user.isAdmin = isAdmin
                user.isCoach = isCoach
                user.owner = user.cognitoId
                user.dateTrialPeriodEnd = null
                delete user.createdAt;
                delete user.updatedAt;
                ApiUtils.updateUser(user)
              })
            } else {
              console.log(error.message)
            }
          } else {
            ApiUtils.createUser({
              "username": userMail, 
              "cognitoId": data.User.Username,
              "owner": data.User.Username,
              "myProfile": "", 
              "teamsOwned": [],
              "teamsPartOf": [],
              "isLeader": isLeader,
              "isAdmin": isAdmin,
              "isCoach": isCoach,
              "reports": [],
              "surveys": [],
              "notificationsActive": true,
            })
          }
        });
      })
  }

  render() {
  return (
    <div>
      {this.state.display}
      {this.state.loadingScreen}
    </div>
  );
  }
}

export default Account;