import React from 'react';
import Typography from '@material-ui/core/Typography';
import RadioGroup from './RadioGroup'

export default function SurveyQuestion(props) {
  return (
    <div>
      <Typography variant="h6" style={{padding: "20px 20px 0 20px"}}>
        {props.questionText}
      </Typography>
      <RadioGroup 
        value={props.questionValue}
        onChange={props.onChange} 
      />
      <hr/>
    </div>
  );
}