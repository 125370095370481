import { API, graphqlOperation } from 'aws-amplify'
import { createTeam, deleteTeam, updateTeam,
    createUser, updateUser, 
    createSurvey, updateSurvey,
    createReport, updateReport, 
    createArticle, updateArticle, deleteArticle,
    createUserFeedback } from '../graphql/mutations'
import { listTeams, getTeam, 
    listUsers, 
    listSurveys,
    listReports,
    listArticles, getArticle,
    listUserFeedbacks} from '../graphql/queries'

const limit = 1000000000
var ApiUtils = {

// Teams

    async createTeam(team) {
        const teamCreated = await API.graphql(graphqlOperation(createTeam, {input: team}))
        return teamCreated.data.createTeam
    },

    async deleteTeam(teamId) {
        await API.graphql(graphqlOperation(deleteTeam, {input: {id: teamId}}))
    },

    async updateTeam(team) {
        await API.graphql(graphqlOperation(updateTeam, {input: team}))
    },

    async getTeamById(teamId) {
        let team = await API.graphql(graphqlOperation(getTeam, {id: teamId}))
        return team.data.getTeam
    },

    async getTeam(teamName) {
        let teams = await API.graphql(graphqlOperation(listTeams, {
            filter: {
                teamName: {  eq: teamName }
            },
            limit: limit
        }))
        return teams.data.listTeams.items[0]
    },

    async getTeamsForLeader(user) {
        let teams = await API.graphql(graphqlOperation(listTeams, {
            filter: {
                leader: { eq: user }
            },
            limit: limit
        }))
        return teams.data.listTeams.items
    },

    async getTeamsForUser(user) {
        let teams = await API.graphql(graphqlOperation(listTeams, {
            filter: {
                users: { contains: user }
            },
            limit: limit
        }))
        return teams.data.listTeams.items
    },

// Users

    async updateUser(user) {
        return await API.graphql(graphqlOperation(updateUser, {input: user}))
    },

    async createUser(user) {
        await API.graphql(graphqlOperation(createUser, {input: user}))
    },

    async getUser(userMail) {
        let users = await API.graphql(graphqlOperation(listUsers, {
            filter: {
                username: {
                    eq: userMail
                }
            },
            limit: limit
        }))
        return users.data.listUsers.items[0]
    },

    async getUsersInTeam(teamId) {
        let users = await API.graphql(graphqlOperation(listUsers, {
            filter: {
                teamsPartOf: { contains: teamId }
            },
            limit: limit
        }))
        return users.data.listUsers.items
    },


// Surveys

    async createSurvey(survey) {
        await API.graphql(graphqlOperation(createSurvey, {input: survey}))
    },

    async updateSurvey(survey) {
        await API.graphql(graphqlOperation(updateSurvey, {input: survey}))
    },

    async getSurveysForUser(user) {
        let surveys = await API.graphql(graphqlOperation(listSurveys, {
            filter: {
                and: [
                    {user: { eq: user }},
                    {isArchived: { eq: false }},
                ]
            },
            limit: limit
        }))
        return surveys.data.listSurveys.items
    },

    async getSurveysForTeam(teamId) {
        let surveys = await API.graphql(graphqlOperation(listSurveys, {
            filter: {
                and: [
                    {teamId: { eq: teamId }},
                    {isArchived: { eq: false }},
                ]
            },
            limit: limit
        }))
        return surveys.data.listSurveys.items
    },

    async getSurveysForTeamAndUser(teamId, user) {
        let surveys = await API.graphql(graphqlOperation(listSurveys, {
            filter: {
                and: [
                    {teamId: { eq: teamId }},
                    {user: { eq: user }},
                    {isArchived: { eq: false }},
                ]
            },
            limit: limit
        }))
        return surveys.data.listSurveys.items
    },

    async getFormSurveysForUsers(users) {
        let orCondition = []
        for (const user of users) {
            orCondition.push({ user: { eq: user } })
        }
        let surveys = await API.graphql(graphqlOperation(listSurveys, {
            filter: {
                and: [
                    {type: { eq: "FORM" }},
                    {or: orCondition}
                ]
            },
            limit: limit
        }))
        return surveys.data.listSurveys.items
    },

// Reports

    async createReport(report) {
        const reportCreated = await API.graphql(graphqlOperation(createReport, {input: report}))
        return reportCreated.data.createReport
    },

    async updateReport(report) {
        await API.graphql(graphqlOperation(updateReport, {input: report}))
    },

    async getReportsForTeam(teamId) {
        let reports = await API.graphql(graphqlOperation(listReports, {
            filter: {
                teamId: { eq: teamId }
            },
            limit: limit
        }))
        return reports.data.listReports.items
    },

    async getActiveReportsForTeam(teamId) {
        let reports = await API.graphql(graphqlOperation(listReports, {
            filter: {
                and: [
                    {teamId: { eq: teamId }},
                    {isArchived: { eq: false }}
                ]
            },
            limit: limit
        }))
        return reports.data.listReports.items
    },

    async getReportByTypeForTeam(teamId, reportType) {
        let reports = await API.graphql(graphqlOperation(listReports, {
            filter: {
                and: [
                    {teamId: { eq: teamId }},
                    {type: { eq: reportType }},
                    {isArchived: { eq: false }}
                ]
            },
            limit: limit
        }))
        return reports.data.listReports.items[0]
    },

// Articles

    async createArticle(article) {
        const articleCreated = await API.graphql(graphqlOperation(createArticle, {input: article}))
        return articleCreated.data.createArticle
    },

    async updateArticle(article) {
        await API.graphql(graphqlOperation(updateArticle, {input: article}))
    },

    async deleteArticle(articleId) {
        await API.graphql(graphqlOperation(deleteArticle, {input: {id: articleId}}))
    },

    async getArticle(articleId) {
        let article = await API.graphql(graphqlOperation(getArticle, {id: articleId}))
        return article.data.getArticle
    },

    async getAllArticles() {
        let articles = await API.graphql(graphqlOperation(listArticles, {limit: limit}))
        return articles.data.listArticles.items
    },


    async getArticlesForUser(userMail) {
        let userTeams = await this.getTeamsForUser(userMail)
        let orCondition = [ { category: { eq: "INITIAL" } } ]
        if (userTeams.length > 0) {
            orCondition.push({ category: { eq: "FORM" } })
        }
        let stormFound = false
        let normFound = false
        let performFound = false
        for (const team of userTeams) {
            if (team.surveys.includes("STORM") || team.reports.includes("STORM")) {
                stormFound = true
            }
            if (team.surveys.includes("NORM") || team.reports.includes("NORM")) {
                normFound = true
            }
            if (team.surveys.includes("PERFORM") || team.reports.includes("PERFORM")) {
                performFound = true
            }
        }
        if (stormFound) {
            orCondition.push({ category: { eq: "STORM" } })
        }
        if (normFound) {
            orCondition.push({ category: { eq: "NORM" } })
        }
        if (performFound) {
            orCondition.push({ category: { eq: "PERFORM" } })
        }
        let articles = await API.graphql(graphqlOperation(listArticles, {
            filter: {
                or: orCondition
            },
            limit: limit
        }))
        return articles.data.listArticles.items
    },

    async getAboutArticles() {
        let articles = await API.graphql(graphqlOperation(listArticles, {
            filter: {
                or: [{ category: { eq: "ABOUT" } }]
            },
            limit: limit
        }))
        return articles.data.listArticles.items
    },

// UserFeedback

    async createUserFeedback(feedback) {
        const feedbackCreated = await API.graphql(graphqlOperation(createUserFeedback, {input: feedback}))
        return feedbackCreated.data.createUserFeedback
    },

    async getUserFeedbackForTeam(teamId) {
        let data = await API.graphql(graphqlOperation(listUserFeedbacks, {
            filter: {
                and: [
                    {teamId: { eq: teamId }},
                    {isArchived: { eq: false }}
                ]
            },
            limit: limit
        }))
        return data.data.listUserFeedbacks.items
    },

    async getUserFeedbackForUserForTeamForReportForProcess(username, teamId, surveyType, processNumber) {
        let data = await API.graphql(graphqlOperation(listUserFeedbacks, {
            filter: {
                and: [
                    {user: { eq: username }},
                    {teamId: { eq: teamId }},
                    {surveyType: { eq: surveyType }},
                    {processNumber: { eq: processNumber }},
                    {isArchived: { eq: false }}
                ]
            },
            limit: limit
        }))
        return data.data.listUserFeedbacks.items[0]
    },


}
export default ApiUtils;