import React from 'react';
import { withRouter } from "react-router";

import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import SecondaryHeader from '../../customComponents/SecondaryHeader'
import Article from './Article'

import ApiUtils from '../../api/ApiUtils';

class EditArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: "",
      title: "",
    }
  }

  componentDidMount() {
    ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      if (user.isAdmin) {
        ApiUtils.getArticle(this.props.match.params.articleId).then((article) => {
          this.setState({ content: article.content, title: article.title, allowed: true })
        })
      } else {
        this.setState({ allowed: false })
      }
    })
  }

  handleChange(event) {
    this.setState({ content: event.target.value })
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value })
  }


  saveArticle() {
    if (this.state.title === "") {
      alert("Per favore inserisci un titolo")
    } else if (this.state.content === "") {
      alert("Per favore inserisci il contenuto")
    } else {
      ApiUtils.updateArticle({
        "id": this.props.match.params.articleId,
        "title": this.state.title,
        "content": this.state.content,
      }).then(
        this.props.history.push('/articles')
      )
    }
  }

  render() {
    let editor
    if (this.state.allowed) {
      editor = <div>
        <br/>
        <Link href="https://www.markdownguide.org/basic-syntax/">Guida</Link>
        &nbsp;
        <Link href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet#youtube-videos">Video</Link>
        <br/>
        <TextField label="Titolo" value={this.state.title} onChange={(event) => this.handleTitleChange(event)} />
        <br/>
        <TextField style={{width: "90%"}} label="Contenuto" multiline value={this.state.content} onChange={(event) => this.handleChange(event)} />
        <br/>
        <br/>
        Preview:
        <Article id="articleId" title={this.state.title} content={this.state.content} timestamp="2020-01-01"/>
        <Box m={1}>
          <Button variant="contained" color="primary" 
            onClick={() => this.saveArticle()}>
            Salva
          </Button>
        </Box>
      </div>
    } else {
      editor = <SecondaryHeader text="You don't have access to this page." />
    }
    return (
      <div>
        {editor}
      </div>
    );
  }
}
export default withRouter(EditArticle)