var FormHelper = {

    getEnglishTeamProfileFromItalian(teamProfile) {
        const mapping = {
            "REALISTA": "REALIST",
            "CURIOSO": "CURIOUS",
            "INGEGNOSO": "INGENIOUS",
            "PROTETTIVO": "PROTECTIVE",
            "PIANIFICATORE": "PLANNER",
            "RISOLUTORE": "SOLVER",
            "OPERATIVO": "OPERATIVE",
            "GIUDIZIOSO": "WISE"
        }
        return mapping[teamProfile]
    },

    getFormDataForTeamType(teamProfile, language) {
        const mapping_team_profile_it = {
            "REALISTA": ["I team realisti di solito apprezzano l’accuratezza, l’ordine, la chiarezza, la precisione, la calma, la sicurezza, l’equilibrio, la conoscenza e il pragmatismo.",
                "Il team realista tende a concentrarsi sui dati, preferisce i fatti e la concretezza. Si tratta di un team pragmatico e concreto, che vuol trovare la risposta giusta alle domande che le situazioni impongono. I rischi per questi team, consistono nella tendenza a trascurare le informazioni legate alla dimensione emotiva, a non lanciarsi facilmente verso ciò che è incerto o innovativo, a perdere la visione più ampia e il senso di ciò che si sta facendo.",
                "I team realisti hanno bisogno prima di tutto di capire. Perciò, quando si tratta di comunicare è importante fornire informazioni in modo dettagliato e strutturato. Il team realista è più disposto ad assumersi dei rischi se ne vede i vantaggi concreti anche nel breve periodo. Per questo motivo, questo tipo di team è più motivato al cambiamento quando ne riconosce un’opportunità concreta. Trattandosi di un team spinto da un drive pratico, parlare di visione e futuro può risultare più semplice se si utilizzano esempi chiari e concreti, preferibilmente supportati da dati."],
            "CURIOSO": ["I team curiosi di solito apprezzano le relazioni, i processi che portano a far nascere idee e pensieri, sono a loro agio anche in contesti molto diversi dal loro. Sono calamitati dalle grandi idee, dall’innovazione e da chi possiede visione.",
                "Il team curioso preferisce affrontare gli ostacoli guardando avanti ed immaginandosi un futuro nuovo. E’ un team potenzialmente visionario, vivo, entusiasta e aperto nei confronti del cambiamento. E’ un trasformatore di eccellenza. Il rischio è che questo tipo di team disperda le sue energie, concentrandosi su aspetti poco pratici e allontanandosi dal focus, perdendo di credibilità ad occhi esterni. Inoltre un team curioso potrebbe essere talmente concentrato sugli aspetti emotivi che potrebbe perdere di vista i dati e i fatti.",
                "I team curiosi hanno bisogno di sentirsi emotivamente connessi con gli altri. Per catturare la loro attenzione, è bene parlare di futuro e puntare sull’immaginazione, piuttosto che su numeri e dati. I team curiosi sono solitamente amanti del rischio, ma potrebbero essere più disposti a rallentare in presenza di importanti motivazioni relazionali, come quella di coinvolgere altre persone all’interno del team. Questo tipo di team è meno interessato ai dettagli. È quindi preferibile iniziare con la visione d’insieme per poi arrivare ad aspetti di dettaglio. In questo modo le comunicazioni saranno più efficaci."],
            "INGEGNOSO": ["I team ingegnosi di solito apprezzano l’accuratezza, la precisione, l’approccio creativo ai problemi, l’opportunità, l’innovazione, l’energia e la diversità.",
                "Il team ingegnoso è principalmente focalizzato sulla ricerca di nuove soluzioni. Questo team vuole esplorare, individuare nuove strade, generare idee, anche se comporta dei rischi. I team ingegnosi preferiscono l’immaginazione e il mondo delle possibilità. I team ingegnosi rischiano di non essere compresi da chi li osserva dall’esterno, sono talmente concentrati sul futuro, che potrebbero non accorgersi di ciò che avviene intorno a loro e trascurare le interazioni umane.",
                "I team ingegnosi sono generalmente guidati dalle opportunità, perciò saranno meno propensi ad utilizzare programmi, tempistiche e check list. Inoltre amano il cambiamento e le sfide. Per rallentare il loro ritmo, aiutali a vedere che per raggiungere il loro obiettivo serve gradualità e fallo comunicando in maniera diretta. I team di questo tipo solitamente amano provare, fare, testare. Per questo fagli sperimentare le diverse possibilità, mantenendo il focus sul futuro, magari attraverso una mappa che li porterà dalla situazione attuale a quella desiderata."],
            "PROTETTIVO": ["I team Protettivi di solito apprezzano la sicurezza, il rispetto, le relazioni, le procedure e il senso pratico.",
                "Il team protettivo è attento alle persone, cauto e pragmatico. Sicuramente fonte di certezze, fiducia e affidabilità. Per i membri del team mantenere la stabilità è uno degli obiettivi principali. Il rischio è quello di apparire come un team chiuso nei confronti del cambiamento e troppo conservativo, non è sempre possibile evitare ciò che è incerto.",
                "I team protettivi sono focalizzati sulle persone e sulla sicurezza. Per motivarli al cambiamento, fai leva sulle relazioni, sulle connessioni e sulle opportunità che potrebbero derivarne. Impedire a questi tipi di team di essere protettivi potrebbe non essere una strategia efficace. Piuttosto aiutali a vedere come anche una diversa alternativa potrebbe essere percorribile e positiva per tutti membri del team. Infine, i team protettivi solitamente amano il particolare. Potrebbe essere più efficace comunicare utilizzando casi concreti, idee dettagliate, fase per fase, con informazioni puntuali."],
            "PIANIFICATORE": ["I team pianificatori di solito apprezzano l’accuratezza, la raccolta di indizi, prove e informazioni, la sicurezza e la logica strategica.",
                "Il team Pianificatore è focalizzato sulla ricerca della soluzione giusta e strategica, anche se è difficile da trovare. Questo team vuole che ci sia un senso dietro alle azioni che si intraprendono. Il rischio è di essere visti dall’esterno come dei perfezionisti, che hanno tempi molto lunghi per passare dalla decisione all’azione. Essere accurati è una caratteristica preziosa, ma attenzione a non farsi bloccare.",
                "Il team pianificatore ha bisogno di analizzare tutto minuziosamente. Se spinto al cambiamento troppo rapidamente, potrebbe sentirsi minacciato e porre degli ostacoli. Per migliorare le relazioni all’interno dei team di questo tipo, potrebbe essere utile aiutare le persone a vedere gli impatti che generano le decisioni del gruppo sullo stato emotivo proprio e altrui. I team Pianificatori potrebbero essere più aperti al cambiamento se i vantaggi sono chiari e allineati con i loro obiettivi. Può essere utile cercare di avvicinare il team con la realtà, osservando l’impatto di azioni attuali sul lungo termine."],
            "RISOLUTORE": ["I team risolutori di solito apprezzano la creatività, il divertimento, le relazioni, il senso pratico, i risultati eccellenti e il successo.",
                "Il team risolutore è creativo, dinamico, energico, vitale, attivo e pragmatico. E’ sempre disponibile, è un punto di riferimento all’interno dell’organizzazione, sempre pronto farsi carico dei problemi e a prendersi cura degli altri. Questo team è una fonte di energia, pieno di idee, con voglia di sperimentare e allo stesso tempo concreto, dotato di buone capacità di execution. Il rischio è quello di non riuscire a dosare le proprie energie, lasciando poco spazio per il confronto e la discussione, trascurando dati e informazioni razionali.",
                "I team risolutori danno valore a ciò che fanno e trovano motivazione nel risolvere problemi o situazioni complesse, questo fa sentire utili i membri del team. Per far sì che il team diventi più cauto, potrebbe essere utile incoraggiarlo a vedere come un atteggiamento più riflessivo potrebbe aiutarlo a risolvere i propri problemi. Nella comunicazione all’interno di un team risolutore, è bene focalizzarsi sul “qui ed ora”, sui bisogni attuali delle persone e sulle azioni pratiche."],
            "OPERATIVO": ["I team operativi apprezzano i dati, l’efficacia, l’innovazione, i risultati, la tempestività, la chiarezza, la determinazione e il coraggio.",
                "Il team operativo è analitico, pragmatico, orientato al compito e al risultato con la tendenza a lavorare sempre a ritmi elevati. E’ un team attratto da novità e cambiamenti, disposto ad assumersi dei rischi. Il rischio è quello di trascurare le persone e le relazioni pur di raggiungere l’obiettivo. Bisogna fare attenzione a non farsi travolgere dal senso di urgenza e dai ritmi di lavoro molto alti.",
                "Il team operativo è orientato all’azione, le discussioni che non si traducono in piani concreti potrebbero generare frustrazione. Questo tipo di team di solito si focalizza sul da farsi e non sugli aspetti emotivi. Se il contesto nel quale si muove il team necessita di questa dimensione, sarebbe meglio richiamare l’attenzione delle persone sul tema da subito. I team operativi di solito amano procedere velocemente e concludere le cose di cui si occupano, situazioni di attesa derivanti da fattori esterni  sono da gestire con attenzione, ricalibrando gli obiettivi e aiutando le persone a considerare il contesto strategico."],
            "GIUDIZIOSO": ["I team giudiziosi di solito apprezzano il pensiero critico, amano l’approfondimento, tendono alla serenità, all’armonia, allo studio e all’osservazione.",
                "Il team giudizioso è concentrato sulle persone, è un gruppo adatto al confronto e all’analisi dei pro e contro nelle diverse situazioni. La riflessione è importante ma attenzione a non trascurare l’azione. Il rischio infatti è quello di essere un team poco pratico o creativo.",
                "I team giudiziosi sono focalizzati sulle relazioni e sulle grandi idee. Perciò potrebbero essere più portati ad ascoltare altre idee quando colgono il senso complessivo della discussione. Questi team sono soliti non correre rischi. Per portarli verso l’innovazione potrebbe essere efficace far leva sulle emozioni e all’esperienza umana, piuttosto che cercare di convincerli attraverso dati razionali. Per comunicare efficacemente all’interno dei team giudiziosi, è solitamente più utile sfruttare la potenza evocativa di metafore e immagini."],
        }
        const mapping_team_profile_en = {
            "REALISTA": ["Realist teams usually appreciate accuracy, tidiness, clarity, precision, calm, safety, balance, knowledge and pragmatism.",
                "A realist team tends to focus on data, it prefers facts and concreteness. It is a pragmatic team that wants to find the right answer for all the different questions. The risk is to forget about emotional information, the tendency of not considering uncertain or new solutions. This team could loose the bigger picture and the sense of what it is doing.",
                "First of all, realist teams need to understand the situation. So it’s important to give structured and detailed information in order to have an effective communication. This team is willing to take risks if it can see real benefits, even in the short term. This is the reason why it is more engaged if it recognizes a concrete opportunity. Talking about a practical team, focusing on vision and future could be easier when there are clear and real examples, supported by data and short-term objectives."],
            "CURIOSO": ["Curios teams usually appreciate relationships and processes that can generate new thoughts. They can feel confident even in different contests. These teams are attracted by great ideas, innovation and people with vision of the future.",
                "The curious team prefers to face obstacles, looking forward and imaging a better future. This is a potentially visionary team, it is alive, enthusiastic and change oriented, it is a creator of excellence. The risk is wasting energy for non-practical things, stepping away from the real focus and loosing credibility from an external point of view. Furthermore, curious teams could be so focused on emotional aspects, that they may forget about data and facts.",
                "Curious teams need to feel emotionally connected with the other people. To catch their attention, you should talk about future and use imagination, instead of numbers and data. A curious team is usually risk-takers, but you can make them slow down for important relational reasons, involving new people inside the team for example. This type of team isn’r really interested in details. So the best strategy can be to start from the overview and then add more particular information. This would make the communication more effective."],
            "INGEGNOSO": ["Ingenious teams usually appreciate accuracy, details creative approaches, opportunities, innovation, energy and diversity.",
                "An ingenious team is mostly focus on searching new solutions, it wants to explore, find new ways, generate ideas, even if it could be a little bit risky. These teams prefer imagination and the world of possibilities. The risk is not to be understand from an external point of view. They are so concentrated on the future that they could ignore human relationships.",
                "Ingenious teams are usually driven by opportunities, so it might be harder for them to use programs, deadlines and check lists. In addition, they love changes and challenges. You can help them understand the importance of a step-by-step approach in order to make them slow down. These teams are willing to try, do things, test new ideas. Let them experience all the different opportunities but keep the focus on the future. You can use also a map that show how to go from the as-is situation, to the to-be one."],
            "PROTETTIVO": ["Protective teams usually appreciate safety, respect, relationships, procedures and practical approaches.",
                "A protective team is attentive to people, cautious and pragmatic. It is a source of certainties, trust and reliability. Maintaining the status quo is one of its objectives. The risk is to be seen as a team conservative and hostile to change, but it isn’t always possible to avoid uncertain situations.",
                "Protective teams are focus on people and safety. You should leverage on relationship and connection to make them accept changes. Pushing for change might not be the best strategy with this type of team. On the other hand, you can show them haw a different solution could be positive for all the team members. Finally, these teams are attracted by details. It could be easier to communicate using real case, precise ideas, step-by-step programs and punctual information."],
            "PIANIFICATORE": ["Planner teams usually appreciate accuracy, clues and informations, safety and strategic approaches.",
                "Planner teams are focused on finding the most suitable and strategic solution, even if it can be really difficult. They want all the action to have a sense, to belong to the bigger plan. The risk is to be perceived as perfectionists that need a lot of time to pass from decision to action. Being accurate is important but try not to be too demanding and go on.",
                "Planner teams need to analyze everything in details. If they are asked to move on too fast, they may feel threatened and stop working. You can try to help people seeing which might be the impact of a decision on group’s feeling and emotion, in order to improve relationships inside the team. Having benefits that are clear and aligned with objectives may be help the team being more adjustable and change oriented. A possible strategy could be to bring the team closer to reality and help them see the long-term consequences of a present decision."],
            "RISOLUTORE": ["Solver teams usually appreciate creativity, fun, relationship, practicality, excellent results and success.",
                "These kind of teams are creative, dynamic, full of energy, active and pragmatic. They are always available, a landmark inside the organization, ready to take care of others’ problems and situations. This team is a great source of energy and ideas, down-to-earth but with the desire to experiment and good execution skills. The risk is not to succeed in manage all the energy, letting too little space for discussion and dialogue, missing data and rational information.",
                "Solver teams give value to everything they do and they are motivated by solving problems and difficult situations. This is the thing that most engage the team members. You might show the team how being more evaluative could bring advantages to the problem solving. Focusing on the “here and now” is the best communication strategy, because it helps these teams to pay more attention to actual people’s needs."],
            "OPERATIVO": ["Operative teams usually appreciate data, efficiency, innovation, results, timing, clarity, venture and bravery.",
                "Operative teams are analytical, pragmatic, task oriented and they have the tendency of working at high rates. These type of teams are attracted by news and changes. The risk is to forget about people and relationship in order to reach objectives. You should try not to let high rates define how to work.",
                "Operative teams are task oriented, so it’s important to transform discussion in real plan. These kind of teams usually focus on the things to do and not on the emotions. If feelings need to be considered, it would be better to make them pay attention to emotional aspects since the beginning of the project. Operative teams usually prefer to work fast and finish tasks as soon as possible. Low rates and waiting situation must be managed carefully, helping people considering the strategic contest."],
            "GIUDIZIOSO": ["Wise teams usually appreciate critical thinking, deep knowledge, peace, harmony, analysis and observation.",
                "Wise teams are more focused on people, it is the perfect group for comparisons, discussions and pros-and-cons analysis. Is is more concentrated on great questions and the meaning of things, instead of practical actions. Being evaluative is important, but try not to forget action. The risk is to be a non practical and non creative team.",
                "Wise teams pay attention to relationships and great ideas, so they are more willing to listen if the meaning of the discussion is clear. These kinds of teams aren’t risk-takers. Insisting on feelings and human experiences may be the right strategy to lead them towards change. They probably won’t be attracted by rational information and data. Metaphors and Pictures are important in order to achieve great results and communicate more effectively."],
        }
        if (language === 'IT') {
            return mapping_team_profile_it[teamProfile]
        } else {
            return mapping_team_profile_en[teamProfile]
        }
    },

    getSuggestionsForTeamType(teamProfile, language) {
        const mapping_team_profile_it = {
            "REALISTA": ["Comunichiamo in modo dettagliato e strutturato.",
                "Cerchiamo i vantaggi concreti dei cambiamenti.",
                "Definiamo sempre piani di azione chiari."],
            "CURIOSO": ["Diamo più spazio alle emozioni nelle riunioni.",
                "Domandiamoci sempre 'Cosa accadrebbe se...?'",
                "Partiamo dalla visione, poi passiamo alla pratica."],
            "INGEGNOSO": ["Alziamo il livello della sfida.",
                "Sperimentiamo senza paura di sbagliare.",
                "Partiamo sempre dall'immaginare il futuro."],
            "PROTETTIVO": ["Manteniamo una forte comunicazione tra di noi.",
                "Concentriamoci sui lati positivi del cambiamento.",
                "Definiamo obiettivi sfidanti ma realizzabili."],
            "PIANIFICATORE": ["Raccogliamo e rendiamo più visibili i dati oggettivi.",
                "Dedica più tempo all'analisi prima di decidere.",
                "Partiamo dal perché poi passiamo alla pratica."],
            "RISOLUTORE": ["Usiamo le emozioni per capire cosa è giusto fare.",
                "Mettiamo più spazio tra discussione e decisioni.",
                "Concludiamo le riunioni con proposte più pratiche."],
            "OPERATIVO": ["Definiamo con più chiarezza chi fa cosa.",
                "Non rimandiamo ciò che possiamo fare subito.",
                "Concludiamo gli incontri con obiettivi concreti."],
            "GIUDIZIOSO": ["Ascoltiamo le emozioni delle persone.",
                "Lasciamo spazio al confronto prima di decidere.",
                "Definiamo le priorità in modo che siano sostenibili."],
        }
        const mapping_team_profile_en = {
            "REALISTA": ["Comunichiamo in modo dettagliato e strutturato.",
                "Cerchiamo i vantaggi concreti dei cambiamenti.",
                "Definiamo sempre piani di azione chiari."],
            "CURIOSO": ["Diamo più spazio alle emozioni nelle riunioni.",
                "Domandiamoci sempre 'Cosa accadrebbe se...?'",
                "Partiamo dalla visione, poi passiamo alla pratica."],
            "INGEGNOSO": ["Alziamo il livello della sfida.",
                "Sperimentiamo senza paura di sbagliare.",
                "Partiamo sempre dall'immaginare il futuro."],
            "PROTETTIVO": ["Manteniamo una forte comunicazione tra di noi.",
                "Concentriamoci sui lati positivi del cambiamento.",
                "Definiamo obiettivi sfidanti ma realizzabili."],
            "PIANIFICATORE": ["Raccogliamo e rendiamo più visibili i dati oggettivi.",
                "Dedica più tempo all'analisi prima di decidere.",
                "Partiamo dal perché poi passiamo alla pratica."],
            "RISOLUTORE": ["Usiamo le emozioni per capire cosa è giusto fare.",
                "Mettiamo più spazio tra discussione e decisioni.",
                "Concludiamo le riunioni con proposte più pratiche."],
            "OPERATIVO": ["Definiamo con più chiarezza chi fa cosa.",
                "Non rimandiamo ciò che possiamo fare subito.",
                "Concludiamo gli incontri con obiettivi concreti."],
            "GIUDIZIOSO": ["Ascoltiamo le emozioni delle persone.",
                "Lasciamo spazio al confronto prima di decidere.",
                "Definiamo le priorità in modo che siano sostenibili."],
        }
        if (language === 'IT') {
            return mapping_team_profile_it[teamProfile]
        } else {
            return mapping_team_profile_en[teamProfile]
        }
    },

}
export default FormHelper;