import React from 'react';
import { withRouter } from "react-router";

import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import SecondaryHeader from '../../customComponents/SecondaryHeader'
import Article from './Article'

import ApiUtils from '../../api/ApiUtils';

class NewArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newPost: "",
      newTitle: "",
      category: "",
    }
  }

  componentDidMount() {
    ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      if (user.isAdmin) {
        this.setState({ allowed: true })
      } else {
        this.setState({ allowed: false })
      }
    })
  }

  handleChange(event) {
    this.setState({ newPost: event.target.value })
  }

  handleTitleChange(event) {
    this.setState({ newTitle: event.target.value })
  }


  createArticle() {
    if (this.state.newTitle === "") {
      alert("Per favore inserisci un titolo")
    } else if (this.state.newPost === "") {
      alert("Per favore inserisci il contenuto")
    } else if (this.state.category === "") {
      alert("Per favore inserisci una categoria")
    } else {
      ApiUtils.createArticle({
        "title": this.state.newTitle,
        "content": this.state.newPost,
        "category": this.state.category,
      }).then(
        this.props.history.push('/articles')
      )
    }
  }

  render() {
    let editor
    if (this.state.allowed) {
      editor = <div>
        <br/>
        <Link href="https://www.markdownguide.org/basic-syntax/">Guida</Link>
        &nbsp;
        <Link href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet#youtube-videos">Video</Link>
        <br/>
        <TextField label="Titolo" value={this.state.newTitle} onChange={(event) => this.handleTitleChange(event)} />
        <br/>
        <TextField label="Categoria" value={this.state.category} onChange={(event) => this.setState({ category: event.target.value })} />
        <br/>
        <TextField style={{width: "90%"}} label="Contenuto" multiline value={this.state.newPost} onChange={(event) => this.handleChange(event)} />
        <br/>
        <br/>
        Preview:
        <Article id="articleId" title={this.state.newTitle} content={this.state.newPost} timestamp="2020-01-01"/>
        <Box m={1}>
          <Button variant="contained" color="primary" 
            onClick={() => this.createArticle()}>
            Salva
          </Button>
        </Box>
      </div>
    } else {
      editor = <SecondaryHeader text="You don't have access to this page." />
    }
    return (
      <div>
        {editor}
      </div>
    );
  }
}
export default withRouter(NewArticle)