import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Paragraph(props) {
  return (
    <div>
      <Typography variant="h6" style={{paddingLeft: "10px", paddingBottom:"10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
        {props.text}
      </Typography>
    </div>
  );
}