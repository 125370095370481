import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';

import FormHelper from '../../api/FormHelper';

const mapping_actions = {
  "data_mining": ["FOCUS RAZIONALE", "Assicurati che il team abbia a disposizione le info necessarie"],
  "modeling": ["FOCUS RAZIONALE", "Organizza le informazioni disponibili in uno schema generale"],
  "prioritizing": ["FOCUS RAZIONALE", "Definisci le informazioni chiave per il team"],
  "connection": ["FOCUS EMOTIVO", "Chiedi più spesso cosa provano le persone"],
  "emotional_insights": ["FOCUS EMOTIVO", "Domandati cosa ha generato uno stato d'animo e che impatti può avere"],
  "emotional_intelligence": ["FOCUS EMOTIVO", "Domandati cosa ha generato uno stato d'animo e che impatti può avere"],
  "collaboration": ["FOCUS EMOTIVO", "Migliora la conoscenza e le relazioni tra le persone"],
  "reflection": ["DECISION MAKING RIFLESSIVO", "Lascia più spazio tra la discussione e le decisioni"],
  "adaptability": ["DECISION MAKING RIFLESSIVO", "Valorizza le diverse opinioni all’interno del team"],
  "critical_thinking": ["DECISION MAKING RIFLESSIVO", "Approfondisci pro e contro delle alternative disponibili"],
  "resilience": ["DECISION MAKING CREATIVO", "Aumenta il numero di alternative tra cui scegliere"],
  "risk_tolerance": ["DECISION MAKING CREATIVO", "Accetta il rischio e sperimenta soluzioni diverse"],
  "imagination": ["DECISION MAKING CREATIVO", "Dedica tempo ad immaginare con il team futuri possibili"],
  "proactivity": ["DRIVE PRATICO", "Anticipa i bisogni e agisci prima che sia richiesto"],
  "commitment": ["DRIVE PRATICO", "Mantieni la massima attenzione sulle priorità del team"],
  "problem_solving": ["DRIVE PRATICO", "Affronta tempestivamente eventuali situazioni critiche"],
  "vision": ["DRIVE IDEALISTA", "Discuti col team la visione di lungo periodo"],
  "design": ["DRIVE IDEALISTA", "Condividi il percorso per avere un futuro di successo"],
  "entrepreneurship": ["DRIVE IDEALISTA", "Dedica tempo all’innovazione per vincere le nuove sfide"],
  "trust": ["TRUST", "Favorisci un ambiente dove potersi esprimere e fidarsi"],
  "synergy": ["SYNERGY", "Valorizza le competenze del team e la collaborazione"],
  "energy": ["ENERGY", "Definisci con il team il prossimo passo verso l'eccellenza"],
}

const mapping_actions_en = {
  "data_mining": ["FOCUS RATIONAL", "Make sure the team has all the necessary information"],
  "modeling": ["FOCUS RATIONAL", "Organize the available information into a general scheme"],
  "prioritizing": ["FOCUS RATIONAL", "Define the key information for the team"],
  "connection": ["FOCUS EMOTIONAL", "Ask more frequently about people’s feelings"],
  "emotional_insights": ["FOCUS EMOTIONAL", "Ask yourself what has generated a state of mind and which impact could have"],
  "emotional_intelligence": ["FOCUS EMOTIONAL", "Ask yourself what has generated a state of mind and which impact could have"],
  "collaboration": ["FOCUS EMOTIONAL", "Improve understanding and relationship among people"],
  "reflection": ["DECISION MAKING EVALUATIVE", "Allow more space between the discussion and the decision"],
  "adaptability": ["DECISION MAKING EVALUATIVE", "Appreciate the different opinions inside the team"],
  "critical_thinking": ["DECISION MAKING EVALUATIVE", "Further explore pros and cons of the available alternatives"],
  "resilience": ["DECISION MAKING CREATIVE", "Increase the number of alternatives you can choose among"],
  "risk_tolerance": ["DECISION MAKING CREATIVE", "Take risks and experience different solutions"],
  "imagination": ["DECISION MAKING CREATIVE", "Spend more time to imagine possible futures with the team"],
  "proactivity": ["DRIVE PRACTICAL", "Anticipate needs and act before it’s required"],
  "commitment": ["DRIVE PRACTICAL", "Keep the highest attention on team’s priorities"],
  "problem_solving": ["DRIVE PRACTICAL", "Face promptly eventual critical situations"],
  "vision": ["DRIVE IDEALIST", "Discuss with the team the long-term vision"],
  "design": ["DRIVE IDEALIST", "Share the path for a successful future"],
  "entrepreneurship": ["DRIVE IDEALIST", "Spend time on innovation to win new challenges"],
  "trust": ["TRUST", "Foster a team where people can discuss and feel safe"],
  "synergy": ["SYNERGY", "Build on team’s competences and collaboration"],
  "energy": ["ENERGY", "Define with the team the next step to excellence"],
}

function WeirdChart(props) {
  const style = {
    width: props.filledPerc, 
    borderRadius: "20px", 
    backgroundColor: "#a9a9a9", 
    height: "30px"
  }
  return <div style={{borderRadius: "20px", width: "198px", height: "30px", backgroundColor: "#e6e6e6", marginLeft:"auto", marginRight:"auto", marginTop:"10px"}}>
      <div style={style}>
      </div>
      <div style={{position: "relative", top: "-34px", left: "99px"}}>
        <div style={{height: "40px", width: "1px", backgroundColor: "#6b6b6b"}}>
        </div>
      </div>
      <div style={{position: "relative", top: "-74px", left: "49px"}}>
        <div style={{height: "40px", width: "1px", backgroundColor: "#6b6b6b"}}>
        </div>
      </div>
      <div style={{position: "relative", top: "-114px", left: "149px"}}>
        <div style={{height: "40px", width: "1px", backgroundColor: "#6b6b6b"}}>
        </div>
      </div>
  </div>
}

class WayReport extends React.Component {

  render() {
    let data = JSON.parse(this.props.data.data)
    console.log(data)

    let trustImg
    if (data.avg_trust > 2.5) {
      trustImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normPlus.png"
    } else {
      trustImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normMinus.png"
    }
    let synergyImg
    if (data.avg_synergy > 2.5) {
      synergyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normPlus.png"
    } else {
      synergyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normMinus.png"
    }
    let energyImg
    if (data.avg_energy > 2.5) {
      energyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normPlus.png"
    } else {
      energyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normMinus.png"
    }
    const trust = 
      <Grid item key="trust" style={{width:"90%"}}>
        <Paper elevation={3}>
          <img width="90%" alt="trust" src={trustImg}/>
          <Typography variant="h6" style={{margin:"20px"}}>
            Trust
          </Typography>
          <Typography variant="body1" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "La sensazione di un ambiente sicuro e affidabile." : "The sensation of a safe and reliable environment."}<br/>
          </Typography>
        </Paper>
      </Grid>
    const synergy = 
      <Grid item key="synergy" style={{width:"90%"}}>
        <Paper elevation={3}>
          <img width="90%" alt="synergy" src={synergyImg}/>
          <Typography variant="h6" style={{margin:"20px"}}>
            Synergy
          </Typography>
          <Typography variant="body1" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "La capacità di lavorare insieme in maniera efficace." : "The ability to work together effectively."}<br/>
          </Typography>
        </Paper>
      </Grid>
    const energy = 
      <Grid item key="energy" style={{width:"90%"}}>
        <Paper elevation={3}>
          <img width="90%" alt="energy" src={energyImg}/>
          <Typography variant="h6" style={{margin:"20px"}}>
            Energy
          </Typography>
          <Typography variant="body1" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "L’impegno dei vari membri per raggiungere risultati eccellenti come team." : "The effort of the various members to achieve eccellent results as a team."}<br/>
          </Typography>
        </Paper>
    </Grid>
    let dynamicsDisplay = []
    if (data.avg_energy >= data.avg_synergy) {
      if (data.avg_energy >= data.avg_trust) {
        if (data.avg_synergy >= data.avg_trust) {
          dynamicsDisplay.push(energy, synergy, trust)
        } else {
          dynamicsDisplay.push(energy, trust, synergy)
        }
      } else {
        dynamicsDisplay.push(trust, energy, synergy)
      }
    } else {
      if (data.avg_energy >= data.avg_trust) {
        dynamicsDisplay.push(synergy, energy, trust)
      } else {
        if (data.avg_synergy >= data.avg_trust) {
          dynamicsDisplay.push(synergy, trust, energy)
        } else {
          dynamicsDisplay.push(trust, synergy, energy)
        }
      }
    }

    const speedPerc = Math.round(data.avg_speed/5*100) + "%"
    const resultsPerc = Math.round(data.avg_results/5*100) + "%"
    const growthPerc = Math.round(data.avg_growth/5*100) + "%"
    const sustainabilityPerc = Math.round(data.avg_confidence/5*100) + "%"
    let nds
    if (data.net_development_score < 0) {
      if (this.props.language === 'IT') {
        nds = "Diminuita"
      } else {
        nds = "Lower"
      }
    } else if (data.net_development_score > 0) {
      if (this.props.language === 'IT') {
        nds = "Aumentata"
      } else {
        nds = "Higher"
      }
    } else {
      if (this.props.language === 'IT') {
        nds = "Uguale"
      } else {
        nds = "The same"
      }
    }
    const speed = 
    <Grid item key="speed" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Speed
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "Si parla di Speed come della percezione della velocità con cui il team risolve i problemi." : "We talk about Speed as the perception of how fast the team solve problems."}
        </Typography>
        <WeirdChart filledPerc={speedPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {speedPerc}
        </Typography>
        <Typography variant="h6" style={{margin:"20px"}}>
          Best Action:<br/>{this.props.language === 'IT' ? mapping_actions[data.speed_action][0] : mapping_actions_en[data.speed_action][0]}
        </Typography>
        <Typography variant="body1" style={{margin:"10px"}}>
          {this.props.language === 'IT' ? mapping_actions[data.speed_action][1] : mapping_actions_en[data.speed_action][1]}
        </Typography>
      </Paper>
    </Grid>
    const results =
    <Grid item key="results" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Results
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
        {this.props.language === 'IT' ? "Si parla di Results come della percezione dei risultati che il team sta ottenendo." : "We talk about Results as the level of productivity perceived by the team."}
        </Typography>
        <WeirdChart filledPerc={resultsPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {resultsPerc}
        </Typography>
        <Typography variant="h6" style={{margin:"20px"}}>
          Best Action:<br/>{this.props.language === 'IT' ? mapping_actions[data.results_action][0] : mapping_actions_en[data.results_action][0]}
        </Typography>
        <Typography variant="body1" style={{margin:"10px"}}>
          {this.props.language === 'IT' ? mapping_actions[data.results_action][1] : mapping_actions_en[data.results_action][1]}
        </Typography>
      </Paper>
    </Grid>
    const growth = 
    <Grid item key="growth" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Growth
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "Si parla di Growth come della percezione della crescita professionale del gruppo." : "We talk about Growth as the perception of professional improvement of the team members."}
        </Typography>
        <WeirdChart filledPerc={growthPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {growthPerc}
        </Typography>
        <Typography variant="h6" style={{margin:"20px"}}>
          Best Action:<br/>{this.props.language === 'IT' ? mapping_actions[data.growth_action][0] : mapping_actions_en[data.growth_action][0]}
        </Typography>
        <Typography variant="body1" style={{margin:"10px"}}>
          {this.props.language === 'IT' ? mapping_actions[data.growth_action][1] : mapping_actions_en[data.growth_action][1]}
        </Typography>
      </Paper>
    </Grid>
    const sustainability = 
    <Grid item key="sustainability" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Sustainability
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "Si parla di Sustainability come della percezione della fiducia e della tranquillità nei confronti delle performance future." : "We talk about Sustainability as the perception of trust and safety towards future."}
        </Typography>
        <WeirdChart filledPerc={sustainabilityPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {sustainabilityPerc}
        </Typography>
        <Typography variant="h6" style={{margin:"20px"}}>
          Best Action:<br/>{this.props.language === 'IT' ? mapping_actions[data.confidence_action][0] : mapping_actions_en[data.confidence_action][0]}
        </Typography>
        <Typography variant="body1" style={{margin:"10px"}}>
          {this.props.language === 'IT' ? mapping_actions[data.confidence_action][1] : mapping_actions_en[data.confidence_action][1]}
        </Typography>
      </Paper>
    </Grid>
    let outcomeDisplay = []
    let outcomeList = [
      {
        "value": data.avg_confidence,
        "display": sustainability,
        "name": "sustainability"
      },
      {
        "value": data.avg_growth,
        "display": growth,
        "name": "growth"
      },
      {
        "value": data.avg_results,
        "display": results,
        "name": "results"
      },
      {
        "value": data.avg_speed,
        "display": speed,
        "name": "speed"
      }
    ]
    outcomeList.sort(function(a, b) {return a.value - b.value;}).reverse()
    for (const outc of outcomeList) {
      outcomeDisplay.push(outc.display)
    }
    return (
      <div>
        <Typography variant="h6" style={{marginRight:"20px", padding: "30px"}}>
          <b>TEAM CHECK</b>
        </Typography>
        <Typography variant="body1">
          {this.props.language === 'IT' ? "Partecipanti: " + data.participants : "Participants: " + data.participants}
        </Typography>
        <Typography variant="h6" style={{marginRight:"20px", padding: "30px"}}>
        <b>TEAM PROFILE</b>
        </Typography>
        <Typography variant="h5">
          {this.props.language === 'IT' ? data.team_profile : FormHelper.getEnglishTeamProfileFromItalian(data.team_profile)}
        </Typography>
        <Typography variant="h6" style={{marginRight:"20px", padding: "30px"}}>
        <b>TEAM DYNAMICS</b>
        </Typography>

        <Grid direction="column" container justify="center" alignItems="center" spacing={5} style={{padding: "10px"}}>
          <Grid item key="disclaimertop" style={{width:"90%"}}>
            {this.props.language === 'IT' ?
              <Typography variant="body2" style={{padding: "10px", textAlign:"left"}}>
                Per ciascuna delle tre dinamiche di gruppo (Energy, Sinergy, Trust), 
                si trovano le seguenti informazioni: il segno +/- a seconda che 
                la percezione del team sia superiore/inferiore alla media del campione e la definizione della dinamica.
              </Typography> : 
              <Typography variant="body2" style={{padding: "10px", textAlign:"left"}}>
                For each of the three dynamics (Energy, Synergy, Trust), the report shows the following information: 
                the +/- sign depending on whether the value is higher / lower than the average of the normative value 
                and the definition of the dynamic.
              </Typography>
            }
          </Grid>
          {dynamicsDisplay}
        </Grid>

        <Typography variant="h6" style={{marginRight:"20px", padding:"30px"}}>
        <b>TEAM PERFORMANCE</b>
        </Typography>
        <Grid direction="column" container justify="center" alignItems="center" spacing={1} style={{padding: "10px"}}>
          <Grid item key="nds" style={{width:"90%"}}>
            <Paper elevation={3}>
              <Typography variant="body1" style={{margin:"20px"}}>
              {this.props.language === 'IT' ? "La percezione del team è che la performance negli ultimi 3 mesi sia" :
              "Thinking about the last 3 months, the team perceive that the performance is"}
              </Typography>
              <Typography variant="h6" style={{margin:"10px"}}>
                {nds}
              </Typography>
            </Paper>
          </Grid>
          <Grid item key="bestAction" style={{width:"90%"}}>
            <Paper elevation={3}>
              <Typography variant="h6" style={{margin:"20px"}}>
                Best Action:<br/>{this.props.language === 'IT' ? mapping_actions[data.total_best_action][0] : mapping_actions_en[data.total_best_action][0]}
              </Typography>
              <Typography variant="body1" style={{margin:"10px"}}>
                {this.props.language === 'IT' ? mapping_actions[data.total_best_action][1] : mapping_actions_en[data.total_best_action][1]}
              </Typography>
            </Paper>
          </Grid>
          <Grid item key="topdisclaimer" style={{width:"90%"}}>
            <Typography variant="body2" style={{margin:"10px", textAlign:"left"}}>
            {this.props.language === 'IT' ? 
              "Questa sezione è dedicata alla performance, che viene divisa in quattro outcome (Speed, Results, Sustainability, Growth). Sopra, la percezione generale rispetto agli ultimi 3 mesi e la Best Action Complessiva, ovvero l’azione che presenta il maggiore impatto complessivo sulla performance secondo i calcoli dell’algoritmo. Sotto, i riquadri riportano per ciascun outcome la definizione, il grafico con il valore della percezione del team e la specifica Best Action." :
              "This section is dedicated to the performance, divided in four outcomes (Speed, Results, Sustainability, Growth). Above, the general perception in the last 3 months and the Overall Best Action, the action that presents the biggest impact on the perfomance according to the algorithm. Below, for each outcome its definition, the chart with the value of the team's perception and the specific Best Action"
            }
            </Typography>
          </Grid>
          {outcomeDisplay}
        </Grid>
      </div>
    );
  }
}
export default WayReport