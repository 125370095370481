import { I18n } from 'aws-amplify';
export const signUpConfig = {
    hiddenDefaults: [
        'phone_number',
    ],
};
const authScreenLabels = {
    en: {
        'Sign in to your account': 'Log in to TeamX',
        'Enter your username': 'Enter your email',
        'Username': 'Email',
        'Create a new account': 'Sign up for TeamX'
    }
};
I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);