import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function NoAccess(props) {
  return (
    <div>
      <Typography variant="h6" style={{padding: "20px"}}>
        {props.text}
      </Typography>
    </div>
  );
}