import React from 'react';
import { withRouter } from "react-router";

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoAccess from '../../customComponents/NoAccess'

import ApiUtils from '../../api/ApiUtils'

class StormSurvey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      answer: "",
      modalOpen: false,
      loadingScreen: <CircularProgress/>,
      noAccess: null,
      submitDisabled: false
    }
  }

  async componentDidMount() {
    await ApiUtils.getTeamById(this.props.match.params.teamId).then((team) => {
      if (team !== null && team !== undefined 
        && team.users.includes(this.props.user.attributes.email.toLowerCase())
        && team.surveys.includes("STORM") ) {
          this.setState({ team:team, shouldPopulateSurvey: true, loadingScreen: null })
      } else {
        this.setState({ shouldPopulateSurvey: false,
          loadingScreen: null, 
          noAccess: <NoAccess text="La survey STORM non è attiva per questo team."/> 
        })
      }
    })
  }

  toggleModal(state) {
    this.setState({modalOpen: state})
  }

  async submitSurvey() {
    if (this.state.answer === "") {
      if (this.props.language === 'IT') {
        alert("Per favore rispondi alla domanda")
      } else {
        alert("Please answer the question")
      }
      return
    }
    await ApiUtils.createSurvey({
      "teamId": this.props.match.params.teamId,
      "user": this.props.user.attributes.email.toLowerCase(),
      "answers": [this.state.answer],
      "type": "STORM",
      "isArchived": false,
    })
    this.props.history.push('/home')
  }

  render() {
    let survey
    if (this.state.shouldPopulateSurvey) {
      survey = <div>
      <Typography variant="h6" style={{padding: "10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
        {this.props.language === 'IT' ? 
          "Di seguito troverete 1 domanda aperta. Non c'è una risposta giusta o sbagliata, rispondete semplicemente cio' che pensate." :
          "Below you'll find 1 open question. There is no right or wrong answer, simply answer what you think." }
      </Typography>
      <Typography variant="h6" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
        {this.state.team.stormQuestion === undefined || this.state.team.stormQuestion === null ?
          this.props.language === 'IT' ?
          "Un team funziona bene quando è chiaro chi-fa-cosa. " +
          "Come percepisci il tuo ruolo all’interno del team? " +
          "Sai cosa fare e cosa gli altri si aspettano da te? " +
          "Conosci il tuo ruolo e le responsabilità delle altre persone del team?" 
          :
          "A good team works when it’s clear who does what. What is your perception about your role inside the team? Do you know what to do and what others expect from you? Do you know your role and the responsibilities of the other team members? "
        : this.state.team.stormQuestion}
      </Typography>
      <TextField
        id="outlined-full-width"
        label={this.props.language === 'IT' ? "Risposta" : "Answer"}
        defaultValue={this.state.answer}
        onChange={event => {
          const { value } = event.target;
          this.setState({ answer: value });
        }}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
      <hr/>
      <Button variant="contained" color="primary" style={{margin: "20px"}}
        onClick={() => this.toggleModal(true)}>
        {this.props.language === 'IT' ? "Concludi il questionario" : "Finish the survey"}
      </Button>
      <Dialog
        open={this.state.modalOpen}
        onClose={() => this.toggleModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{this.props.language === 'IT' ? "Concludi il questionario" : "Finish the survey"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {this.props.language === 'IT' ? 
          "Una volta concluso il questionario, non potrai più cambiare le tue risposte." : 
          "Once you have submitted the survey, you won't be able to change your answers."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => this.toggleModal(false)} color="secondary">
          {this.props.language === 'IT' ? "Indietro" : "Back"}
        </Button>
        <Button variant="contained" color="primary" autoFocus
          disabled={this.state.submitDisabled} 
          onClick={() => {this.setState({submitDisabled:true}); this.submitSurvey() }} >
          {this.props.language === 'IT' ? "Conferma" : "Confirm"}
        </Button>
      </DialogActions>
      </Dialog>
    </div>
    }
    return (
      <div>
        {this.state.loadingScreen}
        {survey}
        {this.state.noAccess}
      </div>
    );
  }
}
export default withRouter(StormSurvey)