import React from 'react';

import { withRouter } from "react-router";

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import SecondaryHeader from '../customComponents/SecondaryHeader'
import Paragraph from '../customComponents/Paragraph'

import ApiUtils from '../api/ApiUtils'
import FormHelper from '../api/FormHelper';

const mapping_actions = {
  "data_mining": ["FOCUS RAZIONALE", "Assicurati che il team abbia a disposizione le info necessarie"],
  "modeling": ["FOCUS RAZIONALE", "Organizza le informazioni disponibili in uno schema generale"],
  "prioritizing": ["FOCUS RAZIONALE", "Definisci le informazioni chiave per il team "],
  "connection": ["FOCUS EMOTIVO", "Chiedi più spesso cosa provano le persone"],
  "emotional_insights": ["FOCUS EMOTIVO", "Domandati cosa ha generato uno stato d'animo e che impatti può avere"],
  "emotional_intelligence": ["FOCUS EMOTIVO", "Domandati cosa ha generato uno stato d'animo e che impatti può avere"],
  "collaboration": ["FOCUS EMOTIVO", "Migliora la conoscenza e le relazioni tra le persone"],
  "reflection": ["DECISION MAKING RIFLESSIVO", "Lascia più spazio tra la discussione e le decisioni"],
  "adaptability": ["DECISION MAKING RIFLESSIVO", "Valorizza le diverse opinioni all’interno del team"],
  "critical_thinking": ["DECISION MAKING RIFLESSIVO", "Approfondisci pro e contro delle alternative disponibili"],
  "resilience": ["DECISION MAKING CREATIVO", "Aumenta il numero di alternative tra cui scegliere"],
  "risk_tolerance": ["DECISION MAKING CREATIVO", "Accetta il rischio e sperimenta soluzioni diverse"],
  "imagination": ["DECISION MAKING CREATIVO", "Dedica tempo ad immaginare con il team futuri possibili"],
  "proactivity": ["DRIVE PRATICO", "Anticipa i bisogni e agisci prima che sia richiesto"],
  "commitment": ["DRIVE PRATICO", "Mantieni la massima attenzione sulle priorità del team"],
  "problem_solving": ["DRIVE PRATICO", "Affronta tempestivamente eventuali situazioni critiche"],
  "vision": ["DRIVE IDEALISTA", "Discuti col team la visione di lungo periodo"],
  "design": ["DRIVE IDEALISTA", "Condividi il percorso per avere un futuro di successo"],
  "entrepreneurship": ["DRIVE IDEALISTA", "Dedica tempo all’innovazione per vincere le nuove sfide"],
  "trust": ["TRUST", "Favorisci un ambiente dove potersi esprimere e fidarsi"],
  "synergy": ["SYNERGY", "Valorizza le competenze del team e la collaborazione"],
  "energy": ["ENERGY", "Definisci con il team il prossimo passo verso l'eccellenza"],
}

const mapping_actions_en = {
  "data_mining": ["FOCUS RATIONAL", "Make sure the team has all the necessary information"],
  "modeling": ["FOCUS RATIONAL", "Organize the available information into a general scheme"],
  "prioritizing": ["FOCUS RATIONAL", "Define the key information for the team"],
  "connection": ["FOCUS EMOTIONAL", "Ask more frequently about people’s feelings"],
  "emotional_insights": ["FOCUS EMOTIONAL", "Ask yourself what has generated a state of mind and which impact could have"],
  "emotional_intelligence": ["FOCUS EMOTIONAL", "Ask yourself what has generated a state of mind and which impact could have"],
  "collaboration": ["FOCUS EMOTIONAL", "Improve understanding and relationship among people"],
  "reflection": ["DECISION MAKING EVALUATIVE", "Allow more space between the discussion and the decision"],
  "adaptability": ["DECISION MAKING EVALUATIVE", "Appreciate the different opinions inside the team"],
  "critical_thinking": ["DECISION MAKING EVALUATIVE", "Further explore pros and cons of the available alternatives"],
  "resilience": ["DECISION MAKING CREATIVE", "Increase the number of alternatives you can choose among"],
  "risk_tolerance": ["DECISION MAKING CREATIVE", "Take risks and experience different solutions"],
  "imagination": ["DECISION MAKING CREATIVE", "Spend more time to imagine possible futures with the team"],
  "proactivity": ["DRIVE PRACTICAL", "Anticipate needs and act before it’s required"],
  "commitment": ["DRIVE PRACTICAL", "Keep the highest attention on team’s priorities"],
  "problem_solving": ["DRIVE PRACTICAL", "Face promptly eventual critical situations"],
  "vision": ["DRIVE IDEALIST", "Discuss with the team the long-term vision"],
  "design": ["DRIVE IDEALIST", "Share the path for a successful future"],
  "entrepreneurship": ["DRIVE IDEALIST", "Spend time on innovation to win new challenges"],
  "trust": ["TRUST", "Foster a team where people can discuss and feel safe"],
  "synergy": ["SYNERGY", "Build on team’s competences and collaboration"],
  "energy": ["ENERGY", "Define with the team the next step to excellence"],
}

class TeamDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      team: null, 
      loadingScreen: <CircularProgress/>,
      teamDetails: null,
      preview: false,
      previewSwitch: null
    }
  }
  
  componentDidMount() {
    ApiUtils.getTeamById(this.props.match.params.teamId).then((team) => {
      ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
        if (team.leader === this.props.user.attributes.email.toLowerCase() || user.isAdmin || team.users.includes(this.props.user.attributes.email.toLowerCase()) ) {
          let leader
          if (user.isAdmin || team.leader === this.props.user.attributes.email.toLowerCase()) {
            leader = true
          } else {
            leader = false
          }
          if (team.suggestions === null) {
            this.setState({ 
              leader: leader, team: team,
              teamDetails: 
              <div>
                <SecondaryHeader text={team.teamName}/>
                <Paragraph text={this.props.language === 'IT' ? "Quando verranno condivisi i report del team appariranno qui." :
                  "When the team reports will be shared they'll appear here."}/>,
                <br/>
                <br/>
              </div>,
            loadingScreen: null})
          } else {
            this.setState({ leader: leader, team: team,
              firstSuggestion: team.suggestions[0],
              secondSuggestion: team.suggestions[1],
              thirdSuggestion: team.suggestions[2] }, () => this.populateView(team))
          }
        } else {
          this.setState({ teamDetails: 
              <Typography style={{padding: "20px"}} variant="h6">
                Devi essere parte del team o leader per accedere a questa dashboard.
              </Typography>,
            loadingScreen: null
          })
        }
      })
    })
  }

  getEnglishMoodFromItalian(mood) {
    if (mood === "Diminuita") {
        return "Decreased"
    } else if (mood === "Uguale") {
        return "Remained the same"
    } else if (mood === "Aumentata") {
        return "Increased"
    }
  }

  getEnglishAverageFromItalian(average) {
    if (average === "Nettamente sopra la media") {
        return "Significantly above average"
    } else if (average === "Sopra la media") {
        return "Above average"
    } else if (average === "Nella media") {
        return "Average"
    } else if (average === "Sotto la media") {
        return "Below average"
    } else if (average === "Nettamente sotto la media") {
        return "Significantly below average"
    }
  }

  getEnglishTalentFromItalian(emotion) {
    if (emotion === "Razionale") {
        return "Rational"
    } else if (emotion === "Emotivo") {
        return "Emotive"
    } else if (emotion === "Riflessivo") {
        return "Evaluative"
    } else if (emotion === "Creativo") {
        return "Creative"
    } else if (emotion === "Pratico") {
        return "Practical"
    } else if (emotion === "Idealista") {
        return "Idealist"
    }
  }

  getEnglishEmotionFromItalian(emotion) {
    if (emotion === "Rabbia") {
        return "Rage"
    } else if (emotion === "Euforia") {
        return "Euphoria"
    } else if (emotion === "Frustrazione") {
        return "Discontempt"
    } else if (emotion === "Soddisfazione") {
        return "Contempt"
    }
  }

  saveSuggestions() {
    let team = this.state.team
    delete team.createdAt
    delete team.updatedAt
    team.suggestions = [this.state.firstSuggestion, this.state.secondSuggestion, this.state.thirdSuggestion]
    ApiUtils.updateTeam(team).then(() => {
      this.setState({ team: team }, () => this.populateView(team))
    })
  }

  saveTalent() {
    let team = this.state.team
    delete team.createdAt
    delete team.updatedAt
    team.talent = this.state.talent
    ApiUtils.updateTeam(team).then(() => {
      this.setState({ team: team }, () => this.populateView(team))
    })
  }

  saveDynamic() {
    let team = this.state.team
    delete team.createdAt
    delete team.updatedAt
    team.targetDynamic = this.state.dynamic
    ApiUtils.updateTeam(team).then(() => {
      this.setState({ team: team }, () => this.populateView(team))
    })
  }

  saveOutcome() {
    let team = this.state.team
    delete team.createdAt
    delete team.updatedAt
    team.targetOutcome = this.state.outcome
    ApiUtils.updateTeam(team).then(() => {
      this.setState({ team: team }, () => this.populateView(team))
    })
  }

  populateView(team) {
    if (team === null) {
      this.setState({ 
        teamDetails: "Nothing to show here."
      })
      return
    }
    let formDetails = null
    let stormDetails = null
    let normDetails = null
    let performDetails = null
    if (team.profile !== null && team.profile !== undefined) {
      if (this.state.leader) {
        this.setState({ previewSwitch: <div style={{width: "90%", margin: "20px auto"}}>
          <Grid container alignItems="center" justify="center" spacing={1}>
            <Grid item><EditIcon color="disabled"/></Grid>
            <Grid item>
              <Switch checked={this.state.preview} onChange={(event) => {
                this.setState({ preview: event.target.checked }, () => this.populateView(team));
              }} name="preview" />
            </Grid>
            <Grid item><VisibilityIcon color="disabled"/></Grid>
          </Grid>
          </div>
         })
      }
      if (this.state.leader && !this.state.preview) {
        formDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
          <SecondaryHeader text={this.props.language === 'IT' ? "Profilo: " + team.profile : 
            "Profile: " + FormHelper.getEnglishTeamProfileFromItalian(team.profile)}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Preferenze" : "Preferences"}/>
          <Paragraph text={FormHelper.getFormDataForTeamType(team.profile, this.props.language)[0]}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Promemoria" : "Memo"}/>
          <TextField
            id="suggOne" label={this.props.language === 'IT' ? "Primo promemoria" : "First memo"} defaultValue={team.suggestions[0]}
            onChange={event => {
              const { value } = event.target;
              this.setState({ firstSuggestion: value });
            }}
            fullWidth margin="normal" InputLabelProps={{shrink: true}} variant="outlined"
          />
          <TextField
            id="suggTwo" label={this.props.language === 'IT' ? "Secondo promemoria" : "Second memo"} defaultValue={team.suggestions[1]}
            onChange={event => {
              const { value } = event.target;
              this.setState({ secondSuggestion: value });
            }}
            fullWidth margin="normal" InputLabelProps={{shrink: true}} variant="outlined"
          />
          <TextField
            id="suggThree" label={this.props.language === 'IT' ? "Terzo promemoria" : "Third memo"} defaultValue={team.suggestions[2]}
            onChange={event => {
              const { value } = event.target;
              this.setState({ thirdSuggestion: value });
            }}
            fullWidth margin="normal" InputLabelProps={{shrink: true}} variant="outlined"
          />
          <Button variant="contained" color="primary" style={{margin: "20px"}}
            onClick={() => this.saveSuggestions()}>
            {this.props.language === "IT" ? "Salva" : "Save"}
          </Button>
        </Paper>
      } else {
        formDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
        <SecondaryHeader text={this.props.language === 'IT' ? "Profilo: " + team.profile : 
          "Profile: " + FormHelper.getEnglishTeamProfileFromItalian(team.profile)}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Preferenze" : "Preferences"}/>
          <Paragraph text={FormHelper.getFormDataForTeamType(team.profile, this.props.language)[0]}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Promemoria" : "Memo"}/>
          <Paragraph text={team.suggestions[0]}/>
          <Paragraph text={team.suggestions[1]}/>
          <Paragraph text={team.suggestions[2]}/>
        </Paper>
      }
    }
    if (team.sentimentScore !== null && team.sentimentScore !== undefined) {
      if (this.state.leader && !this.state.preview) {
        let talentOptions = [<option key="teamTalent" value={team.talent}>{this.props.language === 'IT' ? team.talent : this.getEnglishTalentFromItalian(team.talent)}</option>]
        if (team.talent !== "Razionale" && ["REALISTA", "PIANIFICATORE", "OPERATIVO", "INGEGNOSO"].includes(team.profile)) {
          talentOptions.push(<option key="Razionale" value={"Razionale"}>{this.props.language === 'IT' ? "Razionale" : "Rational"}</option>)
        }
        if (team.talent !== "Emotivo" && ["PROTETTIVO", "GIUDIZIOSO", "RISOLUTORE", "CURIOSO"].includes(team.profile)) {
          talentOptions.push(<option key="Emotivo" value={"Emotivo"}>{this.props.language === 'IT' ? "Emotivo" : "Emotive"}</option>)
        }
        if (team.talent !== "Riflessivo" && ["REALISTA", "PIANIFICATORE", "PROTETTIVO", "GIUDIZIOSO"].includes(team.profile)) {
          talentOptions.push(<option key="Riflessivo" value={"Riflessivo"}>{this.props.language === 'IT' ? "Riflessivo" : "Reflective"}</option>)
        }
        if (team.talent !== "Creativo" && ["OPERATIVO", "INGEGNOSO", "RISOLUTORE", "CURIOSO"].includes(team.profile)) {
          talentOptions.push(<option key="Creativo" value={"Creativo"}>{this.props.language === 'IT' ? "Creativo" : "Creative"}</option>)
        }
        if (team.talent !== "Pratico" && ["REALISTA", "OPERATIVO", "PROTETTIVO", "RISOLUTORE"].includes(team.profile)) {
          talentOptions.push(<option key="Pratico" value={"Pratico"}>{this.props.language === 'IT' ? "Pratico" : "Practical"}</option>)
        }
        if (team.talent !== "Idealista" && ["PIANIFICATORE", "INGEGNOSO", "GIUDIZIOSO", "CURIOSO"].includes(team.profile)) {
          talentOptions.push(<option key="Idealista" value={"Idealista"}>{this.props.language === 'IT' ? "Idealista" : "Idealistic"}</option>)
        }
        stormDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
          <SecondaryHeader text={this.props.language === 'IT' ? "Domanda: " : "Question: "}/>
          <Paragraph text={team.stormQuestion === undefined || team.stormQuestion === null ?
              this.props.language === 'IT' ?
              "Un team funziona bene quando è chiaro chi-fa-cosa. " +
              "Come percepisci il tuo ruolo all’interno del team? " +
              "Sai cosa fare e cosa gli altri si aspettano da te? " +
              "Conosci il tuo ruolo e le responsabilità delle altre persone del team?" 
              :
              "A good team works when it’s clear who does what. What is your perception about your role inside the team? Do you know what to do and what others expect from you? Do you know your role and the responsibilities of the other team members? "
            : team.stormQuestion}/>
          <SecondaryHeader text={"Sentiment Score: " + Math.round(team.sentimentScore)}/>
          <Paragraph text="Il risultato può variare da -100 a +100 a seconda del sentiment rilevato nelle varie risposte."/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Emozione Prevalente: " : "Prevailing Emotion: "}/>
          <Paragraph text={this.props.language === 'IT' ? team.emotion : this.getEnglishEmotionFromItalian(team.emotion)}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Talento: " : "Talent: "}/>
          <NativeSelect
            inputProps={{
              name: 'talent',
              id: 'talent-native-helper',
            }}
            onChange={event => {
            this.setState({ talent: event.target.value })
          }}>
            {talentOptions}
          </NativeSelect>
          <Button variant="contained" color="primary" style={{margin: "20px"}}
            onClick={() => this.saveTalent()}>
            {this.props.language === 'IT' ? "Salva" : "Save" }
          </Button>
        </Paper>
      } else {
        stormDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
          <SecondaryHeader text={this.props.language === 'IT' ? "Domanda: " : "Question: "}/>
          <Paragraph text={team.stormQuestion === undefined || team.stormQuestion === null ?
              this.props.language === 'IT' ?
              "Un team funziona bene quando è chiaro chi-fa-cosa. " +
              "Come percepisci il tuo ruolo all’interno del team? " +
              "Sai cosa fare e cosa gli altri si aspettano da te? " +
              "Conosci il tuo ruolo e le responsabilità delle altre persone del team?" 
              :
              "A good team works when it’s clear who does what. What is your perception about your role inside the team? Do you know what to do and what others expect from you? Do you know your role and the responsibilities of the other team members? "
            : team.stormQuestion}/>
          <SecondaryHeader text={"Sentiment Score: " + team.sentimentScore}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Emozione Prevalente: " : "Prevailing Emotion: "}/>
          <Paragraph text={this.props.language === 'IT' ? team.emotion : this.getEnglishEmotionFromItalian(team.emotion)}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Talento: " : "Talent: "}/>
          <Paragraph text={this.props.language === 'IT' ? team.talent : this.getEnglishTalentFromItalian(team.talent)}/>
        </Paper>
      }
    }
    if (team.normAverage !== null && team.normAverage !== undefined) {
      if (this.state.leader && !this.state.preview) {
        let dynamicOptions = [<option key="teamDynamic" value={team.targetDynamic}>{team.targetDynamic}</option>]
        if (team.targetDynamic !== "Trust") {
          dynamicOptions.push(<option key="Trust" value={"Trust"}>Trust</option>)
        }
        if (team.targetDynamic !== "Synergy") {
          dynamicOptions.push(<option key="Synergy" value={"Synergy"}>Synergy</option>)
        }
        if (team.targetDynamic !== "Energy") {
          dynamicOptions.push(<option key="Energy" value={"Energy"}>Energy</option>)
        }
        normDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
          <SecondaryHeader text={this.props.language === 'IT' ? "Relazioni: " + team.normAverage : "Relationships: " + this.getEnglishAverageFromItalian(team.normAverage)}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Area di deragliamento: " : "Warning area: "}/>
          <Paragraph text={team.lowDynamic}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Dinamica obiettivo: " : "Dynamic to increase: "}/>
          <NativeSelect
            inputProps={{
              name: 'dynamic',
              id: 'dynamic-native-helper',
            }}
            onChange={event => {
            this.setState({ dynamic: event.target.value })
          }}>
            {dynamicOptions}
          </NativeSelect>
          <Button variant="contained" color="primary" style={{margin: "20px"}}
            onClick={() => this.saveDynamic()}>
            {this.props.language === 'IT' ? "Salva" : "Save" }
          </Button>
        </Paper>
      } else {
        normDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
        <SecondaryHeader text={this.props.language === 'IT' ? "Relazioni: " + team.normAverage : "Relationships: " + this.getEnglishAverageFromItalian(team.performance)}/>
        <SecondaryHeader text={this.props.language === 'IT' ? "Area di deragliamento: " : "Warning area: "}/>
          <Paragraph text={team.lowDynamic}/>
          <SecondaryHeader text={this.props.language === 'IT' ? "Dinamica obiettivo: " : "Dynamic to increase: "}/>
          <Paragraph text={team.targetDynamic}/>
        </Paper>
      }
    }
    if (team.performance !== null && team.performance !== undefined) {
      if (this.state.leader && !this.state.preview) {
        let outcomeOptions = [<option key="teamOutcome" value={team.targetOutcome}>{team.targetOutcome}</option>]
        if (team.targetOutcome !== "Speed") {
          outcomeOptions.push(<option key="Speed" value={"Speed"}>Speed</option>)
        }
        if (team.targetOutcome !== "Results") {
          outcomeOptions.push(<option key="Results" value={"Results"}>Results</option>)
        }
        if (team.targetOutcome !== "Growth") {
          outcomeOptions.push(<option key="Growth" value={"Growth"}>Growth</option>)
        }
        if (team.targetOutcome !== "Sustainability") {
          outcomeOptions.push(<option key="Sustainability" value={"Sustainability"}>Sustainability</option>)
        }
        if (team.targetOutcome !== "Performance") {
          outcomeOptions.push(<option key="Performance" value={"Performance"}>Performance</option>)
        }
        performDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
          <SecondaryHeader text={this.props.language === 'IT' ? "Percezione di performance: " + team.performance : "Perception of performance: " + this.getEnglishAverageFromItalian(team.performance)}/>
          <SecondaryHeader text={"Mood: "}/>
          <Paragraph text={this.props.language === 'IT' ? 
            "La percezione del team è che la performance negli ultimi 3 mesi sia " + team.mood : 
            "The team's perception is that performance in the last 3 months has " + this.getEnglishMoodFromItalian(team.mood)}/>
          <SecondaryHeader text={"Best action: "}/>
          <NativeSelect
            inputProps={{
              name: 'dynamic',
              id: 'dynamic-native-helper',
            }}
            onChange={event => {
            this.setState({ outcome: event.target.value })
          }}>
            {outcomeOptions}
          </NativeSelect>
          <Button variant="contained" color="primary" style={{margin: "20px"}}
            onClick={() => this.saveOutcome()}>
            Salva
          </Button>
        </Paper>
      } else {
        let bestAction = JSON.parse(team.bestActions)
        performDetails = <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
          <SecondaryHeader text={this.props.language === 'IT' ? "Percezione di performance: " + team.performance : "Perception of performance: " + this.getEnglishAverageFromItalian(team.performance)}/>
          <SecondaryHeader text={"Mood: "}/>
          <Paragraph text={this.props.language === 'IT' ? 
            "La percezione del team è che la performance negli ultimi 3 mesi sia " + team.mood : 
            "The team's perception is that performance in the last 3 months has " + this.getEnglishMoodFromItalian(team.mood)}/>
          <SecondaryHeader text={"Target outcome: "}/>
          <Paragraph text={team.targetOutcome}/>
          <SecondaryHeader text={"Best action: " + this.props.language === 'IT' ? mapping_actions[bestAction[team.targetOutcome]][0] : mapping_actions_en[bestAction[team.targetOutcome]][0]}/>
          <Paragraph text={this.props.language === 'IT' ? mapping_actions[bestAction[team.targetOutcome]][1] : mapping_actions_en[bestAction[team.targetOutcome]][1]}/>
        </Paper>
      }
    }
    this.setState({ 
      team: team,
      loadingScreen: null,
      teamDetails: 
      <div>
      <SecondaryHeader text={team.teamName}/>
        {formDetails}
        {stormDetails}
        {normDetails}
        {performDetails}
        <br/>
        <br/>
      </div>
    })
  }

  render() {
    return (
      <div>
        {this.state.loadingScreen}
        {this.state.previewSwitch}
        {this.state.teamDetails}
      </div>
    );
  }
}
export default withRouter(TeamDashboard);
