import { Auth } from 'aws-amplify'
import ApiUtils from './ApiUtils';

const AWS = require("aws-sdk")

const mailFooter = "<p style='font-size:10px;margin-top:20px'><a href='https://app.teamx.solutions/account'>Gestisci le notifiche</a><br/>"
    + "Questa è una mail automatica, le risposte non sono monitorate.</p>"

var MailHelper = {

    sendIntroMail(toAddress) {
        Auth.currentCredentials().then(credentials => {
            AWS.config.update({
                region: 'eu-west-1',
                credentials: Auth.essentialCredentials(credentials)
            });
            const params = {
                Destination: {
                    CcAddresses: [],
                    ToAddresses: [toAddress]
                },
                Message: {
                    Body: {
                        Html: {
                            Charset: "UTF-8",
                            Data: `
                            <p>Ciao,<br/>
                            Benvenuto nell’app TEAMX, lo strumento di People Intelligence in grado di dare un supporto nella gestione della team performance.</p>

                            Le tue credenziali di accesso ti sono state mandate in una mail separata.</p>
                            
                            <p>Ciascuno di noi è caratterizzato da uno stile cerebrale prevalente, ha una caratteristica più forte e spiccata che lo contraddistingue. Scoprire i propri punti di forza, ci aiuta a raggiungere risultati migliori, sia come individui ma soprattutto quando lavoriamo in team.<br/>
                            Che aspetti? Vai nella <a href="https://app.teamx.solutions">home</a>, compila il questionario MYPROFILE e scopri che tipo di team player sei!</p>
                            
                            <p>Se vuoi saperne di più, nell’area ARTICOLI del menù sono disponibili una guida all’app e alcuni contenuti introduttivi sullo strumento TEAMX.</p>
                            
                            <p>Buon lavoro,<br/>
                            Il team TEAMX</p>
                            ` + mailFooter
                        },
                        Text: {
                            Charset: "UTF-8",
                            Data: "Benvenuto a TEAMX"
                        }
                },
                Subject: {
                        Charset: 'UTF-8',
                        Data: 'Vuoi scoprire che team player sei?'
                }
                },
                Source: 'app@teamx.solutions',
                ReplyToAddresses: ['app@teamx.solutions'],
                };
            
                // Create the promise and SES service object
                let sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
            
                // Handle promise's fulfilled/rejected states
                sendPromise.then(
                function(data) {
                    console.log(data.MessageId);
                }).catch(
                    function(err) {
                    console.error(err, err.stack);
                });
        })
    },

    sendTrialStartMail(toAddress) {
        Auth.currentCredentials().then(credentials => {
            AWS.config.update({
                region: 'eu-west-1',
                credentials: Auth.essentialCredentials(credentials)
            });
            const params = {
                Destination: {
                    CcAddresses: [],
                    ToAddresses: [toAddress]
                },
                Message: {
                    Body: {
                        Html: {
                            Charset: "UTF-8",
                            Data: `
                            <p>Ciao,<br/>
                            Hai iniziato il periodo di prova come Leader nell'app TEAMX!<br/>
                            Ti ricordiamo che il periodo di prova finirà tra un mese. <br/>
                            Per maggiori informazioni su continuare come Leader contatta info@teamx.tech</p>

                            <p>Buon lavoro,<br/>
                            Il team TEAMX</p>
                            ` + mailFooter
                        },
                        Text: {
                            Charset: "UTF-8",
                            Data: "Benvenuto a TEAMX"
                        }
                },
                Subject: {
                        Charset: 'UTF-8',
                        Data: 'Vuoi scoprire che team player sei?'
                }
                },
                Source: 'app@teamx.solutions',
                ReplyToAddresses: ['app@teamx.solutions'],
                };
            
                // Create the promise and SES service object
                let sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
            
                // Handle promise's fulfilled/rejected states
                sendPromise.then(
                function(data) {
                    console.log(data.MessageId);
                }).catch(
                    function(err) {
                    console.error(err, err.stack);
                });
        })
    },

    sendTrialAboutToExpireMail(toAddress) {
        Auth.currentCredentials().then(credentials => {
            AWS.config.update({
                region: 'eu-west-1',
                credentials: Auth.essentialCredentials(credentials)
            });
            const params = {
                Destination: {
                    CcAddresses: [],
                    ToAddresses: [toAddress]
                },
                Message: {
                    Body: {
                        Html: {
                            Charset: "UTF-8",
                            Data: `
                            <p>Ciao,<br/>
                            Ti ricordiamo che il periodo di prova come Leader finirà tra una settimana. <br/>
                            Per maggiori informazioni su continuare come Leader contatta info@teamx.tech</p>

                            <p>Buon lavoro,<br/>
                            Il team TEAMX</p>
                            ` + mailFooter
                        },
                        Text: {
                            Charset: "UTF-8",
                            Data: "Benvenuto a TEAMX"
                        }
                },
                Subject: {
                        Charset: 'UTF-8',
                        Data: 'Vuoi scoprire che team player sei?'
                }
                },
                Source: 'app@teamx.solutions',
                ReplyToAddresses: ['app@teamx.solutions'],
                };
            
                // Create the promise and SES service object
                let sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
            
                // Handle promise's fulfilled/rejected states
                sendPromise.then(
                function(data) {
                    console.log(data.MessageId);
                }).catch(
                    function(err) {
                    console.error(err, err.stack);
                });
        })
    },

    sendMailReportShared(team, reportLink) {
        Auth.currentCredentials().then(credentials => {
            AWS.config.update({
                region: 'eu-west-1',
                credentials: Auth.essentialCredentials(credentials)
            });
            ApiUtils.getUsersInTeam(team.id).then((users) => {
                for (const user of users) {
                    if (user.notificationsActive) {
                        const params = {
                            Destination: {
                                CcAddresses: [],
                                ToAddresses: [user.username]
                            },
                            Message: {
                                Body: {
                                    Html: {
                                        Charset: "UTF-8",
                                        Data: "<p>Un nuovo report è disponibile per il team " + team.teamName + "</p>"
                                        + "<p><a href='https://app.teamx.solutions" + reportLink + "'>Clicca qui</a> per visualizzarlo.</p>"
                                        + mailFooter
                                    },
                                    Text: {
                                        Charset: "UTF-8",
                                        Data: "Un nuovo report è disponibile per il team" + team.teamName + "." 
                                        + "Visita https://app.teamx.solutions per visualizzarlo."
                                    }
                            },
                            Subject: {
                                    Charset: 'UTF-8',
                                    Data: 'TEAMX Nuovo report per il team ' + team.teamName
                            }
                            },
                            Source: 'app@teamx.solutions',
                            ReplyToAddresses: ['app@teamx.solutions'],
                        };
                        // Create the promise and SES service object
                        let sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
                        // Handle promise's fulfilled/rejected states
                        sendPromise.then(
                        function(data) {
                            console.log(data.MessageId);
                        }).catch(
                            function(err) {
                            console.error(err, err.stack);
                        });
                    }
                }
            })
        })
    },


    sendMailSurveyAdded(team) {
        Auth.currentCredentials().then(credentials => {
            AWS.config.update({
                region: 'eu-west-1',
                credentials: Auth.essentialCredentials(credentials)
            });
            ApiUtils.getUsersInTeam(team.id).then((users) => {
                for (const user of users) {
                    if (user.notificationsActive) {
                        const params = {
                            Destination: {
                                CcAddresses: [],
                                ToAddresses: [user.username]
                            },
                            Message: {
                                Body: {
                                    Html: {
                                        Charset: "UTF-8",
                                        Data: "<p>Una nuova survey da compilare è disponibile per il team " + team.teamName + "</p>"
                                        + "<p><a href='https://app.teamx.solutions'>Clicca qui</a> per compilarla.</p>"
                                        + mailFooter
                                    },
                                    Text: {
                                        Charset: "UTF-8",
                                        Data: "Una nuova survey da compilare è disponibile per il team " + team.teamName + "." 
                                        + "Visita https://app.teamx.solutions per visualizzarla."
                                    }
                            },
                            Subject: {
                                    Charset: 'UTF-8',
                                    Data: 'TEAMX Nuova survey per il team ' + team.teamName
                            }
                            },
                            Source: 'app@teamx.solutions',
                            ReplyToAddresses: ['app@teamx.solutions'],
                        };
                        // Create the promise and SES service object
                        let sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
                        // Handle promise's fulfilled/rejected states
                        sendPromise.then(
                        function(data) {
                            console.log(data.MessageId);
                        }).catch(
                            function(err) {
                            console.error(err, err.stack);
                        });
                    }
                }
            })
        })
    },


}
export default MailHelper;