import React from 'react';
import { withRouter } from "react-router";

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import SecondaryHeader from '../customComponents/SecondaryHeader'
import SecondaryHeaderNoPaddingTop from '../customComponents/SecondaryHeaderNoPaddingTop'
import Paragraph from '../customComponents/Paragraph'
import ApiUtils from '../api/ApiUtils'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamDisplay: "",
      loadingScreen: <CircularProgress/>,
      teamDashboards: null,
      modalOpen: false
    }
  }

  toggleModal(state, team) {
    if (state) {
      let reportsDisplay = []
      if (team.profile !== null && team.profile !== undefined) {
        reportsDisplay.push(
          <Box style={{margin: "20px"}}>
            <Link key={"linkreportform"}  href={"/report/form/" + team.id}>
              FORM
            </Link>
            <br/>
          </Box>)
      }
      if (team.reports.indexOf("WAY") > -1) {
        reportsDisplay.push(
          <Box style={{margin: "20px"}}>
            <Link key={"linkreportway"}  href={"/report/way/" + team.id}>
              WAY
            </Link>
            <br/>
          </Box>)
      }
      if (team.sentimentScore !== null && team.sentimentScore !== undefined) {
        reportsDisplay.push(
          <Box style={{margin: "20px"}}>
            <Link key={"linkreportstorm"}  href={"/report/storm/" + team.id}>
              STORM
            </Link>
            <br/>
          </Box>)
      }
      if (team.normAverage !== null && team.normAverage !== undefined) {
        reportsDisplay.push(
          <Box style={{margin: "20px"}}>
            <Link key={"linkreportnorm"}  href={"/report/norm/" + team.id}>
              NORM
            </Link>
            <br/>
          </Box>)
      } 
      if (team.performance !== null && team.performance !== undefined) {
        reportsDisplay.push(
          <Box style={{margin: "20px"}}>
            <Link key={"linkreportperform"}  href={"/report/perform/" + team.id}>
              PERFORM
            </Link>
            <br/>
          </Box>)
      }
      this.setState({ modalOpen: state, reportsDisplay: reportsDisplay, teamSelected: team.teamName })
    } else {
      this.setState({ modalOpen: state, reportsDisplay: null, teamSelected: null })
    }
  }

  componentDidMount() {
    ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      ApiUtils.getTeamsForUser(this.props.user.attributes.email.toLowerCase()).then((teams) => {
        if (teams !== null && teams !== undefined) {
          ApiUtils.getSurveysForUser(this.props.user.attributes.email.toLowerCase()).then((surveys) => {
            this.setState({ user: user, teams: teams, surveys: surveys }, () => this.displayStuff()
            )
          })
        }
      })
    })
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.language !== nextProps.language && this.state.user !== undefined) {
      this.displayStuff()
    }
  }

  displayStuff() {
    let pendingActions = []
    if (this.state.user.myProfile === null || this.state.user.myProfile === undefined || this.state.user.myProfile === "") {
      pendingActions.push(
        <Grid item key="grididmyprofile">
          <Box component="span" m={1}>
            <Link href={"/survey/form"}>
              {this.props.language === 'IT' ? 
                "Rispondi alla FORM survey per scoprire il profilo del tuo team." : 
                "Answer to the FORM survey to discover your team's profile"}
            </Link>
          </Box>
        </Grid>
      )
    }
    let teamDashboardsLinks = []
    for (const team of this.state.teams) {
      let reportsButton
      let dashboardLink
      let reportsLength = 0
      if (team.performance !== null && team.performance !== undefined) {
        reportsLength += 1
      }
      if (team.normAverage !== null && team.normAverage !== undefined) {
        reportsLength += 1
      }
      if (team.sentimentScore !== null && team.sentimentScore !== undefined) {
        reportsLength += 1
      }
      if (team.reports.indexOf("WAY") > -1) {
        reportsLength += 1
      }
      if (team.profile !== null && team.profile !== undefined) {
        reportsLength += 1
      }
      if (reportsLength > 0) {
        reportsButton = 
          <Button variant="contained" color="primary" style={{margin: "20px"}}
            onClick={() => this.toggleModal(true, team)}>
            {this.props.language === 'IT' ? "Vedi " + reportsLength + " report" :
            "See " + reportsLength + " reports"}
          </Button>
        dashboardLink = 
          <Box component="span" m={1}>
            <Link href={"/teamDashboard/" + team.id}>{this.props.language === 'IT' ? 
            "Vai alla Dashboard" : "Go to the dashboard"}</Link>
          </Box>
      } else {
        reportsButton = 
          <Button variant="contained" color="primary" disabled style={{margin: "20px"}}>
            {this.props.language === 'IT' ? "Nessun report" : "No reports"}
          </Button>
          dashboardLink = null
      }
      let teamTitle
      if (this.state.user.isAdmin 
        || this.state.user.isCoach 
        || (this.state.user.isLeader && team.leader === this.props.user.attributes.email.toLowerCase())) {
          teamTitle = <Link href={"/team/" + team.id}>
              {team.teamName}
            </Link>
      } else {
        teamTitle = team.teamName
      }
      teamDashboardsLinks.push(
        <Paper key={"teampaperid" + team.id} elevation={3} 
          style={{width: "90%", margin: "5px auto", paddingBottom: "10px"}}>
          <Box component="span" m={1}>
            <SecondaryHeaderNoPaddingTop text={teamTitle}/>
          </Box>
          <Box component="span" m={1}>
            <Paragraph text={this.props.language === 'IT' ? "Progetto creato da: " + team.leader : "Project created by: " + team.leader}/>
          </Box>
          <Box component="span" m={1}>
            {reportsButton}
          </Box>
          <br/>
          {dashboardLink}
        </Paper>)
      for (const surveyType of ["WAY", "STORM", "NORM", "PERFORM"]) {
        let surveyFilled = false
        if (team.surveys.includes(surveyType)) {
          // survey is active in the team, need to check if user already completed it
          for (const survey of this.state.surveys) {
            if (survey.type === surveyType && survey.teamId === team.id) {
              // user already completed it
              surveyFilled = true
            }
          }
          if (!surveyFilled) {
            pendingActions.push(
              <Paper key={"teampaperid" + team.id} elevation={3} 
                style={{width: "90%", margin: "5px auto", paddingBottom: "10px"}}>
                <Box component="span" m={1}>
                  <SecondaryHeaderNoPaddingTop text={team.teamName}/>
                </Box>
                <Box component="span" m={1}>
                  <Link href={"/survey/" + surveyType.toLowerCase() + "/" + team.id}>
                    {this.props.language === 'IT' ? "Rispondi alla " + surveyType + " survey" :
                    "Answer to the " + surveyType + " survey"}
                  </Link>
                </Box>
              </Paper>
            )
          }
        }
      }
    }
    let teamDashboards = null 
    if (teamDashboardsLinks.length > 0) {
      teamDashboards = <div>
        <SecondaryHeader text={this.props.language === 'IT' ? "Team di cui fai parte:" : "Teams you're part of: "}/>
        <Grid container 
            direction="column"
            justify="center"
            alignItems="center"
            spacing={1}
          >
          {teamDashboardsLinks}
        </Grid>
      </div>
    } else {
      teamDashboards = <SecondaryHeader text={this.props.language === 'IT' ? "Non fai parte di alcun team." : "You're not part of any team."}/>
    }
    let teamDisplay
    if (pendingActions.length > 0) {
      teamDisplay = <div>
          <SecondaryHeader text={this.props.language === 'IT' ? "Cose da fare:" : "Things to do:"}/>
          <Grid container 
              direction="column"
              justify="center"
              alignItems="center"
              spacing={1}
            >
            {pendingActions}
          </Grid>
        </div>
    } else {
      teamDisplay = <SecondaryHeader text={this.props.language === 'IT' ? "Niente da fare al momento!" : "Nothing to do at the moment!"}/>
    }
    this.setState({ teamDisplay: teamDisplay, loadingScreen: null, teamDashboards: teamDashboards })
  }

  render() {
    return (
      <div>
        {this.state.loadingScreen}
        {this.state.teamDisplay}
        {this.state.teamDashboards}
        <Dialog
          open={this.state.modalOpen}
          onClose={() => this.toggleModal(false, null)}
        >
          <DialogTitle id="alert-dialog-title">{this.state.teamSelected}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.reportsDisplay}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => this.toggleModal(false, null)} color="secondary">
              {this.props.language === 'IT' ? "Indietro" : "Back"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(Home)