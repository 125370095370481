import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';

class StormReport extends React.Component {

  render() {
    const data = JSON.parse(this.props.data.data)
    let teamAnswers = []
    let rage = 0
    let discontempt = 0
    let euphoria = 0
    let contempt = 0
    for (const answer of Object.keys(data)) {
      if (answer !== "participants") {
        const delta = data[answer]["positive"] - data[answer]["negative"]
        if (delta >= 0.5) {
          euphoria += 1
        } else if (delta >= 0) {
          contempt += 1
        } else if (delta >= -0.3) {
          discontempt += 1
        } else {
          rage += 1
        }
      }
      teamAnswers.push(
      <Typography key={answer} variant="body2" style={{padding: "10px", textAlign:"left"}}>
        {data[answer]['answer']}
      </Typography>)
    }
    const total = rage + discontempt + euphoria + contempt
    let ragePerc = Math.round(rage / total * 100)
    let discontemptPerc = Math.round(discontempt / total * 100)
    let euphoriaPerc = Math.round(euphoria / total * 100)
    let contemptPerc = Math.round(contempt / total * 100)
    let percList = [ragePerc, discontemptPerc, euphoriaPerc, contemptPerc]
    const roundError = 100 - (ragePerc + discontemptPerc + euphoriaPerc + contemptPerc)
    const index = percList.indexOf(Math.max(...percList))
    percList[index] += roundError
    const total_score = Math.round(euphoria / total * 100) - Math.round(rage / total * 100) + (Math.round(contempt / total * 100) - Math.round(discontempt / total * 100))/2
    let leftWidth
    let middleWidth
    let rightWidth
    let leftColor
    let rightColor
    if (total_score < 0) {
      leftWidth = (50-Math.abs(total_score)/2).toString() + "%"
      middleWidth = (Math.abs(total_score)/2).toString() + "%"
      rightWidth = "50%"
      leftColor = "#7d7d7d"
      rightColor = "white"
    } else {
      leftWidth = "50%"
      middleWidth = "0"
      rightWidth = (Math.abs(total_score)/2).toString() + "%"
      leftColor = "white"
      rightColor = "#7d7d7d"
    }
    let stormAnswers = null
    if (this.props.isLeader) {
      stormAnswers = 
        <Grid item key="stormAnswers" style={{width:"90%"}}>
          <Paper elevation={3}>
            <Typography variant="h6">
              {this.props.language === 'IT' ? "Risposte del team" : "Team's answers" }
            </Typography>
            {teamAnswers}
          </Paper>
        </Grid>
    }
    return (
      <div>
        <Typography variant="body1">
          {this.props.language === 'IT' ? "Partecipanti: " + data.participants : "Participants: " + data.participants}
        </Typography>
        <Grid direction="column" container justify="center" alignItems="center" spacing={5} style={{padding: "10px"}}>
          <Grid item key="question" style={{width:"90%"}}>
            <Paper elevation={3} >
              <Typography variant="h6">
                {this.props.language === 'IT' ? "Domanda:" : "Question:" }
              </Typography>
              <Typography variant="body1" style={{padding: "20px", textAlign:"left"}}>
              {this.props.stormQuestion === undefined || this.props.stormQuestion === null ?
                this.props.language === 'IT' ?
                "Un team funziona bene quando è chiaro chi-fa-cosa. " +
                "Come percepisci il tuo ruolo all’interno del team? " +
                "Sai cosa fare e cosa gli altri si aspettano da te? " +
                "Conosci il tuo ruolo e le responsabilità delle altre persone del team?" 
                :
                "A good team works when it’s clear who does what. What is your perception about your role inside the team? Do you know what to do and what others expect from you? Do you know your role and the responsibilities of the other team members? "
               : this.props.stormQuestion}
              </Typography>
            </Paper>
          </Grid>
          <Grid item key="sentimentscore" style={{width:"90%"}}>
            <Paper elevation={3} >
              <Typography variant="h6">
                Sentiment Score
              </Typography>
              <Typography variant="h6">
                {Math.round(total_score)}
              </Typography>
              <div style={{
                backgroundImage: "url(https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/storm_total.png)",
                backgroundPosition: "center",
                backgroundSize: "250px",
                backgroundRepeat: "no-repeat",
                height: "110px"
              }}>
                <div style={{paddingTop: "30px", marginRight: "auto", marginLeft: "auto", paddingBottom: "20px", width: "200px", height: "30px"}}>
                  <div style={{backgroundColor: "white", width: leftWidth, height: "40px", float: "left"}}></div>
                  <div style={{backgroundColor: leftColor, width: middleWidth, height: "40px", float: "left", borderRadius: "20px 0px 0px 20px"}}></div>
                  <div style={{backgroundColor: rightColor, width: rightWidth, height: "40px", float: "left", borderRadius: "0px 20px 20px 0px"}}></div>
                </div>
              </div>
              <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
                {this.props.language === 'IT' ? 
                "Il grafico riporta il sentiment score, il cui valore è il risultato della media dei punteggi di sentiment delle singole risposte alla domanda aperta della survey. Lo score può variare da -100 a +100." :
                "The graph shows the sentiment score: the number is the average  of the sentiment scores of all the answers. The score goes from -100 to +100."}
              </Typography>
            </Paper>
          </Grid>
          <Grid item key="emotionalmatrix" style={{width:"90%"}}>
            <Paper elevation={3}>
              <Typography variant="h6">
                Emotional Matrix
              </Typography>
              <div style={{
                backgroundImage: this.props.language === 'IT' ? "url(https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/storm_chart.png)" : "url(https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/storm_chart_en.png)",
                backgroundPosition: "center",
                backgroundSize: "250px",
                backgroundRepeat: "no-repeat",
                height: "300px"
              }}>
              <div style={{paddingTop: "75px"}}>
                <span style={{fontSize: "28px", color: "red", marginLeft: "35px", marginRight: "45px"}}>{percList[0]}%</span>
                <span style={{fontSize: "28px", color: "#ffd000", marginLeft: "30px"}}>{percList[2]}%</span>
                </div>
                <div style={{paddingTop: "75px"}}>
                  <span style={{fontSize: "28px", color: "grey", marginLeft: "35px", marginRight: "45px"}}>{percList[1]}%</span>
                  <span style={{fontSize: "28px", color: "#42aad7", marginLeft: "30px"}}>{percList[3]}%</span>
                </div>
              </div>
              <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
                {this.props.language === 'IT' ? 
                 "La matrice delle emozioni associa i sentiment score alle emozioni. In ogni quadrante c’è la percentuale di risposte che rientrano nella categoria." :
                 "The emotional matrix put together the sentiment score and emotions. On the axis there are the pleasantness and the intensity of the emotions, while each quadrant shows the percentage of the answer for that emotion."
                }
              </Typography>
            </Paper>
          </Grid>
          {stormAnswers}
        </Grid>
      </div>
    );
  }
}
export default StormReport