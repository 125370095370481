import React from 'react';
import Radio from '@material-ui/core/Radio';

export default function RadioGroup(props) {
  return (
    <div>
      1
      <Radio
        checked={props.value === '1'}
        onChange={e => props.onChange(e.target.value)}
        value="1"
        color="primary"
        name="radio-button-demo"
      />
      <Radio
        checked={props.value === '2'}
        onChange={e => props.onChange(e.target.value)}
        value="2"
        color="primary"
        name="radio-button-demo"
      />
      <Radio
        checked={props.value === '3'}
        onChange={e => props.onChange(e.target.value)}
        value="3"
        color="primary"
        name="radio-button-demo"
      />
      <Radio
        checked={props.value === '4'}
        onChange={e => props.onChange(e.target.value)}
        value="4"
        color="primary"
        name="radio-button-demo"
      />
      <Radio
        checked={props.value === '5'}
        onChange={e => props.onChange(e.target.value)}
        value="5"
        color="primary"
        name="radio-button-demo"
      />
      5
    </div>
  );
}