import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelper from '../../api/FormHelper';
import Paragraph from '../../customComponents/Paragraph'
import SecondaryHeader from '../../customComponents/SecondaryHeader'

class FormReport extends React.Component {

  render() {
    const data = JSON.parse(this.props.data.data)
    let brainImg
    let firstColor
    let secondColor
    let thirdColor
    const teamText = FormHelper.getFormDataForTeamType(data.team_profile, this.props.language)
    switch(data.team_profile) {
      case "REALISTA":
        brainImg = "rrp"
        firstColor = "#42aad7"
        secondColor = "#42aad7"
        thirdColor = "#42aad7"
        break;
      case "PIANIFICATORE":
        brainImg = "rri"
        firstColor = "#42aad7"
        secondColor = "#42aad7"
        thirdColor = "#grey"
        break;
      case "OPERATIVO":
        brainImg = "rcp"
        firstColor = "#42aad7"
        secondColor = "grey"
        thirdColor = "#42aad7"
        break;
      case "INGEGNOSO":
        brainImg = "rci"
        firstColor = "#42aad7"
        secondColor = "grey"
        thirdColor = "grey"
        break;
      case "PROTETTIVO":
        brainImg = "erp"
        firstColor = "grey"
        secondColor = "#42aad7"
        thirdColor = "#42aad7"
        break;
      case "GIUDIZIOSO":
        brainImg = "eri"
        firstColor = "grey"
        secondColor = "#42aad7"
        thirdColor = "grey"
        break;
      case "RISOLUTORE":
        brainImg = "ecp"
        firstColor = "grey"
        secondColor = "grey"
        thirdColor = "#42aad7"
        break;
      case "CURIOSO":
        brainImg = "eci"
        firstColor = "grey"
        secondColor = "grey"
        thirdColor = "grey"
        break;
      default:
        break;
    }
    if (this.props.language !== 'IT') {
      brainImg += '_en'
    }
    return (
      <div>
        <Grid direction="column" container justify="center" alignItems="center" spacing={5}>
          <Grid item>
              <Typography variant="body1">
                {this.props.language === 'IT' ? "Partecipanti: " : "Participants: " + data.participants}
              </Typography>
              <Typography variant="h6">
                Team Profile:
              </Typography>
              <Typography variant="h5">
                {this.props.language === 'IT' ? data.team_profile : FormHelper.getEnglishTeamProfileFromItalian(data.team_profile)}
              </Typography>
              <div style={{
                backgroundImage: "url(https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/" + brainImg + ".png)",
                backgroundPosition: "center",
                backgroundSize: "230px",
                backgroundRepeat: "no-repeat",
                height: "300px"
              }}>
              <div style={{paddingTop: "74px"}}>
                <span style={{fontSize: "18px", color: firstColor, marginRight: "245px"}}>{data.team_dist[0]}%</span>
                <span style={{fontSize: "18px", color: firstColor === "#42aad7" ? "grey" : "#42aad7"}}>{100 - data.team_dist[0]}%</span>
              </div>
              <div style={{paddingTop: "44px"}}>
                <span style={{fontSize: "18px", color: secondColor, marginRight: "245px"}}>{data.team_dist[1]}%</span>
                <span style={{fontSize: "18px", color: secondColor === "#42aad7" ? "grey" : "#42aad7"}}>{100 - data.team_dist[1]}%</span>
              </div>
              <div style={{paddingTop: "44px"}}>
                <span style={{fontSize: "18px", color: thirdColor, marginRight: "245px"}}>{data.team_dist[2]}%</span>
                <span style={{fontSize: "18px", color: thirdColor === "#42aad7" ? "grey" : "#42aad7"}}>{100 - data.team_dist[2]}%</span>
              </div>
            </div>
          </Grid>
          <Grid item>
              <Paragraph text={this.props.language === 'IT' ? "L'immagine rappresenta un aggregato delle preferenze cerebrali dei singoli membri del team." 
              + "I numeri a fianco di ogni dimensione rappresentano le percentuali di persone che rientrano in un dato stile." :
              "The graph shows an elaboration of the single members’ favourite brain profiles. The numbers represent the percentage of people that fall into that profile."}
              />
          </Grid>
          <Grid item>
              <SecondaryHeader text={this.props.language === 'IT' ? "Preferenze" : 'Preferences'}/>
              <Paragraph text={teamText[0]}/>
          </Grid>
          <Grid item>
              <SecondaryHeader text={this.props.language === 'IT' ? "Opportunità" : "Opportunities"}/>
              <Paragraph text={teamText[1]}/>
          </Grid>
          <Grid item>
              <SecondaryHeader text={this.props.language === 'IT' ? "Come lavorare assieme": "How to work together"}/>
              <Paragraph text={teamText[2]}/>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default FormReport