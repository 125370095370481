/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModeluserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      username
      isLeader
      isAdmin
      isCoach
      myProfile
      teamsOwned
      teamsPartOf
      surveys
      reports
      articlesReviewed
      dateTrialPeriodEnd
      notificationsActive
      formMailsReceivedCount
      stormMailsReceived
      normMailsReceived
      performMailsReceived
      owner
      individualMailsReceivedCount
      preferredLanguage
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModeluserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      username
      isLeader
      isAdmin
      isCoach
      myProfile
      teamsOwned
      teamsPartOf
      surveys
      reports
      articlesReviewed
      dateTrialPeriodEnd
      notificationsActive
      formMailsReceivedCount
      stormMailsReceived
      normMailsReceived
      performMailsReceived
      owner
      individualMailsReceivedCount
      preferredLanguage
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelteamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      teamName
      leader
      users
      reports
      surveys
      reportsGenerated
      articlesShared
      articlesBlacklisted
      profile
      suggestions
      sentimentScore
      emotion
      talent
      normAverage
      lowDynamic
      targetDynamic
      performance
      mood
      targetOutcome
      dateLastReportGenerated
      owner
      bestActions
      stormQuestion
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelteamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      teamName
      leader
      users
      reports
      surveys
      reportsGenerated
      articlesShared
      articlesBlacklisted
      profile
      suggestions
      sentimentScore
      emotion
      talent
      normAverage
      lowDynamic
      targetDynamic
      performance
      mood
      targetOutcome
      dateLastReportGenerated
      owner
      bestActions
      stormQuestion
      createdAt
      updatedAt
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelsurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      teamId
      user
      type
      answers
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelsurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      teamId
      user
      type
      answers
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelsurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      teamId
      user
      type
      answers
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelreportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      teamId
      isShared
      type
      data
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelreportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      teamId
      isShared
      type
      data
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelreportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      teamId
      isShared
      type
      data
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelarticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      title
      content
      category
      subCategory
      createdAt
      updatedAt
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelarticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      title
      content
      category
      subCategory
      createdAt
      updatedAt
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelarticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      title
      content
      category
      subCategory
      createdAt
      updatedAt
    }
  }
`;
export const createUserFeedback = /* GraphQL */ `
  mutation CreateUserFeedback(
    $input: CreateUserFeedbackInput!
    $condition: ModeluserFeedbackConditionInput
  ) {
    createUserFeedback(input: $input, condition: $condition) {
      id
      surveyType
      processNumber
      user
      answer
      teamId
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const updateUserFeedback = /* GraphQL */ `
  mutation UpdateUserFeedback(
    $input: UpdateUserFeedbackInput!
    $condition: ModeluserFeedbackConditionInput
  ) {
    updateUserFeedback(input: $input, condition: $condition) {
      id
      surveyType
      processNumber
      user
      answer
      teamId
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserFeedback = /* GraphQL */ `
  mutation DeleteUserFeedback(
    $input: DeleteUserFeedbackInput!
    $condition: ModeluserFeedbackConditionInput
  ) {
    deleteUserFeedback(input: $input, condition: $condition) {
      id
      surveyType
      processNumber
      user
      answer
      teamId
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModeluserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      username
      isLeader
      isAdmin
      isCoach
      myProfile
      teamsOwned
      teamsPartOf
      surveys
      reports
      articlesReviewed
      dateTrialPeriodEnd
      notificationsActive
      formMailsReceivedCount
      stormMailsReceived
      normMailsReceived
      performMailsReceived
      owner
      individualMailsReceivedCount
      preferredLanguage
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelteamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      teamName
      leader
      users
      reports
      surveys
      reportsGenerated
      articlesShared
      articlesBlacklisted
      profile
      suggestions
      sentimentScore
      emotion
      talent
      normAverage
      lowDynamic
      targetDynamic
      performance
      mood
      targetOutcome
      dateLastReportGenerated
      owner
      bestActions
      stormQuestion
      createdAt
      updatedAt
    }
  }
`;
