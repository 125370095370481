import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';

function FullCircle() {
  return <svg width="40" height="40">
    <circle cx="20" cy="20" r="15" stroke="#42aad7" strokeWidth="2" fill="#42aad7" />
  </svg>
}

function EmptyCircle() {
  return <svg width="40" height="40">
    <circle cx="20" cy="20" r="15" stroke="gainsboro" strokeWidth="2" fill="#e8e8e8" />
  </svg>
}

class NormReport extends React.Component {

  getTrustStatement(isPositive) {
    if (isPositive) {
      return <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          I membri di un team con alta Trust potrebbero avere la tendenza a:<br/>
          - Accogliere positivamente le novità e accettare di portarle avanti più velocemente.<br/>
          - Tollerare maggiormente gli errori e assumersi più rischi.<br/>
          - Condividere le informazioni importanti con il team e scambiarsi feedback.
        </Typography>
    } else {
      return <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          I membri di un team con bassa Trust potrebbe avere la tendenza a:<br/>
          - Dimostrare poco interesse nei confronti di nuovi progetti o nuove strategie.<br/>
          - Contare sulle proprie risorse e competenze, senza delegare agli altri.<br/>
          - Ascoltare gli altri per un tempo ristretto e in maniera superficiale.
        </Typography>
    }
  }

  getSynergyStatement(isPositive) {
    if (isPositive) {
      return <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          I membri di un team con alta Synergy potrebbero avere la tendenza a:<br/>
          - Partecipare e condividere attivamente le informazioni per la riuscita del progetto.<br/>
          - Riconoscere il merito di tutti e l’importanza di ciascuno.<br/>
          - Assumersi naturalmente dei ruoli, ciascuno in base alle proprie caratteristiche.
        </Typography>
    } else {
      return <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          I membri di un team con bassa Synergy potrebbero avere la tendenza a:<br/>
          - Dare la priorità e perseguire maggiormente gli obiettivi individuali.<br/>
          - Ricercare la propria soddisfazione e il merito personale per ciò che è stato fatto.<br/>
          - Evitare di esporsi, essere preoccupati di sbagliare e non prendersi responsabilità.
        </Typography>
    }
  }

  getEnergyStatement(isPositive) {
    if (isPositive) {
      return <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          I membri di un team con alta Energy potrebbero avere la tendenza a:<br/>
          - Allinearsi su cosa fare e come fare per raggiungere gli obiettivi del team.<br/>
          - Concentrarsi sul problema e ricercare insieme la migliore soluzione possibile.<br/>
          - Mettersi a disposizione degli altri, dedicare tempo e risorse per aiutare i propri colleghi.
        </Typography>
    } else {
      return <Typography variant="body2" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          I membri di un team con bassa Energy potrebbero avere la tendenza a:<br/>
          - Evitare di prendersi volontariamente la responsabilità dei task da portare a termine.<br/>
          - Lasciare che siano altri a preoccuparsi di potenziali problemi.<br/>
          - Essere poco proattivi e ad orientarsi solo al breve periodo, ai problemi e ai bisogni attuali.
        </Typography>
    }
  }

  render() {
    let data = JSON.parse(this.props.data.data)
    let averageBalls = []
    if (data.avg > 1.5) {
      averageBalls.push(<FullCircle key="first"/>)
    } else {
      averageBalls.push(<EmptyCircle key="first"/>)
    }
    if (data.avg > 2) {
      averageBalls.push(<FullCircle key="second"/>)
    } else {
      averageBalls.push(<EmptyCircle key="second"/>)
    }
    if (data.avg > 3) {
      averageBalls.push(<FullCircle key="third"/>)
    } else {
      averageBalls.push(<EmptyCircle key="third"/>)
    }
    if (data.avg > 4) {
      averageBalls.push(<FullCircle key="fourth"/>)
    } else {
      averageBalls.push(<EmptyCircle key="fourth"/>)
    }
    let stdBalls = []
    if (data.std < 2) {
      stdBalls.push(<FullCircle key="first"/>)
    } else {
      stdBalls.push(<EmptyCircle key="first"/>)
    }
    if (data.std < 1.5) {
      stdBalls.push(<FullCircle key="second"/>)
    } else {
      stdBalls.push(<EmptyCircle key="second"/>)
    }
    if (data.std < 1) {
      stdBalls.push(<FullCircle key="third"/>)
    } else {
      stdBalls.push(<EmptyCircle key="third"/>)
    }
    if (data.std < 0.5) {
      stdBalls.push(<FullCircle key="fourth"/>)
    } else {
      stdBalls.push(<EmptyCircle key="fourth"/>)
    }
    let trustImg
    let trustStatement
    if (data.avg_trust > 2.5) {
      trustStatement = this.getTrustStatement(true)
      trustImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normPlus.png"
    } else {
      trustStatement = this.getTrustStatement(false)
      trustImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normMinus.png"
    }
    let synergyImg
    let synergyStatement
    if (data.avg_synergy > 2.5) {
      synergyStatement = this.getSynergyStatement(true)
      synergyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normPlus.png"
    } else {
      synergyStatement = this.getSynergyStatement(false)
      synergyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normMinus.png"
    }
    let energyImg
    let energyStatement
    if (data.avg_energy > 2.5) {
      energyStatement = this.getEnergyStatement(true)
      energyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normPlus.png"
    } else {
      energyStatement = this.getEnergyStatement(false)
      energyImg = "https://s3-eu-west-1.amazonaws.com/reports.teamx.solutions/reportsFiles/normMinus.png"
    }
    const trust = 
      <Grid item key="trust" style={{width:"90%"}}>
        <Paper elevation={3}>
          <img width="90%" alt="trust" src={trustImg}/>
          <Typography variant="h6" style={{margin:"20px"}}>
            Trust
          </Typography>
          <Typography variant="body1" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "La sensazione di un ambiente sicuro e affidabile." : "The sensation of a safe and reliable environment."}<br/>
          </Typography>
          {trustStatement}
        </Paper>
      </Grid>
    const synergy = 
      <Grid item key="synergy" style={{width:"90%"}}>
        <Paper elevation={3}>
          <img width="90%" alt="synergy" src={synergyImg}/>
          <Typography variant="h6" style={{margin:"20px"}}>
            Synergy
          </Typography>
          <Typography variant="body1" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "La capacità di lavorare insieme in maniera efficace." : "The ability to work together effectively."}<br/>
          </Typography>
          {synergyStatement}
        </Paper>
      </Grid>
    const energy = 
      <Grid item key="energy" style={{width:"90%"}}>
        <Paper elevation={3}>
          <img width="90%" alt="energy" src={energyImg}/>
          <Typography variant="h6" style={{margin:"20px"}}>
            Energy
          </Typography>
          <Typography variant="body1" style={{padding: "20px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "L’impegno dei vari membri per raggiungere risultati eccellenti come team." : "The effort of the various members to achieve eccellent results as a team."}<br/>
          </Typography>
          {energyStatement}
        </Paper>
    </Grid>
    let dynamicsDisplay = []
    if (data.avg_energy >= data.avg_synergy) {
      if (data.avg_energy >= data.avg_trust) {
        if (data.avg_synergy >= data.avg_trust) {
          dynamicsDisplay.push(energy, synergy, trust)
        } else {
          dynamicsDisplay.push(energy, trust, synergy)
        }
      } else {
        dynamicsDisplay.push(trust, energy, synergy)
      }
    } else {
      if (data.avg_energy >= data.avg_trust) {
        dynamicsDisplay.push(synergy, energy, trust)
      } else {
        if (data.avg_synergy >= data.avg_trust) {
          dynamicsDisplay.push(synergy, trust, energy)
        } else {
          dynamicsDisplay.push(trust, synergy, energy)
        }
      }
    }
    return (
      <div>
        <Typography variant="body1">
        {this.props.language === 'IT' ? "Partecipanti: " + data.participants : "Participants: " + data.participants}
        </Typography>
        <Typography variant="h6" style={{marginRight:"20px"}}>
          TEAM DYNAMICS
        </Typography>
        <Grid container justify="center" alignItems="center" spacing={1} style={{padding: "10px"}}>
          {this.props.language === 'IT' ?
          <Typography variant="body1" style={{marginRight:"20px"}}>
            Media<br/>Complessiva
          </Typography> : 
          <Typography variant="body1" style={{marginRight:"20px"}}>
            Overall<br/>Average
          </Typography>
          }
          {averageBalls}
        </Grid>
        <Grid container justify="center" alignItems="center" spacing={1} style={{padding: "10px"}}>
          {this.props.language === 'IT' ?
            <Typography variant="body1" style={{marginRight:"23px"}}>
              Omogeneità<br/>percezioni
            </Typography>:
            <Typography variant="body1" style={{marginRight:"23px"}}>
              Homogeneity<br/>of perceptions
            </Typography>
          }
          {stdBalls}
        </Grid>
        <Grid direction="column" container justify="center" alignItems="center" spacing={5} style={{padding: "10px"}}>
          <Grid item key="disclaimertop" style={{width:"90%"}}>
            {this.props.language === 'IT' ?
              <Typography variant="body2" style={{padding: "10px", textAlign:"left"}}>
                I due grafici riportano il valore medio complessivo delle tre dinamiche di gruppo (Energy, Synergy, Trust) 
                e l’omogeneità delle risposte alla survey, rappresentativa della deviazione standard. 
                Sotto, per ciascuna dinamica si trovano le seguenti informazioni: 
                il segno +/- a seconda che il valore sia superiore/inferiore alla media del campione normativo; 
                la definizione della dinamica e tre rischi o tre opportunità associati.
              </Typography> : 
              <Typography variant="body2" style={{padding: "10px", textAlign:"left"}}>
                On the top, the overall average of the three dynamics (Energy, Synergy, Trust) 
                and the homogeneity of the responses to the survey, that is representative of the standard deviation.
                Down below the dynamics in decreasing order of value: the first is the one with the highest perceived value. F
                or each dynamic, the report shows the following information: 
                the +/- sign depending on whether the value is higher / lower than the average of the normative value; 
                the definition of the dynamic and three risks or three opportunities associated to the perceived value.
              </Typography>
            }
          </Grid>
          {dynamicsDisplay}
        </Grid>
      </div>
    );
  }
}
export default NormReport