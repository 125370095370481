import React from 'react';
import { withRouter } from "react-router";

import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Article from './Article'

import ApiUtils from '../../api/ApiUtils';

class UserArticles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      articlesDisplay: <CircularProgress/>,
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.language !== nextProps.language && this.state.user !== undefined) {
      this.displayStuff()
    }
  }

  componentDidMount() {
    ApiUtils.getArticlesForUser(this.props.user.attributes.email.toLowerCase()).then((articles) =>  {
      articles.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
      this.setState({articles: articles}, () => this.displayStuff())
    })
  }

  displayStuff() {
    let articlesDisplay = []
    let articleTitles = [
      <Typography key={"title"} variant="h6" style={{padding: "10px", textAlign: "left"}}>
        {this.props.language === 'IT' ? "Contenuti:" : "Contents:"}
      </Typography>
    ]
    for (const article of this.state.articles) {
      articlesDisplay.push(<Article key={article.id}
        id={article.id} title={this.props.language === 'IT' ? article.title : article.titleEn} content={this.props.language === 'IT' ? article.content : article.contentEn} timestamp={article.updatedAt.substring(0, 10)}/>)
      articleTitles.push(
        <Typography key={"link" + article.id} variant="body2" style={{padding: "10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
          <Link key={"link" + article.id}  href={"#" + article.id}>
            {this.props.language === 'IT' ? article.title : article.titleEn}
          </Link>
        </Typography>
      )
    }
    this.setState({ articlesDisplay: articlesDisplay, articleTitles: articleTitles })
  }
  
  render() {
    return (
      <div>
        {this.state.articleTitles}
        {this.state.articlesDisplay}
      </div>
    );
  }
}
export default withRouter(UserArticles)