import React from 'react';

import { withRouter } from "react-router";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';

import SecondaryHeader from '../customComponents/SecondaryHeader'

import ApiUtils from '../api/ApiUtils'

class CreaTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newTeamName: "", 
      loadingScreen: <CircularProgress/>,
      createTeam: null
    }
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount() {
    ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
      this.setState({ user: user }, () => {
        if (user.isLeader || user.isAdmin || user.isCoach) {
          this.displayStuff()
        } else {
          this.setState({ 
            createTeam: 
              <Typography style={{padding: "20px"}} variant="h6">
                {this.props.language === 'IT' ? "Solo leader e coach possono creare team." : "Only leaders and coach can create teams"}
              </Typography>, 
            loadingScreen: null })
        }
      })
    })
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.language !== this.props.language) {
      if (this.state.user.isLeader || this.state.user.isAdmin || this.state.user.isCoach) {
        this.displayStuff()
      } else {
        this.setState({ 
          createTeam: 
            <Typography style={{padding: "20px"}} variant="h6">
              {this.props.language === 'IT' ? "Solo leader e coach possono creare team." : "Only leaders and coach can create teams"}
            </Typography>, 
          loadingScreen: null })
      }
    }
  }

  handleChange(event) {
    this.setState({ newTeamName: event.target.value })
  }

  async createNewTeam() {
    if (this.state.newTeamName === "") {
      if (this.props.language === "IT") {
        alert("Per favore inserisci un nome per il team.")
      } else {
        alert("Please insert a name for the team.")
      }
    } else {
      const team = await ApiUtils.createTeam({
        "teamName": this.state.newTeamName,
        "leader": this.props.user.attributes.email.toLowerCase(),
        "users": this.state.user.isLeader ? [this.props.user.attributes.email.toLowerCase()] : [],
        "reports": [],
        "surveys": ["FORM"],
        "reportsGenerated": [],
        "articlesShared": []
      })
      await ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
        if (!user.teamsOwned.includes(team.id)) {
          user.teamsOwned.push(team.id)
          delete user.createdAt;
          delete user.updatedAt;
          ApiUtils.updateUser(user)
        }
      })
      this.props.history.push('/team/' + team.id)
    }
  }

  async displayStuff() {
    let createTeam = null
    if (this.state.user.isCoach || this.state.user.isAdmin 
      || (this.state.user.isLeader && this.state.user.teamsOwned.length < 1)) {
      createTeam = <div>
      <Paper elevation={3} style={{width: "90%", margin: "20px", paddingBottom: "10px"}}>
        <SecondaryHeader text={this.props.language === 'IT' ? "Crea un nuovo team" : "Create a new team"}/>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
          <TextField
            label={this.props.language === 'IT' ? "Nome del team" : "Team name"}
            defaultValue={this.state.newTeamName}
            onChange={event => {
              const { value } = event.target;
              this.setState({ newTeamName: value });
            }}
            fullWidth
            variant="outlined"
          />
          <Button style={{marginTop: "20px"}} variant="contained" color="primary" 
            onClick={() => this.createNewTeam()}>
            {this.props.language === 'IT' ? "Crea team" : "Create team"}
          </Button>
        </Grid>
      </Paper>
      </div>
    } else {
      createTeam =
          <Typography style={{padding: "20px"}} variant="h6">
            {this.props.language === 'IT' ? "Per creare più di un team è necessario l'account Coach." :
             "To create more than one team the account Coach is necessary"}
          </Typography>
    }
    this.setState({
      newTeamName: "", 
      loadingScreen: null, 
      createTeam: createTeam
    })
  }

  render() {
    return (
      <div>
          {this.state.loadingScreen}
          {this.state.createTeam}
      </div>
    );
  }
}
export default withRouter(CreaTeam);
