import { withAuthenticator } from 'aws-amplify-react'
import React from 'react';
import './App.css';
import '@aws-amplify/ui/dist/style.css';
import { useLocation } from "react-router-dom";
import NavBar from './customComponents/NavBar';
import { Auth } from 'aws-amplify'
import { signUpConfig } from './config/AuthConfig';
import AuthTheme from './config/AuthTheme';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const materialUiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#42a9d7",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#f3993e",
      contrastText: "#ffffff"
    }
  },
})

const language = (window.navigator.userLanguage || window.navigator.language) === 'it' ? 'IT' : 'EN'

function App() {
  let location = useLocation();
  let user = Auth.user
  return (
    <div className="App"> 
      <ThemeProvider theme={materialUiTheme}>
        <NavBar page={location.pathname} user={user} language={language}/>
      </ThemeProvider>
    </div>
  );
}


export default withAuthenticator(App, { theme: AuthTheme, signUpConfig: signUpConfig, usernameAttributes: 'email' })
