import React from 'react'

import { Switch, Route, Link } from "react-router-dom";

import { Auth } from 'aws-amplify';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';


import Home from '../pages/Home'
import CreaTeam from '../pages/CreaTeam'
import MyTeams from '../pages/MyTeams'
import Account from '../pages/Account'
import About from '../pages/About'
import Team from '../pages/Team'
import TeamDashboard from '../pages/TeamDashboard'
import NormSurvey from '../pages/surveys/NormSurvey'
import PerformSurvey from '../pages/surveys/PerformSurvey'
import StormSurvey from '../pages/surveys/StormSurvey'
import FormSurvey from '../pages/surveys/FormSurvey'
import WaySurvey from '../pages/surveys/WaySurvey'
import ReportBase from '../pages/reports/ReportBase';
import Report from '../pages/reports/Report';
import NewArticle from '../pages/articles/NewArticle';
import EditArticle from '../pages/articles/EditArticle';
import Articles from '../pages/articles/Articles';
import UserArticles from '../pages/articles/UserArticles';
import UserFeedback from '../pages/UserFeedback';
import ApiUtils from '../api/ApiUtils';
import MailHelper from '../api/MailHelper';

class NavBar extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        drawerOpen: false,
        language: props.language,
      }
    }

    componentDidMount() {
      ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
        let userToStore
        var d = new Date();
        if (user === undefined) {
            userToStore = {
            "username": this.props.user.attributes.email.toLowerCase(), 
            "cognitoId": this.props.user.username, 
            "myProfile": "", 
            "teamsOwned": [],
            "teamsPartOf": [],
            "isLeader": false,
            "isAdmin": false,
            "isCoach": false,
            "reports": [],
            "surveys": [],
            "notificationsActive": true,
            "preferredLanguage": "IT"
            }
            ApiUtils.createUser(userToStore)
        } else if (user.isLeader && user.dateTrialPeriodEnd && (user.dateTrialPeriodEnd < d.setDate(d.getDate()-7).valueOf())) {
            user.isLeader = false
            delete user.updatedAt
            delete user.createdAt
            ApiUtils.updateUser(user)
            MailHelper.sendTrialAboutToExpireMail(this.props.user.attributes.email.toLowerCase())
            userToStore = user
            alert("Il tuo periodo di prova terminerà tra una settimana.")
        } else if (user.isLeader && user.dateTrialPeriodEnd && (user.dateTrialPeriodEnd < d.valueOf())) {
            user.isLeader = false
            delete user.updatedAt
            delete user.createdAt
            ApiUtils.updateUser(user)
            userToStore = user
            alert("Il tuo periodo di prova è terminato.")
        } else {
            userToStore = user
        }
        this.setState({
            user: userToStore,
            language: (userToStore.preferredLanguage === undefined || userToStore.preferredLanguage === null) ? 
            this.props.language : userToStore.preferredLanguage
        })
      })
    }
  
    updatePreferredLanguage(value) {
        let user = this.state.user
        user.preferredLanguage = value
        delete user.createdAt
        delete user.updatedAt
        ApiUtils.updateUser(user)
    }

    toggleDrawer(value) {
      this.setState({ drawerOpen: value })
    }

    onSignOutClick() {
      Auth.signOut()
        .catch(err => console.log(err));
    }

    render() {
    const page = this.props.page.split("/")[1].toUpperCase()
    const drawer = <List>
        <img src="https://reports.teamx.solutions/images/TeamX-logo.png" width="150px" style={{margin: "20px"}} alt="logo"/>
            <ListItem onClick={() => this.toggleDrawer(false)}>
                <Link to="/">HOME</Link>
            </ListItem>
            <ListItem onClick={() => this.toggleDrawer(false)}>
                <Link to="/newteam">NEW TEAM</Link>
            </ListItem>
            <ListItem onClick={() => this.toggleDrawer(false)}>
                <Link to="/myteams">MY TEAMS</Link>
            </ListItem>
            <ListItem onClick={() => this.toggleDrawer(false)}>
                <Link to="/account">ACCOUNT</Link>
            </ListItem>
            <ListItem onClick={() => this.toggleDrawer(false)}>
                <Link to="/about">ABOUT</Link>
            </ListItem>
            <ListItem onClick={() => this.toggleDrawer(false)}>
                <Link to="/userArticles" >LIBRARY</Link>
            </ListItem>
            <ListItem onClick={() => this.onSignOutClick()}>
                <Button variant="contained" color="secondary" style={{margin: "40px auto", width: "90%"}}>
                    Exit&nbsp;<ExitToAppIcon/>
                </Button>
            </ListItem>

            <ListItem component="div" style={{ width: "150px" }}>
                <FormControl>
                <InputLabel id="language-select-label">{this.state.language === 'IT' ? "Lingua" : "Language"}</InputLabel>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={this.state.language}
                    onChange={(event) => {
                        this.updatePreferredLanguage(event.target.value)
                        this.setState({language: event.target.value})
                    }}
                >
                    <MenuItem value={'IT'}>IT</MenuItem>
                    <MenuItem value={'EN'}>EN</MenuItem>
                    
                </Select>
                </FormControl>
            </ListItem> 

            <ListItem component="div" style={{ width: "150px" }}>
                <Typography variant="body1" style={{fontSize: "12px"}}>
                    {this.state.language === 'IT' ? 
                    "Utilizzando l'app TeamX dichiaro di aver letto e accettato la " :
                    "By using the TeamX app, I declare that I have read and accepted the "
                    }
                    <a style={{fontSize: "12px"}} href={this.state.language === 'IT' ? "https://weareteamx.com/politica-di-privacy/" : "https://weareteamx.com/en/privacy-policy/"} target="_blank" rel="noopener noreferrer">
                        Privacy Policy.
                    </a>
                </Typography>
            </ListItem> 
        </List>

    return (
        <div style={{flexGrow: 1}}>
            <AppBar position="static" style={{ background: '#42aad7' }}>
                <Toolbar>
                    <Button onClick={() => this.toggleDrawer(true)}>
                        <MenuIcon style={{color: "#ffffff"}} />
                    </Button>
                    <Drawer anchor="left" open={this.state.drawerOpen} onClose={() => this.toggleDrawer(false)}>
                        {drawer}
                    </Drawer>
                    <Typography variant="h6">
                        {page}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Switch>
                <Route exact path="/">
                    <Home user={this.props.user}  language={this.state.language}/>
                </Route>
                <Route exact path="/home">
                    <Home user={this.props.user} language={this.state.language} />
                </Route>
                <Route path="/account">
                    <Account user={this.props.user}  language={this.state.language}/>
                </Route>
                <Route path="/about">
                    <About user={this.props.user} language={this.state.language} />
                </Route>
                <Route path="/newteam">
                    <CreaTeam user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/myteams">
                    <MyTeams user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/team/:teamId">
                    <Team user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/teamDashboard/:teamId">
                    <TeamDashboard user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/survey/form">
                    <FormSurvey user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/survey/storm/:teamId">
                    <StormSurvey user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/survey/norm/:teamId">
                    <NormSurvey user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/survey/perform/:teamId">
                    <PerformSurvey user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/survey/way/:teamId">
                    <WaySurvey user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/report/form/:teamId">
                    <ReportBase user={this.props.user} reportType="FORM" language={this.state.language}/>
                </Route>
                <Route path="/report/storm/:teamId">
                    <ReportBase user={this.props.user} reportType="STORM" language={this.state.language}/>
                </Route>
                <Route path="/report/norm/:teamId">
                    <ReportBase user={this.props.user} reportType="NORM" language={this.state.language}/>
                </Route>
                <Route path="/report/perform/:teamId">
                    <ReportBase user={this.props.user} reportType="PERFORM" language={this.state.language}/>
                </Route>
                <Route path="/report/way/:teamId">
                    <ReportBase user={this.props.user} reportType="WAY" language={this.state.language}/>
                </Route>
                <Route path="/report/:type/:teamId/:reportId">
                    <Report user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/newArticle/">
                    <NewArticle user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/editArticle/:articleId">
                    <EditArticle user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/articles/">
                    <Articles user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/userArticles/">
                    <UserArticles user={this.props.user} language={this.state.language}/>
                </Route>
                <Route path="/feedback/:teamId/:surveyType/:processNumber">
                    <UserFeedback user={this.props.user} language={this.state.language}/>
                </Route>
            </Switch>
        </div>
    );
  }
}
  
export default NavBar
  


