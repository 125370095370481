import React from 'react';
import { withRouter } from "react-router";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import ApiUtils from '../../api/ApiUtils'

import SurveyQuestion from '../../customComponents/SurveyQuestion'
import NoAccess from '../../customComponents/NoAccess'

class WaySurvey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      surveyAnswers: Array(5),
      modalOpen: false,
      loadingScreen: <CircularProgress/>,
      noAccess: null,
      submitDisabled: false
    }
  }

  async componentDidMount() {
    await ApiUtils.getTeamById(this.props.match.params.teamId).then((team) => {
      if (team !== null && team !== undefined 
        && team.users.includes(this.props.user.attributes.email.toLowerCase())
        && team.surveys.includes("WAY") ) {
          ApiUtils.getSurveysForUser(this.props.user.attributes.email.toLowerCase()).then((surveys) => {
            let formSurvey
            let formFound = false
            for (const survey of surveys) {
              if (survey.type === 'FORM' && !formFound) {
                formSurvey = survey
                formFound = true
              }
            }
            this.setState({
              formAnswers: formSurvey.answers,
            })
          }).catch((err) => {
            console.log(err)
            alert("Qualcosa è andato storto. Compila il MyProfile prima di questa survey.")
            this.props.history.push('/home')
          })
          this.setState({ shouldPopulateSurvey: true, loadingScreen: null })
      } else {
        this.setState({ 
          shouldPopulateSurvey: false,
          loadingScreen: null, 
          noAccess: <NoAccess text="La survey WAY non è attiva per questo team."/> 
        })
      }
    })
  }

  toggleModal(state) {
    this.setState({modalOpen: state})
    
  }

  handleChange(value, radio) {
    let surveyAnswers = this.state.surveyAnswers
    surveyAnswers[radio] = value
    this.setState({ surveyAnswers: surveyAnswers })
  }

  async submitSurvey() {
    for (const answer of this.state.surveyAnswers) {
      if (answer === null || answer === undefined) {
        alert("Per favore rispondi a tutte le domande")
        return
      }
    }
    const totalAnswers = this.state.formAnswers.concat(this.state.surveyAnswers)
    await ApiUtils.createSurvey({
      "teamId": this.props.match.params.teamId,
      "user": this.props.user.attributes.email.toLowerCase(),
      "answers": totalAnswers,
      "type": "WAY",
      "isArchived": false,
    })
    this.props.history.push('/home')
  }

  render() {
    let survey
    if (this.state.shouldPopulateSurvey) {
      survey = 
      <div>
        {this.props.language === 'IT' ? 
        <Typography variant="h6" style={{padding: "10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
          Di seguito troverete 7 affermazioni. Ci sono 5 possibilità di risposta (da sinistra a destra):<br/>
          1. In disaccordo<br/>
          2. Parzialmente in disaccordo<br/>
          3. Né in disaccordo, né in accordo<br/>
          4. Parzialmente in accordo<br/>
          5. In accordo<br/>
          Inoltre troverai una domanda finale più generica, avente 3 possibili opzioni di risposta. 
          Leggete ciascuna affermazione e scegliete l'alternativa di risposta che meglio vi descrive.<br/>
          Non ci sono risposte giuste o sbagliate, rispondete semplicemente cio' che pensate.<br/>
        </Typography>
        : 
        <Typography variant="h6" style={{padding: "10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
          Below you will find 7 statements. There are 5 potential answers (left to right):<br/>
          1. I disagree<br/>
          2. Partially disagree<br/>
          3. Neither disagree, nor agree<br/>
          4. Partially agree<br/>
          5. I agree<br/>
          In addition you'll find a more generic final question, with 3 possible answer options.
          Read each statement and choose the best answer for you.<br/>
          There are no right or wrong answers, simply answer what you think.<br/>
        </Typography>
        }
        <SurveyQuestion 
          questionValue={this.state.surveyAnswers[0]} 
          onChange={(v) => this.handleChange(v, 0)}
          questionText={this.props.language === 'IT' ? "Lavorare con le persone del team mi fa crescere professionalmente" : "Working with this team allows me to grow professionally" }
        />
        <SurveyQuestion 
          questionValue={this.state.surveyAnswers[1]} 
          onChange={(v) => this.handleChange(v, 1)}
          questionText={this.props.language === 'IT' ? "Il mio team è altamente performante" : "This is a high performing team" }
        />
        <SurveyQuestion 
          questionValue={this.state.surveyAnswers[2]} 
          onChange={(v) => this.handleChange(v, 2)}
          questionText={this.props.language === 'IT' ? "Sappiamo cosa fare come team per avere successo in futuro" : "As a team, we know how to achieve future success" }
        />
        <SurveyQuestion 
          questionValue={this.state.surveyAnswers[3]} 
          onChange={(v) => this.handleChange(v, 3)}
          questionText={this.props.language === 'IT' ? "Il mio team risolve velocemente i problemi" : "We solve challenges quickly" }
        />
        <SurveyQuestion 
          questionValue={this.state.surveyAnswers[5]} 
          onChange={(v) => this.handleChange(v, 5)}
          questionText={this.props.language === 'IT' ? "Le persone nel team cercano di fare un lavoro eccellente" : "Our team has internal drive to do excellent work" }
        />
        <SurveyQuestion 
          questionValue={this.state.surveyAnswers[6]} 
          onChange={(v) => this.handleChange(v, 6)}
          questionText={this.props.language === 'IT' ? "Nel team si collabora efficacemente" : "The team cooperates effectively" }
        />
        <SurveyQuestion 
          questionValue={this.state.surveyAnswers[7]} 
          onChange={(v) => this.handleChange(v, 7)}
          questionText={this.props.language === 'IT' ? "Ho fiducia nelle persone del team" : "I trust the other team members" }
        />
        <div>
          <Typography variant="h6" style={{padding: "20px 20px 0 20px"}}>
            {this.props.language === 'IT' ? "Pensando agli ultimi 3 mesi, la performance del team è" : "Thinking about the last 3 months, the performance of the team is" }
          </Typography>
          <RadioGroup style={{paddingLeft: "20px"}} aria-label="performance" name="performance" value={this.state.surveyAnswers[4]} 
              onChange={(e) => this.handleChange(e.target.value, 4)}>
            <FormControlLabel value="1" control={<Radio color="primary" value="1"/>} label={this.props.language === 'IT' ? "Diminuita" : "Decreased"} />
            <FormControlLabel value="3" control={<Radio color="primary" value="3"/>} label={this.props.language === 'IT' ? "Uguale" : "The same"}  />
            <FormControlLabel value="5" control={<Radio color="primary" value="5"/>} label={this.props.language === 'IT' ? "Aumentata" : "Increased"}  />
          </RadioGroup>
        </div>
        <hr/>

        <Button variant="contained" color="primary" style={{margin: "20px"}}
          onClick={() => this.toggleModal(true)}>
          {this.props.language === 'IT' ? "Concludi il questionario" : "Finish the survey"}
        </Button>
        <Dialog
          open={this.state.modalOpen}
          onClose={() => this.toggleModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.language === 'IT' ? "Concludi il questionario" : "Finish the survey"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.language === 'IT' ? 
              "Una volta concluso il questionario, non potrai più cambiare le tue risposte." : 
              "Once you have submitted the survey, you won't be able to change your answers."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => this.toggleModal(false)} color="secondary">
              {this.props.language === 'IT' ? "Indietro" : "Back"}
            </Button>
            <Button variant="contained" color="primary" autoFocus
              disabled={this.state.submitDisabled} 
              onClick={() => {this.setState({submitDisabled:true}); this.submitSurvey() }} >
              {this.props.language === 'IT' ? "Conferma" : "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    }
    return (
      <div>
        {this.state.loadingScreen}
        {survey}
        {this.state.noAccess}
      </div>
    );
  }
}
export default withRouter(WaySurvey)