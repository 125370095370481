/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      teamId
      user
      type
      answers
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelsurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamId
        user
        type
        answers
        isArchived
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      teamId
      isShared
      type
      data
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelreportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamId
        isShared
        type
        data
        isArchived
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      title
      content
      category
      subCategory
      createdAt
      updatedAt
      titleEn
      contentEn
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelarticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        category
        subCategory
        createdAt
        updatedAt
        titleEn
        contentEn
      }
      nextToken
    }
  }
`;
export const getUserFeedback = /* GraphQL */ `
  query GetUserFeedback($id: ID!) {
    getUserFeedback(id: $id) {
      id
      surveyType
      processNumber
      user
      answer
      teamId
      isArchived
      createdAt
      updatedAt
    }
  }
`;
export const listUserFeedbacks = /* GraphQL */ `
  query ListUserFeedbacks(
    $filter: ModeluserFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyType
        processNumber
        user
        answer
        teamId
        isArchived
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      username
      isLeader
      isAdmin
      isCoach
      myProfile
      teamsOwned
      teamsPartOf
      surveys
      reports
      articlesReviewed
      dateTrialPeriodEnd
      notificationsActive
      formMailsReceivedCount
      stormMailsReceived
      normMailsReceived
      performMailsReceived
      owner
      individualMailsReceivedCount
      preferredLanguage
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        username
        isLeader
        isAdmin
        isCoach
        myProfile
        teamsOwned
        teamsPartOf
        surveys
        reports
        articlesReviewed
        dateTrialPeriodEnd
        notificationsActive
        formMailsReceivedCount
        stormMailsReceived
        normMailsReceived
        performMailsReceived
        owner
        individualMailsReceivedCount
        preferredLanguage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      teamName
      leader
      users
      reports
      surveys
      reportsGenerated
      articlesShared
      articlesBlacklisted
      profile
      suggestions
      sentimentScore
      emotion
      talent
      normAverage
      lowDynamic
      targetDynamic
      performance
      mood
      targetOutcome
      dateLastReportGenerated
      owner
      bestActions
      stormQuestion
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelteamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamName
        leader
        users
        reports
        surveys
        reportsGenerated
        articlesShared
        articlesBlacklisted
        profile
        suggestions
        sentimentScore
        emotion
        talent
        normAverage
        lowDynamic
        targetDynamic
        performance
        mood
        targetOutcome
        dateLastReportGenerated
        owner
        bestActions
        stormQuestion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
