import React from 'react';
import ReactMarkdown from 'react-markdown'

import Paper from '@material-ui/core/Paper';

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneIcon from '@material-ui/icons/Done';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import BlockIcon from '@material-ui/icons/Block';

import SecondaryHeader from '../../customComponents/SecondaryHeader'
import Paragraph from '../../customComponents/Paragraph'

function Image(props) {
  // eslint-disable-next-line
  return <img {...props} style={{maxWidth: '90%'}} />
}

const renderers = {
  image: Image,
  code: ({ language, value }) => {
    if (language === 'playIcon') {
      return <PlayArrowIcon/>
    }
    if (language === 'doneIcon') {
      return <DoneIcon/>
    }
    if (language === 'blockIcon') {
      return <BlockIcon/>
    }
    if (language === 'hourglassIcon') {
      return <HourglassEmptyIcon/>
    }
    const className = language && `language-${language}`
    const code = React.createElement('code', className ? { className: className } : null, value)
    return React.createElement('pre', {}, code)
  },
}

class Article extends React.Component {

  render() {
    return (
    <Paper elevation={3} id={this.props.id} key={this.props.id} style={{margin:20, padding:20}}>
      <SecondaryHeader text={this.props.title} />
      <br/>
      <ReactMarkdown escapeHtml={false} source={this.props.content} 
      renderers={renderers}/>
      <br/>
      <Paragraph text={this.props.timestamp} />
    </Paper>
    );
  }
}
export default Article