import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';

const mapping_actions = {
  "data_mining": ["FOCUS RAZIONALE", "Assicurati che il team abbia a disposizione le info necessarie"],
  "modeling": ["FOCUS RAZIONALE", "Organizza le informazioni disponibili in uno schema generale"],
  "prioritizing": ["FOCUS RAZIONALE", "Definisci le informazioni chiave per il team"],
  "connection": ["FOCUS EMOTIVO", "Chiedi più spesso cosa provano le persone"],
  "emotional_insights": ["FOCUS EMOTIVO", "Domandati cosa ha generato uno stato d'animo e che impatti può avere"],
  "emotional_intelligence": ["FOCUS EMOTIVO", "Domandati cosa ha generato uno stato d'animo e che impatti può avere"],
  "collaboration": ["FOCUS EMOTIVO", "Migliora la conoscenza e le relazioni tra le persone"],
  "reflection": ["DECISION MAKING RIFLESSIVO", "Lascia più spazio tra la discussione e le decisioni"],
  "adaptability": ["DECISION MAKING RIFLESSIVO", "Valorizza le diverse opinioni all’interno del team"],
  "critical_thinking": ["DECISION MAKING RIFLESSIVO", "Approfondisci pro e contro delle alternative disponibili"],
  "resilience": ["DECISION MAKING CREATIVO", "Aumenta il numero di alternative tra cui scegliere"],
  "risk_tolerance": ["DECISION MAKING CREATIVO", "Accetta il rischio e sperimenta soluzioni diverse"],
  "imagination": ["DECISION MAKING CREATIVO", "Dedica tempo ad immaginare con il team futuri possibili"],
  "proactivity": ["DRIVE PRATICO", "Anticipa i bisogni e agisci prima che sia richiesto"],
  "commitment": ["DRIVE PRATICO", "Mantieni la massima attenzione sulle priorità del team"],
  "problem_solving": ["DRIVE PRATICO", "Affronta tempestivamente eventuali situazioni critiche"],
  "vision": ["DRIVE IDEALISTA", "Discuti col team la visione di lungo periodo"],
  "design": ["DRIVE IDEALISTA", "Condividi il percorso per avere un futuro di successo"],
  "entrepreneurship": ["DRIVE IDEALISTA", "Dedica tempo all’innovazione per vincere le nuove sfide"],
  "trust": ["TRUST", "Favorisci un ambiente dove potersi esprimere e fidarsi"],
  "synergy": ["SYNERGY", "Valorizza le competenze del team e la collaborazione"],
  "energy": ["ENERGY", "Definisci con il team il prossimo passo verso l'eccellenza"],
}

const mapping_actions_en = {
  "data_mining": ["FOCUS RATIONAL", "Make sure the team has all the necessary information"],
  "modeling": ["FOCUS RATIONAL", "Organize the available information into a general scheme"],
  "prioritizing": ["FOCUS RATIONAL", "Define the key information for the team"],
  "connection": ["FOCUS EMOTIONAL", "Ask more frequently about people’s feelings"],
  "emotional_insights": ["FOCUS EMOTIONAL", "Ask yourself what has generated a state of mind and which impact could have"],
  "emotional_intelligence": ["FOCUS EMOTIONAL", "Ask yourself what has generated a state of mind and which impact could have"],
  "collaboration": ["FOCUS EMOTIONAL", "Improve understanding and relationship among people"],
  "reflection": ["DECISION MAKING EVALUATIVE", "Allow more space between the discussion and the decision"],
  "adaptability": ["DECISION MAKING EVALUATIVE", "Appreciate the different opinions inside the team"],
  "critical_thinking": ["DECISION MAKING EVALUATIVE", "Further explore pros and cons of the available alternatives"],
  "resilience": ["DECISION MAKING CREATIVE", "Increase the number of alternatives you can choose among"],
  "risk_tolerance": ["DECISION MAKING CREATIVE", "Take risks and experience different solutions"],
  "imagination": ["DECISION MAKING CREATIVE", "Spend more time to imagine possible futures with the team"],
  "proactivity": ["DRIVE PRACTICAL", "Anticipate needs and act before it’s required"],
  "commitment": ["DRIVE PRACTICAL", "Keep the highest attention on team’s priorities"],
  "problem_solving": ["DRIVE PRACTICAL", "Face promptly eventual critical situations"],
  "vision": ["DRIVE IDEALIST", "Discuss with the team the long-term vision"],
  "design": ["DRIVE IDEALIST", "Share the path for a successful future"],
  "entrepreneurship": ["DRIVE IDEALIST", "Spend time on innovation to win new challenges"],
  "trust": ["TRUST", "Foster a team where people can discuss and feel safe"],
  "synergy": ["SYNERGY", "Build on team’s competences and collaboration"],
  "energy": ["ENERGY", "Define with the team the next step to excellence"],
}

function FullCircle() {
  return <svg width="40" height="40">
    <circle cx="20" cy="20" r="15" stroke="#42aad7" strokeWidth="2" fill="#42aad7" />
  </svg>
}

function EmptyCircle() {
  return <svg width="40" height="40">
    <circle cx="20" cy="20" r="15" stroke="gainsboro" strokeWidth="2" fill="#e8e8e8" />
  </svg>
}

function WeirdChart(props) {
  const style = {
    width: props.filledPerc, 
    borderRadius: "20px", 
    backgroundColor: "#a9a9a9", 
    height: "30px"
  }
  return <div style={{borderRadius: "20px", width: "198px", height: "30px", backgroundColor: "#e6e6e6", marginLeft:"auto", marginRight:"auto", marginTop:"10px"}}>
      <div style={style}>
      </div>
      <div style={{position: "relative", top: "-34px", left: "99px"}}>
        <div style={{height: "40px", width: "1px", backgroundColor: "#6b6b6b"}}>
        </div>
      </div>
      <div style={{position: "relative", top: "-74px", left: "49px"}}>
        <div style={{height: "40px", width: "1px", backgroundColor: "#6b6b6b"}}>
        </div>
      </div>
      <div style={{position: "relative", top: "-114px", left: "149px"}}>
        <div style={{height: "40px", width: "1px", backgroundColor: "#6b6b6b"}}>
        </div>
      </div>
  </div>
}

class PerformReport extends React.Component {

  render() {
    let data = JSON.parse(this.props.data.data)
    let averageBalls = []
    if (data.avg > 1.5) {
      averageBalls.push(<FullCircle key="first"/>)
    } else {
      averageBalls.push(<EmptyCircle key="first"/>)
    }
    if (data.avg > 2) {
      averageBalls.push(<FullCircle key="second"/>)
    } else {
      averageBalls.push(<EmptyCircle key="second"/>)
    }
    if (data.avg > 3) {
      averageBalls.push(<FullCircle key="third"/>)
    } else {
      averageBalls.push(<EmptyCircle key="third"/>)
    }
    if (data.avg > 4) {
      averageBalls.push(<FullCircle key="fourth"/>)
    } else {
      averageBalls.push(<EmptyCircle key="fourth"/>)
    }
    let stdBalls = []
    if (data.std < 2) {
      stdBalls.push(<FullCircle key="first"/>)
    } else {
      stdBalls.push(<EmptyCircle key="first"/>)
    }
    if (data.std < 1.5) {
      stdBalls.push(<FullCircle key="second"/>)
    } else {
      stdBalls.push(<EmptyCircle key="second"/>)
    }
    if (data.std < 1) {
      stdBalls.push(<FullCircle key="third"/>)
    } else {
      stdBalls.push(<EmptyCircle key="third"/>)
    }
    if (data.std < 0.5) {
      stdBalls.push(<FullCircle key="fourth"/>)
    } else {
      stdBalls.push(<EmptyCircle key="fourth"/>)
    }
    const speedPerc = Math.round(data.avg_speed/5*100) + "%"
    const resultsPerc = Math.round(data.avg_results/5*100) + "%"
    const growthPerc = Math.round(data.avg_growth/5*100) + "%"
    const sustainabilityPerc = Math.round(data.avg_confidence/5*100) + "%"
    let nds
    if (data.net_development_score < 0) {
      if (this.props.language === 'IT') {
        nds = "Diminuita"
      } else {
        nds = "Lower"
      }
    } else if (data.net_development_score > 0) {
      if (this.props.language === 'IT') {
        nds = "Aumentata"
      } else {
        nds = "Higher"
      }
    } else {
      if (this.props.language === 'IT') {
        nds = "Uguale"
      } else {
        nds = "The same"
      }
    }
    const speed = 
    <Grid item key="speed" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Speed
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "Si parla di Speed come della percezione della velocità con cui il team risolve i problemi." : "We talk about Speed as the perception of how fast the team solve problems."}
        </Typography>
        <WeirdChart filledPerc={speedPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {speedPerc}
        </Typography>
      </Paper>
    </Grid>
    const results =
    <Grid item key="results" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Results
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
        {this.props.language === 'IT' ? "Si parla di Results come della percezione dei risultati che il team sta ottenendo." : "We talk about Results as the level of productivity perceived by the team."}
        </Typography>
        <WeirdChart filledPerc={resultsPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {resultsPerc}
        </Typography>
      </Paper>
    </Grid>
    const growth = 
    <Grid item key="growth" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Growth
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "Si parla di Growth come della percezione della crescita professionale del gruppo." : "We talk about Growth as the perception of professional improvement of the team members."}
        </Typography>
        <WeirdChart filledPerc={growthPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {growthPerc}
        </Typography>
      </Paper>
    </Grid>
    const sustainability = 
    <Grid item key="sustainability" style={{width:"90%"}}>
      <Paper elevation={3}>
        <Typography variant="h6" style={{margin:"20px"}}>
          Sustainability
        </Typography>
        <Typography variant="body2" style={{padding: "0px 20px 0 20px", textAlign:"left"}}>
          {this.props.language === 'IT' ? "Si parla di Sustainability come della percezione della fiducia e della tranquillità nei confronti delle performance future." : "We talk about Sustainability as the perception of trust and safety towards future."}
        </Typography>
        <WeirdChart filledPerc={sustainabilityPerc}/>
        <Typography variant="body1" style={{margin:"10px"}}>
          {sustainabilityPerc}
        </Typography>
      </Paper>
    </Grid>
    let outcomeDisplay = []
    let outcomeList = [
      {
        "value": data.avg_confidence,
        "display": sustainability,
        "name": "sustainability"
      },
      {
        "value": data.avg_growth,
        "display": growth,
        "name": "growth"
      },
      {
        "value": data.avg_results,
        "display": results,
        "name": "results"
      },
      {
        "value": data.avg_speed,
        "display": speed,
        "name": "speed"
      }
    ]
    outcomeList.sort(function(a, b) {return a.value - b.value;}).reverse()
    for (const outc of outcomeList) {
      outcomeDisplay.push(outc.display)
    }
    return (
      <div>
        <Typography variant="body1">
          {this.props.language === 'IT' ? "Partecipanti: " + data.participants : "Participants: " + data.participants}
        </Typography>
        <Typography variant="h6" style={{marginRight:"20px"}}>
          TEAM PERFORMANCE
        </Typography>
        <Grid container justify="center" alignItems="center" spacing={1} style={{padding: "10px"}}>
        {this.props.language === 'IT' ? 
          <Typography variant="body1" style={{marginRight:"20px"}}>
            Media<br/>Complessiva
          </Typography> : 
          <Typography variant="body1" style={{marginRight:"20px"}}>
            Overall<br/>Average
          </Typography>
          }
          {averageBalls}
        </Grid>
        <Grid container justify="center" alignItems="center" spacing={1} style={{padding: "10px"}}>
          {this.props.language === 'IT' ? 
          <Typography variant="body1" style={{marginRight:"23px"}}>
            Omogeneità<br/>percezioni
          </Typography> :
          <Typography variant="body1" style={{marginRight:"23px"}}>
            Homogeneity<br/>of perceptions
        </Typography>
        }
          {stdBalls}
        </Grid>
        <Grid direction="column" container justify="center" alignItems="center" spacing={1} style={{padding: "10px"}}>
          <Grid item key="nds" style={{width:"90%"}}>
            <Paper elevation={3}>
              <Typography variant="body1" style={{margin:"20px"}}>
              {this.props.language === 'IT' ? "La percezione del team è che la performance negli ultimi 3 mesi sia" :
              "Thinking about the last 3 months, the team perceive that the performance is"}
              </Typography>
              <Typography variant="h6" style={{margin:"10px"}}>
                {nds}
              </Typography>
            </Paper>
          </Grid>
          <Grid item key="bestAction" style={{width:"90%"}}>
            <Paper elevation={3}>
              <Typography variant="h6" style={{margin:"20px"}}>
                Best Action:<br/>{this.props.language === 'IT' ? mapping_actions[data.total_best_action][0] : mapping_actions_en[data.total_best_action][0]}
              </Typography>
              <Typography variant="body1" style={{margin:"10px"}}>
                {this.props.language === 'IT' ? mapping_actions[data.total_best_action][1] : mapping_actions_en[data.total_best_action][1]}
              </Typography>
            </Paper>
          </Grid>
          <Grid item key="topdisclaimer" style={{width:"90%"}}>
            <Typography variant="body2" style={{margin:"10px", textAlign:"left"}}>
            {this.props.language === 'IT' ? 
              "In alto, il valore medio complessivo dei quattro outcomes di performance (Speed, Results, Sustainability, Growth) e l’omogeneità delle risposte alla survey, rappresentativa della deviazione standard. Sotto, i quadrati riportano per ciascun outcome la definizione e il grafico con il valore della percezione dei membri del team." :
              "On the top, the overall average value of the four performance outcomes (Speed, Results, Sustainability, Growth) and the homogeneity of the responses to the survey, representative of the standard deviation. The two quadrants up above, the Mood of the team related to the last three months and the Total Best Action, that is the action with the greatest impact on the outcomes in general. The quadrants down below show the following information for each outcome: the definition of the outcome; the self-perception bar, the value of the  team members’ perception; the customer's perception bar, which emerged from the data provided by the customer involved. The outcomes are in order: the first one is the one with the highest perception."
            }
            </Typography>
          </Grid>
          {outcomeDisplay}
        </Grid>
      </div>
    );
  }
}
export default PerformReport