import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function SecondaryHeaderNoPaddingTop(props) {
  return (
    <div>
      <Typography variant="h6" style={{padding: "0px 10px 10px 10px", textAlign: "left"}}>
        {props.text}
      </Typography>
    </div>
  );
}