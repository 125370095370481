import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function SurveyStatus(props) {
  return (
    <div>
      <ExpansionPanel style={{border: "1px #d0d0d0 solid"}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} id={"expPanel" + props.report}>
          <Typography>{props.report}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{backgroundColor: "#fafafa"}}>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
          <Grid container justify="center" alignItems="center" spacing={1} style={{padding: "10px"}}>
            <Grid item key={"gridSurveyId" + props.report}>
              <Typography>Survey</Typography>
              <Box component="span" m={1}>
                {props.surveyStatus}
              </Box>
            </Grid>
            <Grid item key={"gridReportId" + props.report}>
              <Typography>Report</Typography>
              <Box component="span" m={1}>
                {props.reportStatus}
              </Box>
            </Grid>
          </Grid>
          {props.archiveReport}
        </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}