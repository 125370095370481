import React from 'react';

import { withRouter } from "react-router";

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneIcon from '@material-ui/icons/Done';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BlockIcon from '@material-ui/icons/Block';
//import RedoIcon from '@material-ui/icons/Redo';
import Link from '@material-ui/core/Link';
import { Paper } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

import SecondaryHeader from '../customComponents/SecondaryHeader'
import Paragraph from '../customComponents/Paragraph'
import SurveyStatus from '../customComponents/SurveyStatus'

import ApiUtils from '../api/ApiUtils'
import MailHelper from '../api/MailHelper';
import ReportsHelper from '../api/ReportsHelper';

import { Auth } from 'aws-amplify'
import Lambda from 'aws-sdk/clients/lambda';

const AWS = require("aws-sdk")
AWS.config.update({region: 'eu-west-1'});

class Team extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      team: null, 
      emailRows: 1,
      teamMembers: [],
      loadingScreen: <CircularProgress/>,
      teamDetails: null,
      modalOpen: false,
    }
  }
  
  componentDidMount() {
    ApiUtils.getTeamById(this.props.match.params.teamId).then((team) => {
      ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
        if (team.leader === this.props.user.attributes.email.toLowerCase() || user.isAdmin) {
          ApiUtils.getUsersInTeam(this.props.match.params.teamId).then((users) => {
            ApiUtils.getSurveysForTeam(this.props.match.params.teamId).then((surveys) => {
              ApiUtils.getUserFeedbackForTeam(this.props.match.params.teamId).then((feedback) => {
                this.setState({ 
                  surveysCompleted: surveys,
                  users: users, 
                  user: user, 
                  feedback:feedback, 
                  customStormQuestion: team.stormQuestion !== null && team.stormQuestion !== undefined ?
                    team.stormQuestion :
                    this.props.language === 'IT' ? 
                      "Un team funziona bene quando è chiaro chi-fa-cosa. " +
                      "Come percepisci il tuo ruolo all’interno del team? " +
                      "Sai cosa fare e cosa gli altri si aspettano da te? " +
                      "Conosci il tuo ruolo e le responsabilità delle altre persone del team?"  : 
                      "A good team works when it’s clear who does what. What is your perception about your role inside the team? Do you know what to do and what others expect from you? Do you know your role and the responsibilities of the other team members?"
                }, () => {
                  this.populateView(team) 
                })
              })
            })
          })
        } else {
          this.setState({ teamDetails: 
              <Typography style={{padding: "20px"}} variant="h6">
                You need to be a leader to access this feature.
              </Typography>,
            loadingScreen: null
          })
        }
      })
    })
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.language !== this.props.language && this.state.team !== null) {
      this.populateView(this.state.team)
    }
  }

  saveCustomStormQuestion() {
    let team = this.state.team
    team.stormQuestion = this.state.customStormQuestion
    delete team.createdAt;
    delete team.updatedAt;
    ApiUtils.updateTeam(team)
  }

  generateReport(reportType) {
    Auth.currentCredentials()
    .then(credentials => {
      const lambda = new Lambda({
        region: "eu-west-1",
        credentials: Auth.essentialCredentials(credentials)
      });
      if (reportType === "FORM") {
        let formAnswers = []
        ApiUtils.getFormSurveysForUsers(this.state.team.users).then((formSurveys) => {
          for (const formSurvey of formSurveys) {
            formAnswers.push(formSurvey.answers)
          }
          lambda.invoke({
            FunctionName: 'teamXApiApp',
            Payload: JSON.stringify(
              ReportsHelper.getFormInputFormatted(
                formAnswers
              )
            ),
          }, (err, data) => {
            if (err) {
              console.log(err, err.stack);
            } else {
              let team = this.state.team
              ApiUtils.createReport({
                "teamId": team.id, 
                "isShared": false, 
                "type": "FORM", 
                "data": data.Payload,
                "isArchived": false,
              })
              .then((reportCreated) => {
                team.reportsGenerated.push(reportCreated.id)
                const index = team.surveys.indexOf(reportType);
                if (index > -1) {
                  team.surveys.splice(index, 1);
                }
                if (team.reports.indexOf("FORM") <= -1) {
                  team.reports.push("FORM")
                }
                delete team.createdAt;
                delete team.updatedAt;
                ApiUtils.updateTeam(team).then(() => {
                  setTimeout(() => {
                    this.props.history.push('/report/' + reportType + '/' + team.id + '/' + reportCreated.id)
                  }, 2000)
                })}
              )
            }
          });
        })
      } else {
        let surveyAnswers = []
        for (const survey of this.state.surveysCompleted) {
          if (survey.type === reportType) {
            if (survey.type === "STORM") {
              surveyAnswers.push(survey.answers[0])
            } else if (survey.type === "NORM") {
              surveyAnswers.push(
                {trust: survey.answers[0], synergy: survey.answers[1], energy: survey.answers[2]}
              )
            } else if (survey.type === "PERFORM") {
              surveyAnswers.push(
                ReportsHelper.getSinglePerformInputFormatted(survey.answers)
              )
            } else if (survey.type === "WAY") {
              surveyAnswers.push(
                ReportsHelper.getSinglePerformInputFormatted(survey.answers)
              )
            }
          }
        }
        lambda.invoke({
          FunctionName: 'teamXApiApp',
          Payload: JSON.stringify({
            "reportType": reportType,
            "data": surveyAnswers
          }),
        }, (err, data) => {
          if (err) {
            console.log(err, err.stack);
          } else {
            let team = this.state.team
            ApiUtils.createReport({
              "teamId": team.id, 
              "isShared": false, 
              "type": reportType, 
              "data": data.Payload,
              "isArchived": false,
            })
            .then((reportCreated) => {
              team.reportsGenerated.push(reportCreated.id)
              const index = team.surveys.indexOf(reportType);
              if (index > -1) {
                team.surveys.splice(index, 1);
              }
              if (team.reports.indexOf(reportType) <= -1) {
                team.reports.push(reportType)
              }
              team.dateLastReportGenerated = Date.now().valueOf();
              delete team.createdAt;
              delete team.updatedAt;
              ApiUtils.updateTeam(team).then(() => {
                setTimeout(() => {
                  this.props.history.push('/report/' + reportType + '/' + team.id + '/' + reportCreated.id)
                }, 2000)
              })
            })
          }
        });
      }
    })
  }

  async inviteUser(userMailSensitive) {
    const userMail = userMailSensitive.toLowerCase()
    var poolData = {
      UserPoolId: "eu-west-1_RbL4cSFph",
      Username: userMail,
      DesiredDeliveryMediums: ["EMAIL"],
      TemporaryPassword: "password",
      UserAttributes: [
        {
          Name: "email",
          Value: userMail
        },
        {
          Name: "email_verified",
          Value: "true"
        }
      ]
    };
    Auth.currentCredentials()
      .then(credentials => {
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        COGNITO_CLIENT.adminCreateUser(poolData, (error, data) => {
          if (error) {
            if (error.message === "An account with the given email already exists.") {
              ApiUtils.getUser(userMail).then((user) => {
                if (!user.teamsPartOf.includes(this.state.team.id)) {
                  user.teamsPartOf.push(this.state.team.id)
                  delete user.createdAt;
                  delete user.updatedAt;
                  ApiUtils.updateUser(user)
                }
              })
            } else {
              console.log(error.message)
            }
          } else {
            ApiUtils.createUser({
              "username": userMail, 
              "cognitoId": data.User.Username, 
              "owner": data.User.Username,
              "myProfile": "", 
              "teamsOwned": [],
              "teamsPartOf": [this.state.team.id],
              "isLeader": false,
              "isAdmin": false,
              "isCoach": false,
              "reports": [],
              "surveys": [],
              "notificationsActive": true,
            })
            MailHelper.sendIntroMail(userMail)
          }
        });
      })
  }

  removeUserFromTeam(userMail) {
    if (window.confirm(this.props.language === "IT" ? "Sicuro di voler rimuovere " + userMail + " dal team?" : "Are you sure you want to remove " + userMail + " from the team?")) {
      const teamId = this.state.team.id
      ApiUtils.getUser(userMail).then((user) => {
        if (user.teamsPartOf.includes(teamId)) {
          const index = user.teamsPartOf.indexOf(teamId);
          if (index > -1) {
            user.teamsPartOf.splice(index, 1);
          }
          delete user.createdAt;
          delete user.updatedAt;
          ApiUtils.updateUser(user)
        }
      })
      let team = this.state.team
      const index = team.users.indexOf(userMail);
      if (index > -1) {
        team.users.splice(index, 1);
      }
      delete team.createdAt;
      delete team.updatedAt;
      ApiUtils.updateTeam(team)
      ApiUtils.getSurveysForTeamAndUser(team.id, userMail).then((surveysToArchive) => {
        for (let survey of surveysToArchive) {
          delete survey.createdAt
          delete survey.updatedAt
          survey.isArchived = true
          ApiUtils.updateSurvey(survey)
        }
      }).then(() => {
        ApiUtils.getSurveysForTeam(this.props.match.params.teamId).then((surveys) => {
          this.setState({ surveysCompleted: surveys }, () => {
            this.populateView(team) 
          })
        })
      })
    }
  }

  sendTeamInvitations() {
    const teamMembers = this.state.teamMembers
    let teamMembersList = []
    for (let key of Object.keys(teamMembers)) {
      if (!teamMembers[key].includes("@")) {
        alert("not valid")
      } else {
        teamMembersList.push(teamMembers[key].toLowerCase().trim())
        this.inviteUser(teamMembers[key].toLowerCase().trim())
      }
    }
    ApiUtils.getTeamById(this.props.match.params.teamId).then((data) => {
      for (const member of teamMembersList) {
        if (!data.users.includes(member)) {
          data.users.push(member)
        }
      }
      delete data.createdAt;
      delete data.updatedAt;
      ApiUtils.updateTeam(data)
      this.setState({team: data}, () => this.populateView(data))
    })
  }

  addSurveyToTeam(survey) {
    let team = this.state.team
    team.surveys.push(survey)
    delete team.createdAt;
    delete team.updatedAt;
    ApiUtils.updateTeam(team).then(() => {
      MailHelper.sendMailSurveyAdded(team)
      // to update mails received counts
      for (let user of this.state.users) {
        if (survey === "STORM") {
          let performMailsReceived
          if (user.performMailsReceived !== undefined && user.performMailsReceived !== null) {
            performMailsReceived = JSON.parse(user.performMailsReceived)
          } else {
            performMailsReceived = {}
          }
          performMailsReceived[team.id] = 0
          user.performMailsReceived = JSON.stringify(performMailsReceived)
        } else if (survey === "NORM") {
          let stormMailsReceived
          if (user.stormMailsReceived !== undefined && user.stormMailsReceived !== null) {
            stormMailsReceived = JSON.parse(user.stormMailsReceived)
          } else {
            stormMailsReceived = {}
          }
          stormMailsReceived[team.id] = 0
          user.stormMailsReceived = JSON.stringify(stormMailsReceived)
        } else if (survey === "PERFORM") {
          let normMailsReceived
          if (user.normMailsReceived !== undefined && user.normMailsReceived !== null) {
            normMailsReceived = JSON.parse(user.normMailsReceived)
          } else {
            normMailsReceived = {}
          }
          normMailsReceived[team.id] = 0
          user.normMailsReceived = JSON.stringify(normMailsReceived)
        }
        delete user.createdAt;
        delete user.updatedAt;
        ApiUtils.updateUser(user)
      }
      this.populateView(team)
    })
  }

  archiveReport(reportType) {
    let team = this.state.team
    const index = team.reports.indexOf(reportType);
    if (index > -1) {
      team.reports.splice(index, 1);
    }
    if (reportType === "FORM") {
      team.profile = null
    } else if (reportType === "STORM") {
      team.sentimentScore = null
    } else if (reportType === "NORM") {
      team.normAverage = null
    } else if (reportType === "PERFORM") {
      team.performance = null
    }
    delete team.createdAt;
    delete team.updatedAt;
    ApiUtils.updateTeam(team)
    ApiUtils.getReportByTypeForTeam(team.id, reportType).then((report) => {
      report.isArchived = true
      delete report.createdAt;
      delete report.updatedAt;
      ApiUtils.updateReport(report).then(() => {
        if (reportType !== 'FORM') {
          ApiUtils.getSurveysForTeam(team.id).then((surveys) => {
            for (let survey of surveys) {
              if (survey.type === reportType) {
                survey.isArchived = true
                delete survey.createdAt;
                delete survey.updatedAt;
                ApiUtils.updateSurvey(survey)
              }
            }
          }).then(
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          )
        } else {
          this.populateView(team)
        }
      })
    })
  }

  handleTeamMemberChange(member, event) {
    const newValue = event.target.value
    let teamMembers = this.state.teamMembers
    teamMembers[member] = newValue
    this.setState({ teamMembers: teamMembers })
  }

  async deleteTeam() {
    if (window.confirm(this.props.language === "IT" ? "Sicuro di voler cancellare il team?" : "Are you sure you want to remove delete the team?")) {
      const team = this.state.team
      await ApiUtils.deleteTeam(team.id)
      await ApiUtils.getUser(this.props.user.attributes.email.toLowerCase()).then((user) => {
        if (user.teamsOwned.includes(team.id)) {
          const index = user.teamsOwned.indexOf(team.id);
          if (index > -1) {
            user.teamsOwned.splice(index, 1);
          }
          delete user.createdAt;
          delete user.updatedAt;
          ApiUtils.updateUser(user)
        }
      })
      for (const userMail of this.state.team.users) {
        const teamId = this.state.team.id
        ApiUtils.getUser(userMail).then((user) => {
          if (user.teamsPartOf.includes(teamId)) {
            const index = user.teamsPartOf.indexOf(teamId);
            if (index > -1) {
              user.teamsPartOf.splice(index, 1);
            }
            delete user.createdAt;
            delete user.updatedAt;
            ApiUtils.updateUser(user)
          }
        })
      }
      this.props.history.push('/myteams')
    }
  }

  populateView(team) {
    if (team === null) {
      this.setState({ 
        teamDetails: "Nothing to show here."
      })
      return
    }
    let formCompleted = 0
    for (const user of this.state.users) {
      if (user.myProfile !== null && user.myProfile !== undefined && user.myProfile !== "") {
        formCompleted += 1
      }
    }
    let teamMembersDisplay = []
    for (const teamMember of team.users) {
      teamMembersDisplay.push(
        <Chip key={"chipKey" + teamMember}
        style={{marginBottom: "20px"}}
          variant="outlined"
          label={teamMember}
          onDelete={() => this.removeUserFromTeam(teamMember)}
        />)
    }
    let emailDisplay = []
    for (let i = 0; i < this.state.emailRows; i++) {
      emailDisplay.push(
        <Box component="span" m={1} key={"emailKey" + i}>
          <Input id={"emailId" + i} label="Email"  onChange={(e) => this.handleTeamMemberChange(i, e)} />  
        </Box>)
    }
    let feedbackStorm2Pos = 0
    let feedbackStorm2Neg = 0
    let feedbackStormList3 = []

    let feedbackNorm2Pos = 0
    let feedbackNorm2Neg = 0
    let feedbackNormList3 = []

    let feedbackPerform2Pos = 0
    let feedbackPerform2Neg = 0
    let feedbackPerformList3 = []
    for (const feedback of this.state.feedback) {
      if (feedback.surveyType === "STORM") {
        if (feedback.processNumber === 2) {
          if (feedback.answer === "1") {
            feedbackStorm2Pos += 1
          } else if (feedback.answer === "0")
          feedbackStorm2Neg += 1
        } else if (feedback.processNumber === 3) {
          feedbackStormList3.push(
            <Paragraph key={feedback.id} text={feedback.answer}/>
          )
        }
      } else if (feedback.surveyType === "NORM") {
        if (feedback.processNumber === 2) {
          if (feedback.answer === "1") {
            feedbackNorm2Pos += 1
          } else if (feedback.answer === "0")
          feedbackNorm2Neg += 1
        } else if (feedback.processNumber === 3) {
          feedbackNormList3.push(
            <Paragraph key={feedback.id} text={feedback.answer}/>
          )
        }
      } else if (feedback.surveyType === "PERFORM") {
        if (feedback.processNumber === 2) {
          if (feedback.answer === "1") {
            feedbackPerform2Pos += 1
          } else if (feedback.answer === "0")
          feedbackPerform2Neg += 1
        } else if (feedback.processNumber === 3) {
          feedbackPerformList3.push(
            <Paragraph key={feedback.id} text={feedback.answer}/>
          )
        }
      }
    }
    let feedbackStorm = null
    if (feedbackStorm2Pos + feedbackStorm2Neg + feedbackStormList3.length > 0) {
      feedbackStorm = 
      <Paper elevation={3} 
        style={{width: "90%", margin: "5px auto", paddingBottom: "10px"}}>
          <SecondaryHeader text={"Feedback STORM"}/>
          <SecondaryHeader text={this.props.language === 'IT' ? 
            "Secondo te, il team sta lavorando sul profilo di team e sui suoi punti di forza?" : 
            "Do you think that the team is working on the team profile and its strengths?"}/>
          <Typography variant="h6" 
            style={{paddingLeft: "10px", paddingBottom:"10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
            {this.props.language === 'IT' ? "Sì" : "Yes"}: {feedbackStorm2Pos}, No: {feedbackStorm2Neg}
          </Typography>
          <hr/>
          <SecondaryHeader text={this.props.language === 'IT' ? 
            "Il team ha provato ad applicare il suggerimento ricevuto? Se si, che benefici ha portato?"
            + " Secondo te, cos’altro dovrebbe fare il team concretamente?"
            + " Cosa suggeriresti di fare per valorizzare ancora di più il talento del team?" :
            'Has the team tried to apply the suggestion received? If yes, what benefits did it bring? According to you, what else should the team do?'
            }/>
          {feedbackStormList3}
      </Paper>
    }
    let feedbackNorm = null
    if (feedbackNorm2Pos + feedbackNorm2Neg + feedbackNormList3.length > 0) {
      feedbackNorm = 
      <Paper elevation={3} 
        style={{width: "90%", margin: "5px auto", paddingBottom: "10px"}}>
          <SecondaryHeader text={"Feedback NORM"}/>
          <SecondaryHeader text={this.props.language === 'IT' ? 
            "Secondo te, il team sta lavorando sulle relazioni e le dinamiche all’interno del team?" : 
            "Do you think that the team is working on the team relationships and the dynamics within it?"}/>
          <Typography variant="h6" 
            style={{paddingLeft: "10px", paddingBottom:"10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
            {this.props.language === 'IT' ? "Sì" : "Yes"}: {feedbackNorm2Pos}, No: {feedbackNorm2Neg}
          </Typography>
          <hr/>
          <SecondaryHeader text={this.props.language === 'IT' ? 
            "Il team ha provato ad applicare il suggerimento ricevuto? Se si, che benefici ha portato?"
            + " Secondo te, cos’altro dovrebbe fare il team concretamente?"
            + " Cosa suggeriresti di fare per valorizzare ancora di più le dinamiche del team?":
            'Has the team tried to apply the suggestion received? If yes, what benefits did it bring? According to you, what else should the team do?'
            }/>
          {feedbackNormList3}
      </Paper>
    }
    let feedbackPerform = null
    if (feedbackPerform2Pos + feedbackPerform2Neg + feedbackPerformList3.length > 0) {
      feedbackPerform = 
      <Paper elevation={3} 
        style={{width: "90%", margin: "5px auto", paddingBottom: "10px"}}>
          <SecondaryHeader text={"Feedback PERFORM"}/>
          <SecondaryHeader text={this.props.language === 'IT' ? 
            "Secondo te, il team sta lavorando sulla performance del team?" : 
            "Do you think that the team is working on the team performance?"}/>
          <Typography variant="h6" 
            style={{paddingLeft: "10px", paddingBottom:"10px", textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
            {this.props.language === 'IT' ? "Sì" : "Yes"}: {feedbackPerform2Pos}, No: {feedbackPerform2Neg}
          </Typography>
          <hr/>
          <SecondaryHeader text={this.props.language === 'IT' ? 
            "Stai vedendo dei miglioramenti nella performance, rispetto all’outcome su cui avete scelto di concentrarvi?" :
            "Have you seen improvements in the performance, in respect to the outcome on which you have chose to focus on?"
            }/>
          {feedbackPerformList3}
      </Paper>
    }
    let stormQuestion = null
    // can edit storm question only when STORM survey is not active
    if (!team.surveys.includes("STORM")) {
      stormQuestion = 
      <Paper elevation={3} 
        style={{width: "90%", margin: "5px auto", paddingBottom: "10px"}}>
          <SecondaryHeader text={"STORM Question"}/>
          <TextField
            id="outlined-full-width"
            label={this.props.language === 'IT' ? "Domanda" : "Question"}
            defaultValue={this.state.customStormQuestion}
            onChange={event => {
              const { value } = event.target;
              this.setState({ customStormQuestion: value });
            }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <Button variant="contained" color="primary" style={{margin: "20px"}}
            onClick={() => this.saveCustomStormQuestion()}>
            {this.props.language === 'IT' ? "Salva" : "Save"}
          </Button>
      </Paper>
    }
    let surveyStatuses = []
    let previousSurvey = ""
    const surveysAvailable = this.state.user.isCoach || this.state.user.isAdmin ? ["FORM", "WAY", "STORM", "NORM", "PERFORM"] : ["FORM", "STORM", "NORM", "PERFORM"]
    for (const surveyName of surveysAvailable) {
      let surveyStatus
      let reportStatus
      let archiveReport = null
      if (team.reports.includes(surveyName)) {
        surveyStatus = <Button disabled variant="contained">
          <DoneIcon/>
        </Button>
        reportStatus=
        //<>
        //  <Button variant="contained" color="primary" style={{marginRight:10}}
        //      onClick={() => {this.setState({
        //        loadingScreen: <CircularProgress/>,
        //        teamDetails: null
        //        }); this.generateReport(surveyName) }} >
        //      <RedoIcon/>
        //  </Button>
        <Link href={"/report/" + surveyName.toLowerCase() + "/" + team.id}>
          <Button variant="contained" color="primary">
          <DoneIcon/>
          </Button>
        </Link>
        //</>
        archiveReport =< Button variant="outlined" color="secondary" onClick={() => this.archiveReport(surveyName)}>
          Delete Report
        </Button>
      } else if (team.surveys.includes(surveyName) || surveyName === "FORM") {
        // survey in progress, check completion percentage
        let completedNumber = 0
        if (surveyName === "FORM") {
          completedNumber = formCompleted
        } else {
          for (const survey of this.state.surveysCompleted) {
            if (survey.type === surveyName) {
              completedNumber += 1
            }
          }
        }
        // if more than 2 allow generation of report
        if (completedNumber > 1 && (
          this.state.user.isCoach
          || this.state.user.isAdmin
          || surveyName === "FORM"
          || team.dateLastReportGenerated + 30*24*60*60*1000 < Date.now().valueOf() // more than 30 days have passed since last report
        )) {
          surveyStatus = <Button disabled variant="contained">
            <HourglassEmptyIcon/>
          </Button>
          reportStatus = <Button variant="contained" color="primary"
            onClick={() => {this.setState({
              loadingScreen: <CircularProgress/>,
              teamDetails: null
              }); this.generateReport(surveyName) }} >
            {completedNumber + "/" + team.users.length}
          </Button>
        } else {        
          surveyStatus = <Button disabled variant="contained">
            <HourglassEmptyIcon/>
          </Button>
          reportStatus = <Button disabled variant="contained">
            {completedNumber + "/" + team.users.length}
          </Button>
        }
      } else {
        // no survey or report yet, need to check if the previous one was completed
        if ((previousSurvey !== "" && team.reports.includes(previousSurvey)) || (previousSurvey === "WAY" && team.reports.includes("FORM"))) {
          // team has completed the previous, allow them to start the next
          // way does not prevent them to do the next survey
          surveyStatus = <Button variant="contained" color="primary"
            onClick={() => this.addSurveyToTeam(surveyName)}>
            <PlayArrowIcon/>
          </Button>
          reportStatus = <Button disabled variant="contained">
            <BlockIcon/>
          </Button>
        } else {
          // block them until they complete the previous survey
          surveyStatus = <Button disabled variant="contained">
            <BlockIcon/>
          </Button>
          reportStatus=<Button disabled variant="contained">
            <BlockIcon/>
          </Button>
        }
      }
      surveyStatuses.push(
        <SurveyStatus key={"surveyStatus" + surveyName} report={surveyName}
          reportStatus={reportStatus}
          surveyStatus={surveyStatus}
          archiveReport={archiveReport}
          addSurveyToTeam={() => this.addSurveyToTeam(surveyName)}/>
      )
      previousSurvey=surveyName
    }
    this.setState({ 
      team: team,
      loadingScreen: null,
      teamDetails: 
      <div>
      <SecondaryHeader text={team.teamName}/>
        <Link href={"/teamDashboard/" + team.id} >{this.props.language === 'IT' ? "Vai alla Team Dashboard" : "Go to the Team Dashboard"}</Link>
        <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
          <Typography variant="h6" style={{padding: "10px", textAlign: "left"}}>
            Reports 
            <Button color="primary" 
              onClick={() => this.setState({ modalOpen: true})}>
              <HelpOutlineIcon/> 
            </Button>
          </Typography>
          {surveyStatuses}
        </Paper>
        <Paper elevation={3} style={{width: "90%", margin: "20px auto"}}>
        <SecondaryHeader text={this.props.language === 'IT' ? "Membri" : "Members"}/>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
          {teamMembersDisplay}
        </Grid>
        <SecondaryHeader text={this.props.language === 'IT' ? "Aggiungi membri" : "Add members"}/>
        <Grid container justify="flex-start" alignItems="center" spacing={1} style={{paddingLeft: "20px"}}>
          <Box component="span" m={1}>
            <Button variant="outlined" color="primary" 
              onClick={() => this.addEmailRow()}>
              <AddIcon/>
            </Button>
          </Box>
          <Box component="span" m={1}>
            <Button variant="outlined" color="secondary" 
              onClick={() => this.removeEmailRow()}>
              <RemoveIcon/>
            </Button>
          </Box>
        </Grid>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1} style={{padding: "20px"}}>
        {emailDisplay}
          <Button variant="contained" color="primary" 
            onClick={() => this.sendTeamInvitations()}>
            {this.props.language === 'IT' ? "Conferma" : "Confirm"}
          </Button>
        </Grid>
        </Paper>
        {feedbackStorm}
        {feedbackNorm}
        {feedbackPerform}
        {stormQuestion}
        <Button variant="contained" color="secondary" 
          onClick={() => this.deleteTeam()}>
          {this.props.language === 'IT' ? "Elimina team" : "Delete team"}
        </Button>
        <br/>
        <br/>
      </div>
    })
  }

  addEmailRow() {
    this.setState({ emailRows: this.state.emailRows + 1 }, () => this.populateView(this.state.team))
  }

  removeEmailRow() {
    if (this.state.emailRows > 0) {
      let teamMembers = this.state.teamMembers
      delete teamMembers[this.state.emailRows - 1]
      this.setState({ emailRows: this.state.emailRows - 1, teamMembers: teamMembers }, 
        () => this.populateView(this.state.team))
    }
  }

  render() {
    return (
      <div>
        {this.state.loadingScreen}
        {this.state.teamDetails}

        <Dialog open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false})}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="body2" style={{paddingLeft: "10px", paddingBottom:"10px", 
                textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
                <PlayArrowIcon/> : {this.props.language === 'IT' ? "Survey pronta da mandare al team" : "Survey ready to be sent to the team" }
              </Typography>
              <Typography variant="body2" style={{paddingLeft: "10px", paddingBottom:"10px", 
                textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
                <BlockIcon/> : {this.props.language === 'IT' ? "Survey o Report non pronti da lanciare" : "Survey or Report not ready to launch" }
              </Typography>
              <Typography variant="body2" style={{paddingLeft: "10px", paddingBottom:"10px", 
                textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
                <HourglassEmptyIcon/> : {this.props.language === 'IT' ? "Survey attiva, aspettando risposte dal team" : "Survey active, waiting for responses from the team" }
              </Typography>
              <Typography variant="body2" style={{paddingLeft: "10px", paddingBottom:"10px", 
                textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
                5/7 : {this.props.language === 'IT' ? "Cinque risposte ricevute su sette membri del team" : "Five answers received out of seven team members" }
              </Typography>
              <Typography variant="body2" style={{paddingLeft: "10px", paddingBottom:"10px", 
                textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
                <DoneIcon/> : {this.props.language === 'IT' ? "Survey o Report completi" : "Survey or report complete" }
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => this.setState({ modalOpen: false})} color="secondary">
              {this.props.language === 'IT' ? "Indietro" : "Back" }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(Team);

/*

              <Typography variant="body2" style={{paddingLeft: "10px", paddingBottom:"10px", 
                textAlign: "left", fontSize:"15px", lineHeight:"20px"}}>
                <RedoIcon/> : {this.props.language === 'IT' ? "Genera un'altra volta il report" : "Generate again the report" }
              </Typography>
*/
